import React from "react";
import BaseModal from "../modal/BaseModal";
import Button from "react-bootstrap/Button";

const ConfirmationModal = ({ showModal, onHide, onReject, data = [] }) => {
  return (
    <BaseModal
      showModal={showModal}
      title="Are you sure you want to reject IP Address ?"
      onSave={onHide}
      onHide={onHide}
      confirmLabel="Yes Reject"
      isClose={false}
      size="lg"
    >
      <div className="p-2 ">
        <div className="scrollable-checkbox-list">
          {data.length > 1 &&
            data.map((ip, index) => (
              <p key={ip?.ip} className="text-white">
                {ip?.ip}
              </p>
            ))}
        </div>

        <div className="d-flex justify-content-end align-items-center">
          <Button
            className="w-auto bg-transparent me-2 text-primary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            className="w-auto bg-transparent text-primary"
            onClick={onReject}
          >
            Yes Reject
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
export default ConfirmationModal;
