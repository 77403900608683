import React from 'react'
import armorytestedIcon from "../../assets/images/armorytestedIcon.png"

const ArmoryTestedModal = () => {
  return (
    <React.Fragment>
        <div className='armorytested_main'>
            <div className='armorytested_icon'>
                <img src={armorytestedIcon}/>
            </div>
            <div className='armorytested_content'>
                <div className='armorytested_title'>ARMORY TESTED</div>
                <div className='armorytested_text'>Armory Tested signifies that your infrastructure has been evaluated through advanced offensive testing to identify and prioritize the most critical vulnerabilities.</div>
            </div>
        </div>
    </React.Fragment>
  )
}

export default ArmoryTestedModal