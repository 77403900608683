import React, { useCallback, useEffect, useState ,useMemo} from "react";
import { Container, Image, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import axios from "../../../util/axios";
import Moment from "react-moment";
import { Triangle } from "react-loader-spinner";
import Linker from "../../../components/Linker";

async function fetchUsers(page, pageSize) {
  try {
    let res = await axios.get("/admin/users", {
      params: { page: page, pageSize: pageSize },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const Users = () => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [fetching, setFetching] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const fetchItems = useCallback(async (page) => {
    if (fetching) {
      return;
    }

    setFetching(true);

    try {
      const data = await fetchUsers(page, pageSize);
      setItems((currentItems) => [...currentItems, ...data.items]);
      setTotalPages(data.totalPages);
      setCurrentPage(page);
    } finally {
      setFetching(false);
    }
  }, [fetching, pageSize]);

  useEffect(() => {
    fetchItems(1); // Load the first page on component mount
  }, []);

  const loader = (
    <div key="loader" className="loader">
      <Triangle
        height="80"
        width="80"
        color="#fff"
        ariaLabel="triangle-loading"
        wrapperStyle={{ justifyContent: "center" }}
        visible={true}
      />
    </div>
  );
  const tableRows = useMemo(() => {
    return items.map((item, index) => (
      <tr key={`${item.id}-${index}`}>
        <td>{item.id}</td>
        <td>{item.fullname || "N/A"}</td>
        <td>{item.email || "N/A"}</td>
        <td>{item.phone || "N/A"}</td>
        <td>{item?.organization?.org_name || "N/A"}</td>
        <td>{item.member_level === 9 ? "Admin" : "User"}</td>
        <td>{item.comments || "N/A"}</td>
        <td>
          <Moment format="MMM DD, YYYY hh:mm A">
            {item.registration_date}
          </Moment>
        </td>
        <td>
          <Linker
            to={`/admin/users/edit/${item.id}`}
            title="Edit this User"
            className="m-1"
          >
            <img alt="" src="/images/icon-mini-edit.png" />
          </Linker>
        </td>
      </tr>
    ));
  }, [items]);



  return (
    <React.Fragment>
      <Container className="main_container_style pb-0" fluid>
        <h1 className="fw-normal mb-5">
          <Image src="/images/icon-users.png" width={50} rounded /> Users
        </h1>
      <div className="scroll-container">
      <InfiniteScroll
          pageStart={0}
          loadMore={() => fetchItems(currentPage + 1)}
          hasMore={currentPage < totalPages}
          loader={loader}
          useWindow={false}
        >
          <Table variant="dark" striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Organization</th>
                <th>Level</th>
                <th>Comments</th>
                <th>Registration Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {tableRows}
            </tbody>
          </Table>
        </InfiniteScroll>
      </div>
      </Container>
      <Container className="main_container_style py-0" fluid>
        <h2>User Roles and Permissions</h2>
        <ul>
          <li>
            <strong>Admins</strong> - can create organizations, invite users, modify settings, create new targets on an organization, create Full, Demo, and Modular scans.
          </li>
          <li>
            <strong>Distributors</strong> - can create new targets on an organization, create Full and Demo scans.
          </li>
          <li>
            <strong>Regular users</strong> - can create Full scans on a single organization's target.
          </li>
        </ul>
      </Container>
    </React.Fragment>
  );
};

export default Users;
