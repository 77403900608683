import React, { useState } from "react";
import "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ComparisonChart from "../charts/ComparisonChart";

const OffenseCard = ({ cardsData }) => {
  const [isAdditionalTextVisible, setIsAdditionalTextVisible] = useState(false);

  const toggleAdditionalText = () => {
    setIsAdditionalTextVisible(!isAdditionalTextVisible);
  };

  return (
    <React.Fragment>
      <div className="offensecard_main">
        {cardsData.image ? (
          <div className="image-card">
            <div className="offensecard_title">
              Attack: <span>{cardsData?.CardTitle || "Unknown Attack"}</span>
            </div>
            <div className="dotted-line-image"></div>
            <div className="offensecard_image_container">
              <img
                src={cardsData.image}
                alt="Card icon"
                className="offensecard_image"
              />
              <p className="coming-soon-text">Coming soon</p>
            </div>
          </div>
        ) : (
          <>
            <div className="offensecard_title">
              Attack: <span>{cardsData?.CardTitle}</span>
            </div>
            <div className="dotted-line"></div>
            <div className="offensecard_output_section">
              <div className="offensecard_output_title">Output</div>
              <div className="margindiv"></div>
              <div className="card_subsection">
                <ul>
                  <li className="fw-semibold">
                    [+] [2024-03-05 11:00] Starting the Attack:
                  </li>
                  <br />
                  {cardsData?.data?.map((item) => (
                    <>
                      <li className="greentext">{item?.ip}</li>
                      <li className="greentext">{item?.passAuth}</li>
                      <li className="greentext">{item?.dictionary}</li>
                      <li className="">Result: {item?.result}</li>
                      <br />
                    </>
                  ))}
                  <li className="greentext">
                    [+] [2024-03-05 12:00] Attack Completed
                  </li>
                </ul>
              </div>
            </div>
            <ComparisonChart cardsData={cardsData} />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default OffenseCard;
