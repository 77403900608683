import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import Moment from "react-moment";
import { decodeHTML } from "entities";
import AudioPlayer from "../../components/AudioPlayer";
const NewSingle = ({ item }) => (
  <>
    <Row>
      <Col className="d-flex flex-column text-center p-0 pb-1">
        <a href={item.permalink} rel="noreferrer" target="_blank">
          <h1 className="display-5 ">{decodeHTML(item.title)}</h1>
        </a>
        <div className="m-1">
          <i className="bi bi-calendar3 me-2" title="Published Date"></i>
          <small className="text-muted">
            <Moment format="MMM DD, YYYY">{item.published_at}</Moment>
          </small>

          <i className="bi bi-lightbulb mx-2" title="Source"></i>
          <small className="text-muted">{item.feed.title}</small>
        </div>
      </Col>
    </Row>
    {/* image url */}
    {item.image_url ? (
      <Row className="rounded my-1 rounded shadow-sm">
        <Col className="p-1 d-flex justify-content-center">
          <a href={item.permalink} rel="noreferrer" target="_blank">
            <Image
              fluid
              src={item.image_url}
              alt={item.title}
              className="rounded news-image"
            />
          </a>
        </Col>
      </Row>
    ) : (
      ""
    )}
    {/* audio url */}
    {item.audio_url ? (
      <Row className="rounded my-2 rounded shadow-sm">
        <Col className="p-1 d-flex justify-content-center">
          <AudioPlayer src={item.audio_url} />
        </Col>
      </Row>
    ) : (
      ""
    )}
    <Row>
      <Col className="news-details p-2 d-flex flex-column justify-content-between ">
        {decodeHTML(item.open_text)}
      </Col>
    </Row>
  </>
);

export default NewSingle;
