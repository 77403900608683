export const webUpdatesFilter = (name, activeFilters, eventKey = "6", filterType = "Current State") => {
    if (activeFilters.some((filter) => filter.name === name)) {
        return activeFilters.filter((filter) => filter.name !== name);
    } else {
        const filteredCurrentFilters = activeFilters.filter(
            (filter) => filter.type !== filterType
        );
        if (name !== null) {
            return [
                ...filteredCurrentFilters,
                {
                    type: `${filterType}: ${name}`,
                    eventKey,
                    name,
                },
            ];
        }
        return filteredCurrentFilters;
    }
};
