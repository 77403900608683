import React, { useCallback, useEffect } from "react";
import { useState } from "react";

import { Triangle, ProgressBar } from "react-loader-spinner";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormCheck,
  FormControl,
  FormGroup,
  FormSelect,
  Image,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";

import axios from "../../../util/axios";
import { Link, json, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import EditableField from "../../../components/EditableField";
import { Field, Form, Formik } from "formik";
import PhoneField from "../../../components/PhoneField";
import * as Yup from "yup";


const AHref = ({ id, children, title, ...props }) => (
  <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
    <Link {...props}>{children}</Link>
  </OverlayTrigger>
);

const schema = Yup.object().shape({
	fullname: Yup.string()
	  .min(2, "Too Short!")
	  .max(70, "Too Long!")
	  .required("Full name is required"),
	email: Yup.string().email().required("Email is required"),
	organization_id: Yup.string().required("Organization is required"),
  });
const UserEdit = () => {
  const [item, setItem] = useState([]);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const routeParams = useParams();
  const navigate = useNavigate();

  const [fetching, setFetching] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);

  const initialValues = {
    id: "",
    fullname: "",
    email: "",
    phone: "",
    member_level: 0,
    comments: "",
    organization_id: null,
  };

  const handleSubmit = (values, helpers) => {
    setFetching(true);

    // console.log('params', values);

    axios
      .post(`/admin/users/${values.id}`, values)
      .then((response) => {
        const data = response.data;
        setFetching(false);
        if (data.id > 0) {
          toast.success(`User (${data.fullname}) has been updated`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setTimeout(() => {
            navigate("/admin/users/");
          }, 500);
        }
      })
      .catch((e) => {
        setFetching(false);
        if (e.response.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };

  const init = async () => {
    if (routeParams?.id) {
      setId(routeParams?.id);
      setFetching(true);
      await fetchItem(routeParams.id);
      setFetching(false);
    }
  };
  const fetchItem = async (id) => {
    try {
      try {
        let res = await axios.get(`/admin/users/${id}`);
        console.log("data", res.data);
        setTitle(res.data.fullname);
        for (const key of Object.keys(res.data)) {
          if ("is_approved" == key) {
            if (res.data[key] && res.data[key] <= 1) res.data[key] = 1;
          }
          initialValues[key] = res.data[key];
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    } finally {
      // setFetching(false);
      console.log("sfetching", fetching);
    }
  };
  const fetchOrganization = async () => {
    try {
      try {
        let res = await axios.get(`/organizations`);
        console.log("data-organizations", res.data);
        setOrganizationList(res.data.items);
        console.log("organa", organizationList);
      } catch (error) {
        console.log(error);
        throw error;
      }
    } finally {
    }
  };

  useEffect(() => {
    init();
    fetchOrganization();
    console.log("loading...");
  }, [routeParams?.id]);

  return (
    <React.Fragment>
      <Container className="py-5 max-80" fluid>
        <h1 className="fw-normal mb-5">
          <Image src="/images/icon-users.png" width={50} rounded /> Edit User :{" "}
          <small>{title ? title : id}</small>
        </h1>

        <Formik
		validationSchema={schema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          render={({
            handleChange,
            handleSubmit,
            handleBlur,
            values,
            errors,
            validateForm,
          }) => (
            <Form>
              {/* Full Name Field */}
			
              <Field
                name="fullname"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="fullname" className="my-3">
                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Your Full Name"
                    >
                      <FormControl
                        type={"text"}
                        size="lg"
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="John Doe"
                        className={
                          meta.touched && meta.error ? "is-invalid" : ""
                        }
                        {...field}
                      />
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
              {/* Email Field */}
              <Field
                name="email"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="email" className="my-3">
                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Enter your email"
                    >
                      <FormControl
                        type={"text"}
                        size="lg"
                        value={field.value}
                        onChange={field.onChange}
                        className={
                          meta.touched && meta.error ? "is-invalid" : ""
                        }
                        {...field}
                      />
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />

              {/* Phone Field */}

              <PhoneField
                className="my-3"
                name="phone"
                type="text"
                label="Enter your phone"
              />
			     {/* Organization */}
				 <Field
                as="select"
                name="organization_id"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="organization_id"   className="my-3">
                    <FloatingLabel
                      controlId="floatingSenderName"
                      label="Organization"
                    >
                      <FormSelect
                        value={field.value}
                        onChange={field.onChange}
                        className={
                          meta.touched && meta.error ? "is-invalid" : " "
                        }
                        {...field}
                      >
                        <option value="">Select organization</option>
                        {organizationList.map((organization) => (
                          <option key={organization.id} value={organization.id}>
                            {organization.org_name}
                          </option>
                        ))}
                      </FormSelect>
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
              {/* Member Level */}
              <Field
                as="select"
                name="member_level"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="action" className="my-3">
                    <FloatingLabel
                      controlId="floatingSenderName"
                      label="Member Level"
                    >
                      <FormSelect
                        value={field.value}
                        onChange={field.onChange}
                        className={
                          meta.touched && meta.error ? "is-invalid" : " "
                        }
                        {...field}
                      >
                        <option value="">Select Action</option>
                        <option value="0">User</option>
                        <option value="9">Admin</option>
                      </FormSelect>
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
             

              {/* Comments Field */}
              <Field
                name="comments"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="comments" className="my-3">
                    <FloatingLabel controlId="comments" label="Comments">
                      <FormControl
                        as="textarea"
                        size="lg"
                        style={{ height: "150px" }}
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="Comments"
                        className={
                          meta.touched && meta.error ? "is-invalid" : ""
                        }
                        {...field}
                      />
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
              {/* id field */}
              <Field name="id" type="hidden" />

              <Button
                type="submit"
                className="btn bg-primary btn-full my-3"
                id="singup-btn"
                disabled={fetching}
              >
                {fetching ? "Submitting" : "Submit"}
                <ProgressBar
                  height="30"
                  width="30"
                  color="#000"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{
                    justifyContent: "center",
                    marginLeft: "10px",
                  }}
                  wrapperClassName=""
                  visible={fetching}
                />
              </Button>
            </Form>
          )}
        />
      </Container>
    </React.Fragment>
  );
};

export default UserEdit;
