import React from "react";
import { Modal, Button } from "react-bootstrap";
import { ReactComponent as IconTick } from "../assets/images/Tick.svg";

function EmailConfirmationModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="confirmation-modal"
    >
      <Modal.Body className="p-5 text-center">
        <div>
          <IconTick className="align-middle me-2 placeholder-wave" />
          <h1 className="fw-normal">{props.bodyContent}</h1>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <div className="w-100">
          <Button
            style={{ height: '50px' }}
            className="w-100 fw-normal"
            onClick={props.confirmationHide}
          >
            Done
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EmailConfirmationModal;
