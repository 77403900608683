import React from "react";

const Data = ({ items }) => {
  return (
    <div className="summary-horizontal-grid mt-2">
      {items?.map((item) => (
        <div key={item?.id} className="summary-horizontal-item">
          <div className="summary-icon-item">
            <item.icon className="data-icon" />
            <span className="data-label">{item.label}</span>
          </div>
          <div>
            <div className="data-info">
              <span className="data-value">{item.value}</span>
              {item.active !== null && (
                <span className="data-active">({item.active} Active)</span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Data;
