import React from 'react'
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb"
import StatsCard from "../../components/stats-card/StatsCard"


const AttackSurface = () => {
    const breadcrumbItems = ["Dashboard", "Offensive Recon"];
    
  return (
    <React.Fragment>
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
        <div className='d-flex align-items-center justify-content-center flex-wrap flex-row'>
        <StatsCard title="Domain" value="2" isCentered={false} valueColor="#41E7DF" />
        <StatsCard title="IP Addresses" value="53" isCentered={false} valueColor="#93C2FF" />
        <StatsCard title="Hostnames" value="47" isCentered={false} valueColor="#B694FF" />
        <StatsCard title="Web Servers" value="9" isCentered={false} valueColor="#FCEE73" />
        </div>

        
    </React.Fragment>
  )
}

export default AttackSurface
