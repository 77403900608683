import React, { useState, useEffect } from "react";
import { ReactComponent as NoScanIcon } from "../../assets/images/no-scanner.svg";

const TypingEffect = ({ message }) => {
  const [displayedMessage, setDisplayedMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const typingSpeed = 70; 

  useEffect(() => {
    setDisplayedMessage("");
    setCurrentIndex(0);
  }, [message]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < message.length) {
        setDisplayedMessage((prevMessage) => prevMessage + message[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval);
      }
    }, typingSpeed);

    return () => clearInterval(interval);
  }, [currentIndex, message, typingSpeed]);

  return <span>{displayedMessage}</span>;
};

const WebScanner = ({ title, data }) => {
  const [scannerEvents, setScannerEvents] = useState([]);

  useEffect(() => {
    setScannerEvents(data);
  }, [data]);

  return (
    <React.Fragment>
      <div className="waf font">
        <div className="waf-card_header">
          <div className="waf-card_title">{title}</div>
        </div>
        {scannerEvents?.length > 0 ? (
          <div className="waf-card_subsection m-h-13">
            {scannerEvents.map((event, index) => (
              <div key={index}>
                <div
                  className={
                    event.type === "Scanner Initiated"
                      ? "waf-top-left"
                      : "waf-mid-left"
                  }
                  style={{ marginTop: index === 0 ? "0px" : "10px" }}
                >
                  {event.time && `[${event.time}]`}{" "}
                  {event.type && `${event.type}:`}{" "}
                  <span
                    className={
                      event.type === "Scanner Initiated"
                        ? "waf-top-right"
                        : "waf-mid-right"
                    }
                  >
                    {index === scannerEvents.length - 1 ? (
                      <TypingEffect message={event.message} />
                    ) : (
                      event.message
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="waf-card_subsection_empty">
            <div className="no_scanner_container">
              <NoScanIcon />
              <div className="no_scanner_text">
                Scanner Output Coming Soon
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default WebScanner;
