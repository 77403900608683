import React from 'react';
import SecurityIssueCard from "../../components/securityissue-card/SecurityIssueCard";
import { ReactComponent as Arrow } from "../../assets/images/port-arrow.svg";
import armorytestedIconSmall from "../../assets/images/armorytestedIconSmall.png";
import { ReactComponent as HighRiskGroup } from "../../assets/images/HighRiskGroup.svg";
import { ReactComponent as MediumGroupRisk } from "../../assets/images/MediumGroupRisk.svg";
import { ReactComponent as LowRiskGroup } from "../../assets/images/LowRiskGroup.svg";

const GroupIssuesTitle = ({ group, collapsedGroups, toggleCollapse, cvssRisk, cvssScore }) => {
  return (
    <div className="ports-list-table d-block" >
      <div
        className="left-ports-container d-flex align-items-baseline"
        onClick={() => toggleCollapse(group.issue_name)}
        style={{ width: collapsedGroups[group.issue_name]? "auto" : "" }}
      >
        <Arrow
          className={
            collapsedGroups[group.issue_name]
              ? "arrow-rotated-down"
              : "arrow-rotated-up"
          }
        />
        <div className="w-100">
          <div className="d-flex align-items-center justify-content-between">
            <span className="tab-issue-title">{group.issue_name}:</span>
            <div className="section_1_part1">
              <div className="securityissueCard_title">
                {/* Additional group-specific data can go here */}
              </div>
              {group?.armory_tested ? (
                <div className="section_1_part1_subpart d-flex align-items-center justify-content-center">
                  <img
                    className="h-100"
                    src={armorytestedIconSmall}
                    alt="Armory Tested"
                  />
                  <div className="securityissueCard_subtitle">
                    ARMORY TESTED
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="d-flex align-items-center mt-1">
            {cvssRisk === "primary" && (
              <div>
                <HighRiskGroup />
                <label className="ms-1 group-tab-sub">High Risk,</label>
              </div>
            )}
            {cvssRisk === "secondary" && (
              <div>
                <MediumGroupRisk />
                <label className="ms-1 group-tab-sub">Medium Risk,</label>
              </div>
            )}
            {cvssRisk === "tertiary" && (
              <div>
                <LowRiskGroup />
                <label className="ms-1 group-tab-sub">Low Risk,</label>
              </div>
            )}
            <div className="d-flex align-items-center mx-2">
              <div className="title-text mt-0">
                <label className="group-tab-value ms-1">{group?.assets.length}</label>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <label className="group-tab-sub ms-1">Assets ,</label>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="group-tab-value mt-0 pe-1">Category:</div>
              <div className="d-flex align-items-center justify-content-center">
                <label className="group-tab-sub">{group.scan_category},</label>
              </div>
            </div>
            <div className="d-flex align-items-center ms-1">
              <div className="group-tab-value mt-0 pe-2">CVE:</div>
              <div className={`cve-tag d-flex align-items-center justify-content-center ${cvssRisk}`}>
                <span>{group.cve ? "Yes" : "No"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="right-ports-container"
        style={{
          width: collapsedGroups[group.issue_name] ? "auto" : "",
        }}
      >
        {collapsedGroups[group.issue_name]&& (
          <div className="port-collapse-table group-issues">
            {group.assets.map((el) => (
              <SecurityIssueCard
                key={el.id}
                classNameVariant={cvssRisk}
                RiskPercentage={cvssScore}
                data={group}
                asset={el}
                isGroup={true}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupIssuesTitle;
