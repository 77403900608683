import React ,{useEffect}from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import TabPaneContent from './CustomTabPane';
const CustomTabContainer = ({ tabs, activeKey, onSelect,onDomainChange  }) =>{
 
return (
   <>
    <Tab.Container id="left-tabs-example" defaultActiveKey={activeKey} onSelect={onSelect}>
        <Row className='m-0'>
            <Col sm={5} className='p-0 mt-4 mb-4'>
                <Nav variant="pills" className="flex-column">
                    {tabs.map(tab => (
                        <Nav.Item key={tab.eventKey}>
                            <Nav.Link className="rounded-0" eventKey={tab.eventKey}>{tab.title}</Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </Col>
            <Col sm={7} className='p-0 tab-content-container'>
                <Tab.Content className=' pt-3 ps-2 pe-2'>
                    {tabs.map(tab => (
                        <TabPaneContent key={tab.eventKey} data={tab.data} eventKey={tab.eventKey} title={tab.contentTitle} onDomainChange={onDomainChange} />
                    ))}
                </Tab.Content>
            </Col>
        </Row>
    </Tab.Container></>
);
} 

export default CustomTabContainer;
