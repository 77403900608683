import React from 'react';
import { Button } from 'react-bootstrap';

class DButton extends React.Component {
  animationTimer;
  constructor(props) {
    super(props);
    this.state = {
      internalClassName: ''
    };

    this.onPressed = this.onPressed.bind(this);
  }

  componentWillUnmount() {
    if (this.animationTimer) {
      clearTimeout(this.animationTimer);
    }
    this.animationTimer = null;
  }

  onPressed(value) {
    const { def = 'this', onPressed, callState, enablerStates = [] } = this.props;

    if (enablerStates.length > 0 && callState && enablerStates.indexOf(callState) < 0) {
      console.error(`${callState} state is not enabler for ${def} button, so no action invoked`);
      return;
    }

    this.setState({
      internalClassName: 'clicked'
    });
    this.animationTimer = setTimeout(() => {
      this.setState({
        internalClassName: ''
      });
    }, 500);

    if (onPressed) {
      onPressed(value);
    }
  }
  render() {
    var variant = 'success';
    var { value, subvalue, action } = this.props;
    if ('call' === action) {
      value = "Dial";
    }
    if ('call-end' === action) {
      variant = 'danger';
      value = "End Call";
    }

    return (
      <>
        <div
          className="mx-4 text-box"
        >
          <span className="scan-content-text ms-2">
            When you use this product, you confirm that you have the right to use the Information you provide (including all phone numbers, and other personal information), and you will not violate any third-party rights or laws.
          </span>
        </div>
        <Button className='mt-4' variant={variant} onClick={() => this.onPressed(value)}>{value}</Button>

      </>
      // <div
      //   className={`button ${className} ${!subvalue ? 'no-sub' : ''} ${this.state.internalClassName}`}
      //   onClick={() => this.onPressed(value)}>
      //   {value}
      //   {
      //     subvalue && <div className="sub-digit">
      //       {subvalue}
      //     </div>
      //   }
      // </div>
    );
  }
}

export default DButton;
