import React from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
const Typography = () => {
  return (
    <React.Fragment>
      <Container >
      <Row className="m-3" >
          <Col>
            <h1>Heading 1 h1</h1>
            <span>Size: 32</span>
            <h2>Heading 2 h2</h2>
            <span>Size: 24</span>
            <h3>Heading 3 h3</h3>
            <span>Size: 18</span>
            <h4>Heading 4 h4</h4>
            <span>Size: 16</span>
            <h5>Heading 5 h5</h5>
            <span>Size: 14</span>
            <h6>Heading 6 h6</h6>
            <span>Size: 12</span>

          </Col>
        </Row>
        <Row className="m-3" >
          <h3>Buttons</h3>
          <Col>
            <Button variant="primary">Primary</Button>{" "}
            <Button variant="secondary">Secondary</Button>{" "}
            <Button variant="success">Success</Button>{" "}
            <Button variant="warning">Warning</Button>{" "}
            <Button variant="danger">Danger</Button>{" "}
            <Button variant="info">Info</Button>{" "}
            <Button variant="light">Light</Button>{" "}
            <Button variant="dark">Dark</Button>
            <Button variant="link">Link</Button>
          </Col>
        </Row>
        <Row className="m-3" >
          <Col>
            <Button variant="outline-primary">Primary</Button>{" "}
            <Button variant="outline-secondary">Secondary</Button>{" "}
            <Button variant="outline-success">Success</Button>{" "}
            <Button variant="outline-warning">Warning</Button>{" "}
            <Button variant="outline-danger">Danger</Button>{" "}
            <Button variant="outline-info">Info</Button>{" "}
            <Button variant="outline-light">Light</Button>{" "}
            <Button variant="outline-dark">Dark</Button>
          </Col>
        </Row>
        <Row className="m-3" >
          <Col>
            <Button href="#">Link</Button> <Button type="submit">Button</Button>{" "}
            <Button as="input" type="button" value="Input" />{" "}
            <Button as="input" type="submit" value="Submit" />{" "}
            <Button as="input" type="reset" value="Reset" />
          </Col>
        </Row>
        <Row className="m-3" >
          <Col>
            <div className="mb-2">
              <Button variant="primary" size="lg">
                Large button
              </Button>{" "}
              <Button variant="secondary" size="lg">
                Large button
              </Button>
            </div>
            <div>
              <Button variant="primary" size="sm">
                Small button
              </Button>{" "}
              <Button variant="secondary" size="sm">
                Small button
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Typography;
