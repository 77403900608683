import React, { useState, useEffect, useRef, useContext } from "react";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import { useParams, useOutletContext } from "react-router-dom";
import axios from "../../../../util/axios";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../../../components/card/GenericCard";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning";
import NoData from "../../../../components/empty/NoData";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import WebUpdatesHorizontal from "../../../../components/website-updates/WebUpdatesHorizontal.js";
import { webUpdatesFilter } from '../../../../util/genericFunctions.js'

const Cms = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [doughnutChartType, setDoughnutChartType] = useState([]);
  const [cms, setCms] = useState([]);
  const [tabs, setTabs] = useState([]);
  const routeParams = useParams();
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [noDataDoughtnutChart, setNoDataDoughtnutChart] = useState(false);
  const [isDataInProcess, setIsDataInProcess] = useState(false);

  const colorList = [
    "#398FFE",
    "#41E7DF",
    "#B694FF",
    "#238D88",
    "#1D56C9",
    "#727EF7",
    "#8067DC",
    "#39B9FE",
    "#33BAB3",
    "#38CEC7",
    "#81D3FF",
    "#D6C2FF",
    "#41E7DF",
    "#398FFE",
    "#B694FF",
  ];

  const sortedArray = Object.entries(doughnutChartType).sort(
    (a, b) => b[1] - a[1]
  );

  const top7 = sortedArray.slice(0, 7);

  const otherSum = Number(
    sortedArray.slice(7).reduce((acc, item) => acc + item[1], 0)
  );

  const result = {
    ...Object.fromEntries(top7),
  };

  if (otherSum > 0) {
    result["Other"] = otherSum;
  }

  const sortedLabels = ["None"].concat(
    Object.keys(result).filter((label) => label.toLowerCase() !== "none")
  );

  let colorIndex = 0;
  const colors = sortedLabels.map((label) => {
    if (label.toLowerCase() === "none") {
      return "#F5F5F5";
    } else {
      const color = colorList[colorIndex % colorList.length];
      colorIndex++;
      return color;
    }
  });

  const doughnutChartData = {
    labels: doughnutChartType?.length == 0 ? [] : sortedLabels,
    datasets: [
      {
        label: "",
        data:
          doughnutChartType?.length == 0
            ? []
            : sortedLabels.map((key) => result[key]),
        backgroundColor: colors,
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  };

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const columns = [
    { Header: "Hostname", accessor: "host", isSortable: true },
    { Header: "CMS", accessor: "has_cms", isSortable: true },
    { Header: "CMS Name", accessor: "cms_name", isSortable: true },
    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>{row?.first_detected
            ? moment(row?.first_detected).format("DD/MM/YY")
            : ""}</div>
        )
      },
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.cms_change_status != null ? (
              <CurrentStatus
                status={row?.cms_change_status}
                tooltipInfo={row?.cms_changes}
                headerKeys={{
                  cms: "CMS Name",
                  has_cms: "CMS",
                  host: "Hostname",
                  first_detected: "First Detected",
                  change_status: "Current State",
                  cms_change_status: "Current State",
                }}
              />
            ) : (
              ""
            )}</div>
        )
      },
    },
  ];

  const updateTabsWithData = (cmsData) => {
    const hostname = Array.from(new Set(cmsData.map((cms) => cms?.host))).map(
      (hostName, index) => ({
        id: index,
        name: hostName,
        type: "Hostname: " + hostName,
        key: "1",
        active: false,
      })
    );

    const hasCMSFilter = [
      {
        id: 0,
        name: "Yes",
        type: "CMS: Yes",
        key: "2",
        active: false,
      },
      {
        id: 1,
        name: "No",
        type: "CMS: No",
        key: "2",
        active: false,
      },
    ];

    const cmsName = Array.from(new Set(cmsData.map((cms) => cms?.cms_name)))
      .filter((name) => name !== null)
      .map((name, index) => ({
        id: index,
        name: name,
        type: "CSM Name: " + name,
        key: "3",
        active: false,
      }));

    setTabs([
      {
        eventKey: "1",
        title: "Hostname",
        contentTitle: "Hostname",
        data: Array.from(hostname),
      },
      {
        eventKey: "2",
        title: "CMS",
        contentTitle: "CMS",
        data: Array.from(hasCMSFilter),
      },
      {
        eventKey: "3",
        title: "CMS Name",
        contentTitle: "CMS Name",
        data: Array.from(cmsName),
      },
    ]);
  };

  const fetchCms = async () => {
    try {
      const { data } = await axios.get(`scans/${routeParams?.target_id}/cms`);
      setDoughnutChartType(data?.groups?.cms);
      setWebUpdatesData(data?.groups?.changes);

      setCms(
        data?.cms.length > 0
          ? data?.cms?.map((el) => ({
            ...el,
            host: el.host.host,
            has_cms: el.cms || el.cms_version ? true : false,
            cms_name: el?.cms,
          }))
          : []
      );
      updateTabsWithData(
        data?.cms.length > 0
          ? data?.cms?.map((el) => ({
            ...el,
            host: el.host.host,
            has_cms: el.cms || el.cms_version ? true : false,
            cms_name: el?.cms,
          }))
          : []
      );
      if (scanningStatus === -1 || scanningStatus === 3) {
        if (
          Object.values(data?.groups?.cms)?.every((value) => value === 0) ||
          Object.values(data?.groups?.cms)?.length === 0
        ) {
          setNoDataDoughtnutChart(true);
        } else {
          setNoDataDoughtnutChart(false);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (scanningStatus === 3 || scanningStatus === -1) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchCms();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchCms();
        fetchIntervalRef.current = setInterval(fetchCms, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(
      webUpdatesFilter(name, activeFilters)
    )
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredCms = cms.filter((item) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1" && item.host) {
          return item.host.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return (
            (lowerCaseFilter === "yes" && item.has_cms) ||
            (lowerCaseFilter === "no" && !item.has_cms)
          );
        } else if (eventKey === "3" && item.cms_name) {
          return item.cms_name.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "6") {
          return item?.cms_change_status === statusMap[filter.name.toLowerCase()]
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (item.host &&
        item.host.toLowerCase().includes(searchValue.toLowerCase())) ||
      (item.cms_name &&
        item.cms_name.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      noDataDoughtnutChart ? (
                        <NoDataAfterScanning />
                      ) : isDataInProcess ? (
                        <NoData />
                      ) : (
                        <DoughnutChart
                          data={doughnutChartData}
                          options={doughnutChartOptions}
                        />
                      )
                    }
                    title={"Content Management Systems"}
                    isCentered={false}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-12">
                      <GenericCard
                        children={<WebUpdatesHorizontal
                          data={webUpdatesData}
                          onSort={handleWebUpdateSort}
                        />}
                        title={"Updates"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"CMS Scanner"} />
            </div>
          </div>

          <div className="pb-5">
            {/* <div className="table-container">
              <div className="table-left">Results</div>
              <div>
                <BaseInput
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  icon={Search}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
            <div>
              <BaseFilter
                tabs={tabs}
                onFilterChangeWaf={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                iskeyfilter={true}
                className="mt-3"
                totalRecords={filteredCms.length}
                exportTitle={`CMS_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportHeader={["Hostname", "CMS", "CMS Name"]}
                exportRows={filteredCms.map((cms) => [
                  cms.host,
                  cms.has_cms ? "Yes" : "No",
                  cms.cms_name ? cms.cms_name : "-",
                ])}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredCms}
                selectable={true}
                showCheckboxes={false}
                action={false}
                loading={isLoading}
                isDatainProcess={isDataInProcess}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Cms;
