import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import core from '../../util/core';

import Moment from 'react-moment';
import { ReactComponent as IconPhone } from '../../assets/images/icon-phone.svg';
import { ReactComponent as IconMail } from '../../assets/images/icon-mail.svg';
import { ReactComponent as IconMessage } from '../../assets/images/icon-message.svg';
import { Link } from 'react-router-dom';

const SpoofWidget = ({ item }) => {


    return (

        <div className="d-flex justify-content-center">
            <Link text to="/spoof/phone" className="me-1 btn align-items-center d-flex flex-column border  rounded btn-full p-3 w-100" variant="rounded border">
                <IconPhone width="24px" className="img-fluid" />
                Phone
            </Link>

            <Link to="/spoof/email" className=" btn me-1 align-items-center d-flex flex-column border rounded btn-full p-3 w-100" variant="rounded border">
                <IconMail width="24px" className="img-fluid" />
                Email
            </Link>

            {/* <Link disabled to="/spoof/sms" className="disabled mx-1 btn align-items-center d-flex flex-column border rounded btn-full p-3 w-100" variant="rounded border">
                <IconMessage width="24px" className="img-fluid" />
                SMS
            </Link> */}



        </div>
    )
};

export default SpoofWidget;