import React, { useCallback, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Stack } from "react-bootstrap";
import IconBox from "../../components/IconBox";
import { Link } from "react-router-dom";
import { ReactComponent as IconPhone } from '../../assets/images/icon-phone.svg';
import { ReactComponent as IconMail } from '../../assets/images/icon-mail.svg';
import { ReactComponent as IconMessage } from '../../assets/images/icon-message.svg';
import axios from "../../util/axios";
import InfiniteScroll from "react-infinite-scroller";
import SpoofSingle from "./SpoofSingle";
import Widget from "./SpoofWidget";
import SpoofWidget from "./SpoofWidget";


async function loadItems(page, pageSize) {
	try {
		let res = await axios.get('spoof', { params: { page: page, pageSize: pageSize } });
		console.log('data', res.data);
		return res.data;

	} catch (error) {
		console.log(error);
		throw error;
	}

}

const Spoof = () => {
	const [items, setItems] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState("");
	const pageSize = 10;
	const [fetching, setFetching] = useState(false);

	const fetchItems = useCallback(
		async () => {
			if (fetching) {
				return;
			}

			setFetching(true);

			try {
				const data = await loadItems(page, pageSize);

				setItems([...items, ...data.items]);
				setPage(data.nextPage)
				setTotal(data.total)


			} finally {
				setFetching(false);
			}
		},
		[items, fetching, page, pageSize]
	);

	const hasMoreItems = !!page;

	const loader = (
		<div key="loader" className="loader">
			Loading ...
		</div>
	);

	return (
		<React.Fragment>
			<div className="main_container_style">
				<Stack gap={2}>
					<SpoofWidget />
					<InfiniteScroll
						loadMore={fetchItems}
						hasMore={hasMoreItems}
						loader={loader}
						className="container-fluids "
					>
						<h3 className="pt-3 pb-3 m-0 p-0">Action History ({total})</h3>
						{items.map(item => (
							<SpoofSingle key={item.id} item={item} />
						))}
						{total <= 0 ? <div className="pt-3">No record found!</div> : ""}
					</InfiniteScroll>
				</Stack>
			</div>
		</React.Fragment >
	);
};

export default Spoof;
