import axios from "axios";
// define baseUrl
 const BASE_URL= axios.defaults.baseURL;


const request=(endpoint)=>{
    let userToken = localStorage.getItem('user-token');
    let config ={
        headers:{}
    }
    if (userToken) {
        // console.log('my token', userToken)
      
        config['headers']["access-token"] = userToken;
    }

    return fetch(BASE_URL+endpoint,config)

}




export default request;