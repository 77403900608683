import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormControl,
  FormGroup,
  FormCheck,
  Row,
  Card,  
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneField from "../../components/PhoneField";
import axios from "../../util/axios";
import * as Yup from "yup";
import { Triangle } from "react-loader-spinner";
import ShareForm from "./ShareForm";

const schema = Yup.object().shape({
  // recipient_name: Yup.string().required("Please provide recipient name."),
  fullname: Yup.string().required("Please provide name."),
  url: Yup.string().required("Please provide URL."),
});

const UrlForm = () => {
  const params = new URLSearchParams(window.location.search) // id=123
  const navigate = useNavigate();
  const [submitBtn, setSubmitBtn] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [locatorData, setLocatorData] = useState(null);

  let API_ENDPOINT_NODE = "urls/generate-url";

  const myInfo = JSON.parse(localStorage.getItem("user"));
  let myName = "";

  if (myInfo && myInfo["fullname"]) {
    myName = myInfo.fullname;
  }
  
  const [keyword, setKeyword] = useState(params.has('keyword') ? params.get('keyword') : '');
  let initialValues = {
    recipient_name: params.get('recipient_name') || '',
    fullname: params.get('fullname') || myName,
    url: params.get('url') || "https://",
  };
  const init = async () => {
    //
  };
  useEffect(() => {
    //passing getData method to the lifecycle method
    init();
  }, []);

  const handleSubmit = (values, helpers) => {
    console.log("my data");
    setIsLoading(true);

    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        setIsLoading(false);
        const data = response.data;
        setLocatorData(data.data);
        initialValues = {
          recipient_name: "",
          fullname: "",
          url: "https://",
        };
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
        helpers.resetForm();
        // setTimeout(() => {
        //   navigate("/auth/otp/" + data.code);
        // }, 500);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };
  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Generating URL..." });
    } else {
      setSubmitBtn({ disabled: false, label: "Next" });
    }
  }, [isLoading]);
  return (
    <React.Fragment>
      
        <Row>
          <Col>
            
            {locatorData ? (
              <ShareForm item={locatorData} />
            ) : (
              <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                render={({
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                  validateForm,
                }) => (
                  <Form>
                    {/* Sender Name Field */}
                    {/* <Field
                      name="recipient_name"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup controlId="recipient_name" className="my-3">
                          <FloatingLabel
                            controlId="floatingSenderName"
                            label="Recipient Name"
                          >
                            <FormControl
                              type={"text"}
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              placeholder="John Doe"
                              className={
                                meta.touched && meta.error ? "is-invalid" : ""
                              }
                              {...field}
                            />
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FloatingLabel>
                        </FormGroup>
                      )}
                    /> */}
                    {/* Full Name Field */}
                    <Field
                      name="recipient_name"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup controlId="recipient_name" className="my-3">
                          <FloatingLabel
                            controlId="floatingFullName"
                            label="Recipient Name"
                          >
                            <FormControl
                              type={"text"}
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              placeholder="John Doe"
                              className={
                                meta.touched && meta.error ? "is-invalid" : ""
                              }
                              {...field}
                            />
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FloatingLabel>
                        </FormGroup>
                      )}
                    />
                    {/* URL Field */}
                    <Field
                      name="url"
                      placeholder="https:/anywhere.com"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup controlId="url" className="my-3">
                          <FloatingLabel
                            controlId="url"
                            label="URL"
                            placeholder="https:/anywhere.com"
                          >
                            <FormControl
                              type={"text"}
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              className={
                                meta.touched && meta.error ? "is-invalid" : ""
                              }
                              placeholder="https:/anywhere.com"
                              {...field}
                            />
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FloatingLabel>
                        </FormGroup>
                      )}
                    />
                    {/* URL Field */}
                    <Button
                      type="submit"
                      className="btn bg-primary btn-full -3"
                      id="singup-btn"
                      disabled={submitBtn.disabled}
                    >
                      {submitBtn.label}
                    </Button>
                  </Form>
                )}
              />
            )}
            <div key="loader" className="loader">
              <Triangle
                height="80"
                width="80"
                color="#185f95"
                ariaLabel="triangle-loading"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClassName=""
                visible={isLoading}
              />
            </div>
          </Col>
        </Row>
    </React.Fragment>
  );
};

export default UrlForm;
