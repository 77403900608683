import React, { useState } from "react";
import BaseTable from '../../../../components/table/BaseTable';
import BaseFilter from '../../../../components/filter/BaseFilter';
import BaseInput from '../../../../components/form/BaseInput';
import WebScanner from '../../../../components/web-scanner/WebScanner';
import WebUpdate from '../../../../components/website-updates/WebUpdate';
import { ReactComponent as Search } from "../../../../assets/images/search.svg";
import CircularChart from "../../../../components/charts/CircularChart";
import GenericCard from "../../../../components/card/GenericCard"
import { useParams } from "react-router-dom";
import { ThreeCircles } from 'react-loader-spinner'
import ValueChart from "../../../../components/charts/ValueChart"

const Shodan = () => {
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);
    const [cms, setCms] = useState([]);
    const routeParams = useParams();

    const data = [{
        id: 1,
        ip: '-',
        host: "-",
        domain: "-",
        port: "-",
        service: "-",
        version: "-",
        os: "-",
        isp: "-",
        org: "-",
        location: "-",
    },
    ];

    const CircularChartdata = {
        labels: ['DNSSEC Unsigned', 'DNSSEC Signed'],
        datasets: [
            {
                label: 'Shodan',
                data: [5, 5],
                backgroundColor: ['#ff6155', '#2AA16E'],
                hoverOffset: 4,
                borderColor: 'transparent',
                borderWidth: 0,
                borderAlign: 'center'
            }
        ]
    };

    const CircularChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    padding: 10,
                    color: '#F5F5F5',
                }
            },
            title: {
                display: false,
                text: 'Headers Security',
                color: 'white',
                font: {
                    family: 'Kodchasan',
                    size: 24,
                }
            }
        }
    };

    const columns = [
        { Header: 'IP Address', accessor: 'ip', isSortable: false },
        { Header: 'Hostname', accessor: 'host' },
        { Header: 'Domain', accessor: 'domain' },
        { Header: 'Port', accessor: 'port' },
        { Header: 'Service', accessor: 'service' },
        { Header: 'Service Version', accessor: 'version' },
        { Header: 'OS', accessor: 'os' },
        { Header: 'ISP', accessor: 'isp' },
        { Header: 'Organization', accessor: 'org' },
        { Header: 'Location', accessor: 'location' },
    ];

    const sampleData = [
        { id: 1, type: 'upload' },
        { id: 2, type: 'upload' },
        { id: 3, type: 'removal' },
        { id: 4, type: 'removal' },
        { id: 5, type: 'upload' },
    ];

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleRowSelect = (selectedRows) => {

    };

    const handleAction = (rowData) => {

    };



    return (
        <React.Fragment>
            {
                isLoading ? <div className="content-loader">
                    <ThreeCircles
                        visible={true}
                        height="60"
                        width="60"
                        color="#ffff"
                        ariaLabel="three-circles-loading"
                        wrapperClass=""
                    />
                </div>
                    :
                    <div className="main_container_style">
                        <div className="pb-4 row top-container-main web-updates-container" >
                            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
                                <div className="row gap-1 top-container-main h-100" >
                                    <div className="row d-flex align-items-center justify-content-between p-0 m-0 h-25" >
                                        <div className="col-6">
                                            <GenericCard children={<ValueChart value={2} size={"small"} color={"#41E7DF"} />} title={'Domain'} isCentered={true} />
                                        </div>
                                        <div className="col-6">
                                            <GenericCard children={<ValueChart value={43} size={"small"} color={"#93C2FF"} />} title={'IP Addresses'} isCentered={true} />
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center justify-content-between p-0 m-0 h-25" >
                                        <div className="col-6">
                                            <GenericCard children={<ValueChart value={57} size={"small"} color={"#B694FF"} />} title={'Host Names'} isCentered={true} />
                                        </div>
                                        <div className="col-6">
                                            <GenericCard children={<ValueChart value={9} size={"small"} color={"#FCEE73"} />} title={'Web Servers'} isCentered={true} />
                                        </div>
                                    </div>
                                    <div className="col-12 h-46" >
                                        <div className="row h-100" >
                                            <div className="col-12">
                                                <GenericCard children={<WebUpdate data={sampleData} />} title={'IP Addresses Updates'}
                                                    subtitle={"View Issues"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
                                <WebScanner title={'Shodan Scanner'} />
                            </div>
                        </div>

                        <div className="pb-5 pt-3">
                            <div className='table-container' >
                                <div className='table-left'>
                                    Results
                                </div>
                                <div >
                                    <BaseInput
                                        type="text"
                                        placeholder="Search..."
                                        className="search-input"
                                        icon={Search}
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <div >
                                <BaseFilter className="mt-3" totalRecords='12' />

                            </div>
                            <div>
                                <BaseTable className="mt-3 mb-3"
                                    columns={columns}
                                    data={data}
                                    selectable={true}
                                    onRowSelect={handleRowSelect}
                                    showCheckboxes={false}
                                    action={false}
                                    loading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
            }
        </React.Fragment>
    )
}

export default Shodan;