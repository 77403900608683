import React, { useEffect } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormControl,
  FormGroup,
  Row,
  Stack,
} from "react-bootstrap";
import IconBox from "../../components/IconBox";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IconHead } from "../../assets/images/icon-mail.svg";
import Image from "react-bootstrap/esm/Image";
import DialPad from "../../components/dialpad/Index";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import axios from "../../util/axios";
import EmailConfirmationModal from "../../components/EmailConfiramation";


import * as Yup from "yup";
import { Field, Formik, Form } from "formik";


const SpoofEmail = () => {
  const [submitBtn, setSubmitBtn] = useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const params = new URLSearchParams(window.location.search); // id=123

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  let API_ENDPOINT_NODE = "/spoof";

  const schema = Yup.object().shape({
    from: Yup.string().required("From is required"),
    to: Yup.string().email().required("To email is required"),
    subject: Yup.string().required("Subject is required"),
    content: Yup.string().required("Content is required"),
  });
  const initialValues = {
    from: user.fullname || "",
    to: "",
    content: "",
    subject: "",
    type: "email",
    status: 1,
    id: null,
  };

  const fetchDetails = async () => {
    if (params.has("id")) {
      let id = params.get("id");
      setIsLoading(true);
      try {
        const { data } = await axios.get("/spoof/" + id);
        console.log("my data", data);
        for (let field in data) {
          initialValues[field] = data[field];
        }
        // initialValues.to = data.to;

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
        toast.error("Unable to fetch details", {
          toastId: "error1",
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  const init = async () => {
    await fetchDetails();
  };
  useEffect(() => {
    //passing getData method to the lifecycle method
    init();
  }, []);

  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Sending..." });
    } else {
      setSubmitBtn({ disabled: false, label: "Send" });
    }
  }, [isLoading]);

  const handleSubmit = (values, helpers) => {
    setIsLoading(true);

    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        const data = response.data;
        setModalShow(true)
        if (data.message) {
          toast.success(data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        // setTimeout(() => {
        //   navigate("/spoof");
        // }, 500);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };
  const confirmationModalHide = () => {
    navigate("/spoof");
  }


  return (
    <React.Fragment>
      <h1 className="fw-normal mb-5">
        <Button
          variant="secondary"
          onClick={() => navigate("/spoof")}
          className="px-2 rounded-1 me-3"
        >
          <i className="bi bi-chevron-left text-white fs-4"></i>
        </Button>
        <IconHead width="35" className="me-3" />
        Email Spoof
      </h1>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({
          handleChange,
          handleSubmit,
          handleBlur,
          values,
          errors,
          validateForm,
        }) => (
          <Form>
            <Stack gap={4}>
              <Field name="type" type="hidden" />
              <Field name="status" type="hidden" />
              {/* To Field */}
              <Field
                name="from"
                placeholder="Sender name"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="from">
                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Sender name"
                    >
                      <FormControl
                        disabled={isLoading}
                        type={"text"}
                        size="xxlg"
                        value={field.value}
                        onChange={field.onChange}
                        className={
                          meta.touched && meta.error ? "is-invalid" : ""
                        }
                        {...field}
                      />
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
              {/* To Field */}
              <Field
                name="to"
                placeholder="Recipient email addresss..."
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="email">
                    <FloatingLabel
                      controlId="floatingPassword"
                      label="Recipient email"
                    >
                      <FormControl
                        disabled={isLoading}
                        type={"text"}
                        size="xxlg"
                        value={field.value}
                        onChange={field.onChange}
                        className={
                          meta.touched && meta.error ? "is-invalid" : ""
                        }
                        {...field}
                      />
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
              {/* Subject Field */}
              <Field
                name="subject"
                placeholder="Subject"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="subject">
                    <FloatingLabel controlId="floatingPassword" label="Subject">
                      <FormControl
                        disabled={isLoading}
                        type={"text"}
                        size="xxlg"
                        value={field.value}
                        onChange={field.onChange}
                        className={
                          meta.touched && meta.error ? "is-invalid" : ""
                        }
                        {...field}
                      />
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
              {/* Conntent */}
              <Field
                name="content"
                placeholder="Enter Text..."
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="content" className="my-3">
                    <FloatingLabel controlId="floatingPassword" label="Content">
                      <FormControl
                        disabled={isLoading}
                        as="textarea"
                        size="lg"
                        style={{ height: "150px" }}
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="News Title"
                        className={
                          meta.touched && meta.error ? "is-invalid" : ""
                        }
                        {...field}
                      />
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
              <div
                className="mx-4 text-box"
              >
                <span className="scan-content-text ms-2">
                  When you use this product, you confirm that you have the right to use the Information you provide (including all phone numbers, and other personal information), and you will not violate any third-party rights or laws.
                </span>
              </div>
              <div className="my-3 d-flex justify-content-end">
                <Button
                  type="submit"
                  className="btn btn-primary btn-full "
                  id="singup-btn"
                  style={{ width: 150 }}
                  disabled={submitBtn.disabled || isLoading}
                >
                  {submitBtn.label}
                </Button>


                <EmailConfirmationModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  confirmationHide={confirmationModalHide}
                  bodyContent="Email spoofing is complete!"
                />
              </div>
            </Stack>
          </Form>
        )}
      />
    </React.Fragment>
  );
};

export default SpoofEmail;
