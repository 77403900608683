import React from 'react';

const PublicLayout = (props) => {
    return (
        <>
            {props.children}
        </>
    );
}

export default PublicLayout;
