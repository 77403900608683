import ReactGA from "react-ga4";

const ReactGAArgs = [
  {
    trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS,
    gaOptions: {
      debug_mode: true,
    },
    gtagOptions: {
      debug_mode: true,
    },
  },
];

ReactGA.initialize(ReactGAArgs);

export const sendEvent = (eventName, params = {}) => {
  ReactGA.event(eventName, params);
};

export const sendUserId = (user_id) => {
  ReactGA.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS, { user_id });
};
