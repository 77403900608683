const STATES = {
  DEFAULT: 'DEFAULT',
  CALLING: 'CALLING',
  RINGING: 'RINGING',
  ON_CALL: 'ON_CALL',
  INCOMING_CALL: 'INCOMING_CALL',
  REPLYING: 'REPLYING',
  REJECTING: 'REJECTING',
  ENDING: 'ENDING'
};

export default STATES;
