import React, { useState, useEffect, useRef } from "react";
import HighlightsRisk from "../../components/highlights-risk/HighlightsRisk";
import OptionsSection from "../../components/options-section/OptionsSection";
import { ReactComponent as DataProcess } from "../../assets/images/data-process.svg";
import { ReactComponent as NoIssue } from "../../assets/images/completed.svg";
import axios from "../../util/axios";
import { ThreeCircles } from "react-loader-spinner";
import BreadCrumb from "../../components/breadcrumbs/BreadCrumb";
import { useParams, useLocation } from "react-router-dom";
import GenericCard from "../../components/card/GenericCard.js";
import IssueStatusChart from "../../components/charts/IssueStatusChart";
import { Chart } from 'chart.js';
import { useMemo } from "react";
import GroupSwitch from "../../components/cves/Switch"
import CveList from './CveList';
import { webUpdatesFilter } from '../../util/genericFunctions.js'

const Cves = () => {
  const timeoutRef = useRef(null);
  const routeParams = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ipParam = searchParams.get("ip");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cves, setCves] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isSortBy, setIsSortBy] = useState("rhl");
  const [isFinished, setIsFinished] = useState(false);
  const [selectedSortValue, setSelectedSortValue] = useState("Risk Score High to Low");
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(null);
  const [scanningDetails, setScanningDetails] = useState({
    status: null,
    date: "",
  });
  const [originalCves, setOriginalCves] = useState([]);  // Store the original data
  const [separatedCves, setSeparatedCves] = useState([]); // Store the separated results
  const [selectedIssueStatus, setSelectedIssueStatus] = useState(null);
  const colorList = [
    "#B694FF",
    "#5BD5D5",
    "#367BD5",
  ];
  const [doughnutChartType, setDoughnutChartType] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: colorList,
        hoverBackgroundColor: colorList,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  });
  const centerTextPlugin = {
    id: 'centerText',
    afterDraw: function (chart) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      const totalIssuesStatus = chart.config.options.plugins.centerText?.totalIssuesStatus;

      if (totalIssuesStatus !== undefined) {
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em Kodchasan`;
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#F5F5F5";

        const text = `${totalIssuesStatus}`;  // Total value to display
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }
  };

  Chart.register(centerTextPlugin);
  const totalIssuesStatus = doughnutChartType.datasets[0].data.reduce((acc, val) => acc + val, 0);
  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: function () {
            return ''; // Remove the top title
          },
          label: function (context) {
            const label = context.label || "";
            const value = context.raw;
            return `${label}: ${value}`; 
          },
        },
        yAlign: "center", 
        xAlign: "right",  
      },
      title: {
        display: false,
      },
      centerText: {
        totalIssuesStatus: totalIssuesStatus,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: "transparent",
      },
    },
  };  
  const [tabs, setTabs] = useState([]);
  const [assets, setAssets] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapsedGroups, setCollapsedGroups] = useState({});


  const navItems = [
    // { href: "#", eventKey: "overview", label: "Overview", disabled: false },
    { href: "#", eventKey: "home", label: "Open Issues", disabled: false },
    // { href: "#", eventKey: "disabled", label: "Armory Tested", disabled: false },
    // { href: "#", eventKey: "history", label: "History", disabled: false },
  ];

  const impacts = [
    {
      id: 0,
      name: "0-40",
      type: "Impact: 0-40",
      key: "4",
      active: false,
    },
    {
      id: 1,
      name: "40-80",
      type: "Impact: 40-80",
      key: "4",
      active: false,
    },
    {
      id: 2,
      name: "80-100",
      type: "Impact: 80-100",
      key: "4",
      active: false,
    },
  ];
  const Exploitabilities = [
    {
      id: 0,
      name: "0-40",
      type: "Exploitability: 0-40",
      key: "5",
      active: false,
    },
    {
      id: 1,
      name: "40-80",
      type: "Exploitability: 40-80",
      key: "5",
      active: false,
    },
    {
      id: 2,
      name: "80-100",
      type: "Exploitability: 80-100",
      key: "5",
      active: false,
    },
  ];
  const CVSS = [
    {
      id: 0,
      name: "0-4",
      type: "CVSS: 0-4",
      key: "6",
      active: false,
    },
    {
      id: 1,
      name: "4-8",
      type: "CVSS: 4-8",
      key: "6",
      active: false,
    },
    {
      id: 2,
      name: "8-10",
      type: "CVSS: 8-10",
      key: "6",
      active: false,
    },
  ];
  const EPSS = [
    {
      id: 0,
      name: "0.0-0.4",
      type: "EPSS: 0.0-0.4",
      key: "7",
      active: false,
    },
    {
      id: 1,
      name: "0.4-0.8",
      type: "EPSS: 0.4-0.8",

      key: "7",
      active: false,
    },
    {
      id: 2,
      name: "0.8-1",
      type: "EPSS: 0.8-1",
      key: "7",
      active: false,
    },
  ];
  const scores = [
    {
      id: 0,
      name: "0-39",
      type: "Risk Score: 0-39",
      key: "8",
      active: false,
    },
    {
      id: 1,
      name: "40-79",
      type: "Risk Score: 40-79",
      key: "8",
      active: false,
    },
    {
      id: 2,
      name: "80-100",
      type: "Risk Score: 80-100",
      key: "8",
      active: false,
    },
  ];

  const hasCveFilterOptions = [
    {
      id: 0,
      name: "Yes",
      type: "Has CVE: Yes",
      key: "9",
      active: false,
    },
    {
      id: 1,
      name: "No",
      type: "Has CVE: No",
      key: "9",
      active: false,
    },
  ];

  const sortData = (allCves, sortBy) => {

    switch (sortBy) {
      case "chl":
        return allCves.sort((a, b) => {
          const cvssA = a.cvss === "-" ? -Infinity : parseFloat(a.cvss);
          const cvssB = b.cvss === "-" ? -Infinity : parseFloat(b.cvss);
          return cvssB - cvssA;
        });
      case "clh":
        return allCves.sort((a, b) => {
          const cvssA = a.cvss === "-" ? -Infinity : parseFloat(a.cvss);
          const cvssB = b.cvss === "-" ? -Infinity : parseFloat(b.cvss);
          return cvssA - cvssB;
        });
      case "rhl":
        return allCves.sort((a, b) => b.risk_score - a.risk_score);
      case "rlh":
        return allCves.sort((a, b) => a.risk_score - b.risk_score);
      case "ihl":
        return allCves.sort((a, b) => b.impact - a.impact);
      case "ilh":
        return allCves.sort((a, b) => a.impact - b.impact);
      case "ehl":
        return allCves.sort((a, b) => b.exploitability - a.exploitability);
      case "elh":
        return allCves.sort((a, b) => a.exploitability - b.exploitability);
      case "ephl":
        return allCves.sort((a, b) => {
          const epssA = a.epss === "-" ? -Infinity : parseFloat(a.epss);
          const epssB = b.epss === "-" ? -Infinity : parseFloat(b.epss);
          return epssB - epssA;
        });
      case "eplh":
        return allCves.sort((a, b) => {
          const epssA = a.epss === "-" ? -Infinity : parseFloat(a.epss);
          const epssB = b.epss === "-" ? -Infinity : parseFloat(b.epss);
          return epssA - epssB;
        });
      default:
        return allCves;
    }
  };

  const handleSortChange = (e) => {
    setIsSortBy(e.value);
    setSelectedSortValue(e.label);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
    const removedRiskFilter = !updatedFilters.some((filter) => filter.eventKey === "8");
    const removedIsssueFilter = !updatedFilters.some((filter) => filter.eventKey === "issueStatus");

  if (removedRiskFilter) {
    setSelectedRiskLevel(null);
  }
  if (removedIsssueFilter) {
    setSelectedIssueStatus(null);
}
  };

  const updateTabsWithData = (cveData) => {
    let allAssets = cveData.reduce((acc, el) => {
      el.assets?.forEach((el) => {
        acc.push(
          `${el?.host
            ? el?.host
            : el?.domain
              ? el?.domain
              : el.ip && el.port
                ? `${el.ip} Port: ${el.port}`
                : el.ip
                  ? el.ip
                  : "-"
          }`
        );
      });
      return acc;
    }, []);
    const uniqueAssets = Array.from(new Set(allAssets)).map((name, index) => ({
      id: index,
      name: name || "-",
      type: name ? "Asset: " + name : "Asset: -",
      key: "2",
      active: false,
    }));

    const issueNames = Array.from(
      new Set(cveData.map((cve) => cve.issue_name))
    ).map((name, index) => ({
      id: index,
      name: name ? name : "-",
      type: name ? "Issue Name: " + name : "Issue Name: -",
      key: "1",
      active: false,
    }));

    const categories = Array.from(
      new Set(cveData.map((cve) => cve.scan_category))
    ).map((category, index) => ({
      id: index,
      name: category ? category : "-",
      type: category ? "Category: " + category : "Category: -",

      key: "3",
      active: false,
    }));

    setTabs([
      {
        eventKey: "1",
        title: "Security Issue",
        contentTitle: "Security Issue",
        data: Array.from(issueNames),
      },
      {
        eventKey: "2",
        title: "Relevant Assets",
        contentTitle: "Relevant Assets",
        data: Array.from(uniqueAssets),
      },

      {
        eventKey: "3",
        title: "Category",
        contentTitle: "Category",
        data: Array.from(categories),
      },
      {
        eventKey: "4",
        title: "Impact",
        contentTitle: "Impact",
        data: Array.from(impacts),
      },
      {
        eventKey: "5",
        title: "Exploitability",
        contentTitle: "Exploitability",
        data: Array.from(Exploitabilities),
      },
      {
        eventKey: "6",
        title: "CVSS",
        contentTitle: "CVSS",
        data: Array.from(CVSS),
      },
      {
        eventKey: "7",
        title: "EPSS",
        contentTitle: "EPSS",
        data: Array.from(EPSS),
      },
      {
        eventKey: "8",
        title: "Risk Score",
        contentTitle: "Risk Score",
        data: Array.from(scores),
      },
      {
        eventKey: "9",
        title: "Has CVE",
        contentTitle: "Has CVE",
        data: Array.from(hasCveFilterOptions),
      },
    ]);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchCves = async (id) => {
      try {
        const {
          data: { result, scanning, completionTime, seperatedResults, countGroups },
        } = await axios.get(`/issues/${id}`);
        const originalData = Object.entries(result)?.map((el) => el[1]);

        if (Object.keys(result).length === 0 && seperatedResults.length === 0) {
          setOriginalCves([]);
          setSeparatedCves([]);
          setCves([]);
          updateTabsWithData([]);
          setIsLoading(false);
          return;
        }
        setOriginalCves(originalData);  
        setSeparatedCves(seperatedResults); 

        const filteredData = ipParam
          ? seperatedResults.filter((cve) =>
            cve.ip === ipParam
          )
          : seperatedResults;

        // if (data?.task?.status == "Done" && data?.length == 0) {
        //   setIsFinished(true)
        // } else {
        //   setIsFinished(false)
        // }

        // if (data?.task?.status == "Running") {
        //   timeoutRef.current = setTimeout(() => fetchCves(id), 5000);
        // }
        // setScanningDetails({
        //   status: data?.task?.status,
        //   date: data?.task?.status == 'Interrupted' || data?.task?.status == 'Done' || data?.task?.status == 'Stopped' ? data?.task?.modification_time : new Date()
        // })
        setDoughnutChartType({
          labels: ["Open", "Reopen", "Pending Validation"],
          datasets: [
            {
              data: [
                countGroups.open || 0,
                countGroups.pending || 0,
                countGroups.pendingValidation || 0
              ],
              backgroundColor: colorList,
              hoverBackgroundColor: colorList,
              borderColor: "transparent",
              borderWidth: 0,
            },
          ],
        });
        setCves(filteredData);
        updateTabsWithData(originalData);
        setIsLoading(false);
        setScanningDetails({
          status: scanning,
          date: completionTime,
        });
      } catch (err) {
        if (err?.response?.data?.status == false) {
          setIsFinished(false);
          setScanningDetails({
            status: "Temporary error",
            date: new Date(),
          });
        }
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (routeParams?.id) {
      fetchCves(routeParams.id);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [routeParams.id]);
  const filterCategories = [
    { key: "2", data: assets, field: "assets", isAsset: true },
    { key: "4", data: impacts, field: "impact" },
    { key: "5", data: Exploitabilities, field: "exploitability" },
    { key: "6", data: CVSS, field: "cvss" },
    { key: "7", data: EPSS, field: "epss" },
    { key: "8", data: scores, field: "risk_score" },
  ];

  const allSortedData = sortData(cves, isSortBy);

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});
  const filteredCves = useMemo(() => {
    const dataToFilter = isChecked ? originalCves : separatedCves;
  
    return dataToFilter.filter((cve) => {
      const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
        return groupedFilters[eventKey].some((filter) => {
          if (eventKey === "1") {
            return (
              (filter.name === "-" &&
                (!cve.issue_name || cve.issue_name === "-")) ||
              (cve.issue_name &&
                cve.issue_name.toLowerCase() === filter.name.toLowerCase())
            );
          } else if (eventKey === "3") {
            return (
              (filter.name === "-" &&
                (!cve.scan_category || cve.scan_category === "-")) ||
              (cve.scan_category &&
                cve.scan_category.toLowerCase() === filter.name.toLowerCase())
            );
          } else if (eventKey === "9") {
            return (
              (filter.name.toLowerCase() === "no" && !cve.cve) ||
              (filter.name.toLowerCase() === "yes" && cve.cve)
            );
          } else if (eventKey === "8") {
            // Add this block to handle Risk Score filters
            const [min, max] = filter.name.split(": ")[1]
              .split("-")
              .map(Number);
            return cve.risk_score >= min && cve.risk_score <= max;
          }
          else if (eventKey === "issueStatus") {
            // Handle Issue Status filters
            return (
              isChecked
                ? cve.assets?.some((asset) => asset.issue_status === filter.name)
                : cve.issue_status === filter.name
            );
          } 
           else {
            return filterCategories.some((category) => {
              if (eventKey === category.key) {
                if (category.field === "assets") {
                  return cve.assets?.some((asset) => {
                    let assetName = asset.ip ? asset.ip : "";
                    if (asset.port) {
                      assetName += ` Port: ${asset.port}`;
                    } else if (asset.domain) {
                      assetName = asset.domain;
                    } else if (asset.host) {
                      assetName = asset.host;
                    }
                    assetName = assetName.trim().toLowerCase();
                    const filterName = filter.name.trim().toLowerCase();
                    return assetName === filterName;
                  });
                } else {
                  const score = cve[category.field] || 0;
                  const [min, max] = filter.name.split("-").map(Number);
                  return score >= min && score <= max;
                }
              }
              return false;
            });
          }
        });
      });
  
      const matchesSearchValue =
        searchValue === "" ||
        (cve.issue_name &&
          cve.issue_name.toLowerCase().includes(searchValue.toLowerCase())) ||
        (cve.scan_category &&
          cve.scan_category.toLowerCase().includes(searchValue.toLowerCase())) ||
        (cve.assets?.length > 0 &&
          cve.assets?.some(
            (el) =>
              (el.ip &&
                el.ip.toLowerCase().includes(searchValue.toLowerCase())) ||
              (el.port &&
                String(el.port)
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (el.domain &&
                el.domain.toLowerCase().includes(searchValue.toLowerCase())) ||
              (el.host &&
                el.host.toLowerCase().includes(searchValue.toLowerCase()))
          ));
  
      const matchesRiskLevel =
        !selectedRiskLevel ||
        (selectedRiskLevel === "high" &&
          cve.risk_score >= 80 &&
          cve.risk_score <= 100) ||
        (selectedRiskLevel === "medium" &&
          cve.risk_score >= 40 &&
          cve.risk_score <= 79) ||
        (selectedRiskLevel === "low" &&
          cve.risk_score >= 0 &&
          cve.risk_score <= 39);
      const matchesIssueStatus = !selectedIssueStatus ||
        (isChecked
          ? cve.assets?.some((asset) => asset.issue_status === selectedIssueStatus)
          : cve.issue_status === selectedIssueStatus);

          
          const matchesIpParam = !ipParam || (isChecked
            ? cve.assets?.some((asset) => asset.ip === ipParam)
            : cve.ip === ipParam);
            
            
  
      return matchesFilters && matchesSearchValue && matchesRiskLevel && matchesIssueStatus && matchesIpParam;
    });
  }, [
    isChecked,
    separatedCves,
    originalCves,
    groupedFilters,
    searchValue,
    selectedRiskLevel,
    selectedIssueStatus,
    filterCategories,
    ipParam
  ]);
  let highRisk = 0;
  let mediumRisk = 0;
  let lowRisk = 0;
  cves?.map((el) => {
    if (el.risk_score >= 80 && el.risk_score <= 100) {
      highRisk += 1;
    } else if (el.risk_score >= 40 && el.risk_score <= 79) {
      mediumRisk += 1;
    } else if (el.risk_score >= 0 && el.risk_score <= 39) {
      lowRisk += 1;
    }
  });
  const handleRiskClick = (riskLevel) => {
    let riskName;
    switch (riskLevel) {
        case "high":
            riskName = "Risk Score: 80-100";
            break;
        case "medium":
            riskName = "Risk Score: 40-79";
            break;
        case "low":
            riskName = "Risk Score: 0-39";
            break;
        default:
            riskName = null;
    }

    setActiveFilters((currentFilters) => {
        const filteredFilters = currentFilters.filter((filter) => filter.eventKey !== "8");
        const updatedFilters = webUpdatesFilter(`${riskName}`, filteredFilters, "8", "Risk Score");
        setSelectedRiskLevel(riskLevel);

        return updatedFilters;
    });
};


  const handleSwitchChange = (e) => {
    const isSwitchChecked = e.target.checked;
    setIsChecked(isSwitchChecked);
    const newCves = isSwitchChecked ? originalCves : separatedCves;
    setCves(newCves);
    if (!isSwitchChecked) {
      setCollapsedGroups(newCves.map(() => true));
    }
  };
  const toggleCollapse = (groupName) => {
    setCollapsedGroups((prevState) => ({
      ...prevState,
      [groupName]: !prevState[groupName],
    }));
  };
  useEffect(() => {
    // Default all groups to collapsed (hidden)
    const initialCollapsedState = cves.reduce((acc, group) => {
      acc[group.issue_name] = false;
      return acc;
    }, {});
    setCollapsedGroups(initialCollapsedState);
  }, [cves]);
  

  const handleIssueClick = (issueStatus) => {
    const normalizedIssueStatus = issueStatus === 'Open' ? 'OPEN' : issueStatus;
    setActiveFilters((currentFilters) => {
        const filteredFilters = currentFilters.filter((filter) => filter.eventKey !== "issueStatus");
        const updatedFilters = webUpdatesFilter(`${normalizedIssueStatus}`, filteredFilters, "issueStatus", "Issue Status");
        setSelectedIssueStatus(normalizedIssueStatus);

        return updatedFilters;
    });
};

  
  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div>
          <BreadCrumb
            breadcrumbItems={["Security Issues"]}
            showScanning={true}
            endTime={scanningDetails?.date || new Date()}
            status={scanningDetails?.status}
            isLastUpdate={true}
          />
          {/* <Tabs navItems={navItems} initialActiveKey="home" /> */}

          <div>
            {cves?.length > 0 ? (
              <div className="main_container_style ">
                <div className="row">
                  <div className="col-6">
                    <HighlightsRisk
                      highRisk={highRisk}
                      mediumRisk={mediumRisk}
                      lowRisk={lowRisk}
                      onRiskClick={handleRiskClick}
                    />
                  </div>
                  <div className="col-6">
                    <GenericCard
                      children={
                        <IssueStatusChart
                          data={doughnutChartType}
                          options={doughnutChartOptions}
                          onIssueClick={handleIssueClick}
                        />

                      }
                      title={"Issues Status"}
                    />
                  </div>
                </div>

                {/* <IssuesFinding
                  handleSearchChange={handleSearchChange}
                  searchValue={searchValue}
                /> */}
                <div className="mt-4 mb-3">
                  <GroupSwitch
                    id="group-switch"
                    label="Group Issues by Title"
                    checked={isChecked}
                    onChange={handleSwitchChange}
                    className="group-switch"
                  />
                </div>
                <OptionsSection
                  cves={cves}
                  filteredCves={filteredCves}
                  tabs={tabs}
                  handleFilterChange={handleFilterChange}
                  activeFilters={activeFilters}
                  removeFilter={removeFilter}
                  handleSortChange={handleSortChange}
                  searchValue={searchValue}
                  handleSearchChange={handleSearchChange}
                  selectedSortValue={selectedSortValue}
                  isChecked={isChecked}
                />
                <CveList
        isChecked={isChecked}
        filteredCves={filteredCves}
        collapsedGroups={collapsedGroups}
        toggleCollapse={toggleCollapse}
      />
              </div>

            ) : isFinished ? (
              <div className="data-processing-screen-main">
                <NoIssue className="icon mb-2" />
                <h1 className="mb-2">Issue-Free Verification</h1>
                <p>At the last scan, no issues were detected.</p>
              </div>
            ) : (
              <div className="data-processing-screen-main">
                <DataProcess className="icon mb-2" />
                <h1 className="mb-2">Data in Process</h1>
                <p>Hang tight! We're currently processing your data.</p>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Cves;
