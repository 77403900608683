import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, FormGroup, Modal, FormSelect } from "react-bootstrap";
import { Field, Formik, Form } from "formik";
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import axios from "../util/axios";

const labelStyle = 'original'
const timezones = {
  ...allTimezones,
}

function UserTimezoneModal(props) {
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })
  const [isSubmit, setisSubmit] = useState(false)
  const [user, setUser] = useState(null)
  const initialValues = {
   timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const handleSubmit = (values, helpers) => {
    setisSubmit(true)
    axios
      .post(`/users/timezone`, values)
      .then((response) => {
        setisSubmit(false)
        props.onHide(); 
      })
      .catch((e) => {
        setisSubmit(false)
      });
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user)
  }, []);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="confirmation-modal"
    >
      <Modal.Body className="p-4 text-center">
        <React.Fragment>
          <Formik
           initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ handleChange, handleBlur, values, errors, validateForm }) => (
              <Form>
                
                <Field
                as="select"
                name="timezone"
                render={({ field, form: { touched, errors }, meta }) => (
                  <FormGroup controlId="timezone"   className="my-3">
                    <FloatingLabel
                      controlId="floatingSenderName"
                      label="Timezone"
                    >
                      <FormSelect
                        value={field.value}
                        onChange={field.onChange}
                        className={
                          meta.touched && meta.error ? "is-invalid" : " "
                        }
                        {...field}
                      >
                       
                       <option value="">Select Timezone</option>
                      {options.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                      </FormSelect>
                      {meta.touched && meta.error ? (
                        <div className="invalid-feedback">{meta.error}</div>
                      ) : null}
                    </FloatingLabel>
                  </FormGroup>
                )}
              />
                <Button type="submit" disabled={isSubmit}>Continue</Button>
              </Form>
            )}
          </Formik>
        </React.Fragment>
        {/* Removed Modal.Footer for simplicity */}
      </Modal.Body>
    </Modal>
  );
}

export default UserTimezoneModal;
