import React, { useEffect, useState } from "react";

const AdminProtectedRoute = (props) => {
    const [isAdmin, setIAdmin] = useState(false);

    const checkAdminAccess = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.member_level == 9) {
            console.log('admin board', user)
            setIAdmin(true);
        }
    }

    useEffect(() => {
        checkAdminAccess();
    }, [isAdmin]);

    return (
        <React.Fragment>
            {
                isAdmin ? props.children : null
            }
        </React.Fragment>
    );
}

export default AdminProtectedRoute;