import React, { useState, useEffect } from "react";
import downred from "../../assets/images/downred.png";
import upgreen from "../../assets/images/upgreen.png";
import vertical_line from "../../assets/images/vertical_line.png";
import left_pointer_line from "../../assets/images/left_pointer_line.png";

const Loader = () => {
    return (
      <div className="loader">
        {Array.from({ length: 15 }).map((_, index) => (
          <div key={index} className="loader-bar" />
        ))}
      </div>
    );
  };


const RadarLoader = () => (
    <div className="radar-loader">
      <div className="radar-sweep"></div>
      <div className="particles">
        <div className="particle part1" ></div>
        <div className="particle part2" ></div>
        <div className="particle part3" ></div>

      </div>
    </div>
  );

const RiskCard = () => {
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        // Hide loader after 5 seconds
        const timer = setTimeout(() => setLoading(false), 5000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <React.Fragment>
            <div className="riskcard_main font">
                <div className="section_1">
                    <div className="subsection">
                        <div className="risksubcard_heading">Servers and Hosts</div>
                        <div className="risksubcard_update">
                            <div>Scanning Complete : 2 days ago</div>
                            <div className="issue_text">12 Issues</div>
                        </div>
                        {loading ? <Loader /> : (
                        <div className="risksubcard_nextupdate">Next Scanning in 7 days</div>)}
                    </div>
                    <div className="subsection">
                        <div className="risksubcard_heading">Network Security</div>
                        <div className="risksubcard_update">
                            <div>Scanning Complete : 2 days ago</div>
                            <div className="issue_text">3 Issues</div>
                        </div>
                        {loading ? <Loader /> : (
                        <div className="risksubcard_nextupdate">Next Scanning in 7 days</div>)}
                    </div>
                </div>
                <div className="section_2">
                {loading ? <RadarLoader /> : (
                    <div>
                        <div className="content_1">
                            <div className="content_1_value">22</div>
                            <div><img src={upgreen} /></div>
                        </div>
                        <div className="content_2">
                            <div>High Risk Assets</div>
                            <div><img src={vertical_line} /></div>
                        </div>
                        <div className="content_1">
                            <div className="content_1_value">32</div>
                            <div><img src={downred} /></div>
                        </div>
                        <div className="content_2">
                            <div>High Risk</div>
                            <div>Security Issues</div>
                            <div><img src={vertical_line} /></div>
                        </div>
                        <div className="content_1">
                            <div className="content_1_value">20</div>
                            <div><img src={upgreen} /></div>
                        </div>
                        <div className="content_2">
                            <div>Armory Tested</div>
                            <div>Security Issues</div>
                        </div>
                        
                    </div>
                    )}
                </div>
                <div className="section_3">
                    <div className="subsection">
                        <div className="risksubcard_heading">Web Applications</div>
                        <div className="risksubcard_update">
                            <div>Scanning Complete : 2 days ago</div>
                            <div className="issue_text">24 Issues</div>
                        </div>
                        {loading ? <Loader /> : (
                        <div className="risksubcard_nextupdate">Next Scanning in 7 days</div>)}
                    </div>
                    <div className="subsection">
                        <div className="risksubcard_heading">Data Exposure</div>
                        <div className="risksubcard_update">
                            <div>Scanning Complete : 2 days ago</div>
                            <div className="issue_text">32 Issues</div>
                        </div>
                        {loading ? <Loader /> : (
                        <div className="risksubcard_nextupdate">Next Scanning in 7 days</div>)}
                    </div>
                </div>
                <div className="pointer_left"><img src={left_pointer_line}/></div>
                <div className="pointer_right"><img src={left_pointer_line}/></div>
                <div className="gradient"/>
            </div>
        </React.Fragment>

    )
}

export default RiskCard