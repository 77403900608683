import React, { useState, useEffect, useRef, useContext } from "react";
import ArmoryTestedModal from "../../components/armorytested-modal/ArmoryTestedModal";
import OffenseCard from "../../components/offense-card/OffenseCard";
import skeletonIcon from "../../assets/images/skeleton.png"; // Ensure the correct import path
import { useParams, useOutletContext } from "react-router-dom";
import { ScannerContext } from "../../components/ScannerContext";
import axios from "../../util/axios";

const Dashboard = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const [cardData, setCardData] = useState([
    {
      CardTitle: "SSH Dictionary Attack",
      image: skeletonIcon,
    },
    {
      CardTitle: "FTP Dictionary Attack",
      image: skeletonIcon,
    },
    {
      CardTitle: "Subdomain Takeover",
      image: skeletonIcon,
    },
    {
      CardTitle: "Email Spoofing",
      image: skeletonIcon,
    },
    {
      CardTitle: "Log4Shell",
      image: skeletonIcon,
    },
    {
      CardTitle: "FTP Default Credentials",
      image: skeletonIcon,
    },
  ]);

  const [isLoading, setIsLoading] = useState(true);

  const processData = (response, serviceType) => {
    if (!response?.data || response?.data[serviceType]?.length === 0)
      return null;

    const dataField = response.data[serviceType];
    const successCount = response.data.groups?.creds?.true || 0;
    const failCount = response.data.groups?.creds?.false || 0;

    const data = dataField.map((item) => {
      return {
        id: item?.id,
        ip: `${serviceType.toUpperCase()} Detected on ${item?.ip?.ip}`,
        passAuth:
          serviceType === "ssh"
            ? item.hasPassworAuth
            : item?.is_ftp_anon
            ? "Password Authentication Enabled"
            : "Password Authentication Disabled",
        dictionary:
          serviceType === "ssh"
            ? item.passAuth
              ? "Dictionary Attack Attempted"
              : "Dictionary Attack Not Possible"
            : "Dictionary Attack Attempted",
        result: item?.creds?.length
          ? `Attack Succeeded on ${item?.ip?.ip}`
          : `Attack Failed on ${item?.ip?.ip}`,
      };
    });

    return {
      CardTitle: `${serviceType.toUpperCase()} Dictionary Attack`,
      data,
      success: successCount,
      fail: failCount,
      data1: [
        {
          value: successCount,
          color: "#FF6155",
          text1: " assets are vulnerable",
          text2: "",
        },
      ],
      data2: [
        {
          value: failCount,
          color: "#3DDC97",
          text1: "",
          text2: " assets are not vulnerable",
        },
      ],
    };
  };

  const fetchData = async () => {
    try {
      const sshResponse = await axios.get(`scans/${routeParams?.id}/ssh`);
      const ftpResponse = await axios.get(`scans/${routeParams?.id}/ftp`);

      const sshData = processData(sshResponse, "ssh");
      const ftpData = processData(ftpResponse, "ftp");

      setCardData([
        sshResponse?.data?.ssh?.length
          ? sshData
          : {
              CardTitle: "SSH Dictionary Attack",
              image: skeletonIcon,
            },
        ftpResponse?.data?.ftp?.length
          ? ftpData
          : {
              CardTitle: "FTP Dictionary Attack",
              image: skeletonIcon,
            },
        {
          CardTitle: "Subdomain Takeover",
          image: skeletonIcon,
        },
        {
          CardTitle: "Email Spoofing",
          image: skeletonIcon,
        },
        {
          CardTitle: "Log4Shell",
          image: skeletonIcon,
        },
        {
          CardTitle: "FTP Default Credentials",
          image: skeletonIcon,
        },
      ]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  // const fetchIntervalRef = useRef();
  useEffect(() => {
    // clearInterval(fetchIntervalRef.current);

    // if (scanningStatus === 0) {
    //   setIsLoading(false);
    //   setIsDataInProcess(true);
    // } else {
    //   setIsDataInProcess(false);
    //   if (scanningStatus === 3 || scanningStatus === -1) {
    //     setIsDataisPartiallyProcessing(false);
    //     setIsLoading(true);
    //     fetchSSHData();
    //   } else if (scanningStatus === 2 || scanningStatus === 1) {
    //     setIsDataisPartiallyProcessing(true);
    //     setIsLoading(true);
    //     fetchIntervalRef.current = setInterval(fetchSSHData, 5000);
    //   }
    // }
    // return () => {
    //   clearInterval(fetchIntervalRef.current);
    // };

    fetchData();
  }, [scanningStatus, routeParams?.id]);

  return (
    <React.Fragment>
      <ArmoryTestedModal />
      <div className="d-flex flex-wrap align-items-center justify-content-center gap-4 mb-5">
        {cardData.map((card, index) => (
          <OffenseCard key={index} cardsData={card} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
