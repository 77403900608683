import React, { useMemo } from "react";

const ComparisonChart = ({ cardsData }) => {
  const maxValue1 = useMemo(() => {
    return Math.max(...(cardsData?.data1.map((item) => item.value) || []));
  }, [cardsData?.data1]);
  const maxValue2 = Math.max(...cardsData?.data2.map((item) => item.value));

  return (
    <>
      <div className="offensecard_results w-100 justify-content-start">
        <div className="offensecard_results_title mb-2">Results</div>
        <div className="offensecard_results_content w-100">
          <div className="offensecard_results_text">
            <div className="offensecard_success">Attack Succeeded</div>
            <div>
              <span className="success_value fw-bold">
                {cardsData?.success}{" "}
              </span>
              assets are vulnerable
            </div>
            <div className="offensecard_failed">Attack Failed</div>
            <div>
              {/* {data2text1} */}
              <span className="failed_value fw-bold">
                {cardsData?.fail || 0}{" "}
              </span>
              assets are not vulnerable
            </div>
          </div>
          <div className="offensecard_chart">
            <div className="bar-graph">
              <div className="bar-container">
                {cardsData?.data1.map((item) => (
                  <div
                    key={item?.value}
                    className="bar1"
                    style={{
                      height: `100%`,
                      backgroundColor: item.color,
                    }}
                  >
                    <span className="bar-value">{cardsData?.fail}</span>
                  </div>
                ))}
              </div>
              <div className="bar-container">
                {cardsData?.data2.map((item) => (
                  <div
                    key={item?.value}
                    className="bar2"
                    style={{
                      height: `100%`,
                      backgroundColor: item.color,
                    }}
                  >
                    <span className="bar-value">{cardsData?.success}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparisonChart;
