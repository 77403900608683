import React, { useState, useEffect, useCallback,useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BaseTable from "../../../components/table/BaseTable";
import axios from "../../../util/axios";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { ReactComponent as Export } from "../../../assets/images/Export.svg";
import request from "../../../util/fetch";
import CyberServices from './CyberServices';
import InfiniteScroll from "react-infinite-scroller";
import { Triangle } from "react-loader-spinner";




const CyberServicesListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [CyberServicesListing, setCyberServicesListing] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 2;
  const [fetching, setFetching] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  // const [allOrg, setAllOrg] = useState([]);

  const handleDeleteClick = (id) => {
    setDeleteItemId(id);
    setShowModal(true);
  };

  const handleEditClick = (data) => {
    setEditData(data);
    setShowEditModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteItemId) {
      try {
        await axios.delete(`/admin/cyber-services/${deleteItemId}`);
        fetchServices();
        toast.success("Security Issue deleted successfully!");
      } catch (error) {
        toast.error("Failed to delete the item!");
      }
    }
    setShowModal(false);
    setDeleteItemId(null);
  };

  const columns = useMemo(
    () => [
      { Header: "Title", accessor: "title", isSortable: true },
      {
        Header: "Objective",
        key: "Objective",
        isSortable: true,
        accessor: (row) => <div>{renderObjective(row.objective)}</div>,
      },
      {
        Header: "Date",
        isSortable: true,
        key: "Date",
        accessor: (row) => (
          <div>
            {row.submit_date ? (
              <Moment format="DD/MM/YYYY">{row.submit_date}</Moment>
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        Header: "Document",
        isSortable: true,
        key: "Document",
        accessor: (row) => (
          <div className="cyberservicessection_1_part3 d-flex">
            <a>{row.doc}</a>
            <span className="table-export cursor-pointer fs-6 ms-3">
              {row.doc ? (
                <p
                  onClick={downloadFile(row)}
                  className="table-export cursor-pointer fs-6 ms-3"
                >
                  <Export className="export-icon" height={25} width={25} />
                  Download
                </p>
              ) : (
                ""
              )}
            </span>
          </div>
        ),
      },
      {
        Header: "Risk Score",
        isSortable: true,
        key: "Risk Score",
        accessor: (row) => (
          <div
            className={
              row.risk_score >= 80
                ? "listing_value_primary"
                : row.risk_score >= 40 && row.risk_score < 80
                ? "listing_value_secondary"
                : "listing_value_tertiary"
            }
          >
            {row.risk_score}%
          </div>
        ),
      },
      {
        Header: "Action",
        key: "Action",
        accessor: (row) => (
          <div className="delete-main">
            <i
              className="bi bi-pencil mx-3"
              onClick={() => handleEditClick(row)}
            ></i>
            <i
              className="bi bi-trash"
              onClick={() => handleDeleteClick(row.id)}
            ></i>
          </div>
        ),
      },
    ],
    []
  );

  const downloadFile = (row) => () => {
    request("/admin/cyber-services/download/" + row.id)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = row.doc;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Download error:", error));
  };
  async function fetchServices(page, pageSize) {
    setIsLoading(true);
    try {
      let res = await axios.get("/admin/cyber-services", {
        params: { page: page, pageSize: pageSize },
      });
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  const fetchItems = useCallback(
    async (page) => {
      if (fetching) {
        return;
      }
      setFetching(true);
      try {
        const data = await fetchServices(page, pageSize);
        setTotalPages(data.totalPages);
        setCyberServicesListing((currentItems) => [...currentItems, ...data.items]);
        setCurrentPage(page);
      } finally {
        setFetching(false);
      }
    },
    [fetching, pageSize]
  );


  useEffect(() => {
    fetchItems(1)
  }, []);
  const renderObjective = (objective) => {
    if (objective.includes("https")) {
      return (
        <a
          href={objective}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer cyberservicessection_1_part3"
        >
          {objective}
        </a>
      );
    } else {
      return <span>{objective}</span>;
    }
  };
  const loader = (
    <div key="loader" className="loader">
      <Triangle
        height="80"
        width="80"
        color="#fff"
        ariaLabel="triangle-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );

  return (
    <>
      <React.Fragment>
        <Container fluid className="main_container_style">
          <Row className="justify-content-start m-0">
            <Col>
              <div className="d-flex align-items-center justify-content-between">
                {" "}
                <h1 className="fw-normal mb-3">Security Issues</h1>
                <Link
                  to="/admin/security-issues/create"
                  className="add-button header-title"
                >
                  <i className="bi bi-plus-circle mx-2"></i>Add Security Issue
                </Link>
              </div>
            </Col>
          </Row>
          <div className="scroll-container">
            <InfiniteScroll
              pageStart={0}
              loadMore={() => fetchItems(currentPage + 1)}
              hasMore={currentPage < totalPages}
              loader={loader}
              useWindow={false}
            >
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={CyberServicesListing}
                selectable={true}
                showCheckboxes={false}
                action={false}
                loading={isLoading}
                isPagination={false}
              />
            </InfiniteScroll>

          </div>
        </Container>
      </React.Fragment>
      <Modal show={showModal} centered onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete it?</Modal.Title>
          <div className="d-flex justify-content-between gap-2 w-40">
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleConfirmDelete}>
              Yes
            </Button>
          </div>
        </Modal.Header>
      </Modal>
      <Modal show={showEditModal} centered onHide={handleEditClose}>
        <Modal.Body>
          <CyberServices
            editData={editData}
            fetchServices={fetchServices}
            handleEditClose={handleEditClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CyberServicesListing;
