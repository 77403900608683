import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  FormCheck,
  FormControl,
  FormGroup,
  Row,
  
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, useFormikContext } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../util/axios";
import * as Yup from "yup";





const EasmForm = ({ item, loadMore }) => {
  
  const navigate = useNavigate();

  const [submitBtn, setSubmitBtn] = useState({});
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let API_ENDPOINT_NODE = "/scans/create";

  const filterValues = (values) => {
    let items =
      values?.services && values.services.length > 0
        ? values.services.map((service) => {
            return service.service_id;
          })
        : [1];
    return items;
  };
  const initialValues = {
    scan_name: item?.scan_name || "",
    scan_input: item?.scan_input || "",
    services: filterValues(item),
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    if (item) {
      initialValues["scan_input"] = item.scan_input;
      initialValues["scan_name"] = item.scan_name;
      initialValues["services"] = filterValues(item);
    }
  }, [item]);
  const schema = Yup.object().shape({
    scan_name: Yup.string().required("Scan name is required"),
    scan_input: Yup.string().required("Domain/IP is required"),
    services: Yup.array()
      .max(14, "Only 10 services are allowed")
      .required("Please select atleast one service"),
  });

  const loadServices = async () => {
    try {
      const { data } = await axios.get("/scans/services");
      console.log("my data", data);
      setServices(data);

      data.map((srv, index) => {
        if (1 === srv.checked) {
          if (-1 <= initialValues.services.findIndex((item) => item === srv.id))
            initialValues.services.push(srv.id);
        }
      });
      console.log("initial", initialValues);
    } catch (err) {}
  };
  const init = async () => {
    await loadServices();
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    init();
  
  }, []);
  const handleSubmit = (values, helpers) => {
    setIsLoading(true);
    console.log(values);

    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        setIsLoading(false);
        const data = response.data;

        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });

        setTimeout(() => {
          // navigate("/easm/" + data.data.id);
          navigate("/easm");
        }, 500);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };
  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Generating scan request..." });
    } else {
      setSubmitBtn({ disabled: false, label: "Start Scan" });
    }
  }, [isLoading]);
  const onDomainChange = (e, cb) => {
    let extractr = e.target.value.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im
    );
    if (extractr && extractr.length > 0) e.target.value = extractr[1];

    cb(e);
  };
  return (
    <React.Fragment>
  
      <Card className="mb-3">
        <Card.Body>
          <Card.Title
            className="text-uppercase d-flex justify-content-between text-white p-1 pb-3"
            as="h3"
          >
            EASM Analysis{" "}
          </Card.Title>
          <Formik
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={({
              handleChange,
              handleSubmit,
              handleBlur,
              values,
              errors,
              validateForm,
            }) => (
              <Form>
                <Row>
                  <Col md="6" className="pb-2">
                    {/* Scan Name Field */}
                    <Field
                      name="scan_name"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup controlId="scan_name">
                          <FloatingLabel
                            controlId="floatingSenderName"
                            label="My website scan"
                          >
                            <FormControl
                              type={"text"}
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              placeholder="example.com or 8.8.8.8"
                              className={
                                meta.touched && meta.error ? "is-invalid" : ""
                              }
                              {...field}
                            />
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FloatingLabel>
                        </FormGroup>
                      )}
                    />
                  </Col>
                  <Col md="6">
                    {/* Scan Input Field */}
                    <Field
                      name="scan_input"
                      render={({ field, form: { touched, errors }, meta }) => (
                        <FormGroup controlId="scan_input">
                          <FloatingLabel
                            controlId="floatingSenderName"
                            label="example.com or 8.8.8.8"
                          >
                            <FormControl
                              type={"text"}
                              size="lg"
                              value={field.value}
                              onChange={field.onChange}
                              placeholder="example.com or 8.8.8.8"
                              className={
                                meta.touched && meta.error ? "is-invalid" : ""
                              }
                              {...field}
                            />
                            {meta.touched && meta.error ? (
                              <div className="invalid-feedback">
                                {meta.error}
                              </div>
                            ) : null}
                          </FloatingLabel>
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
                <Row className=" my-3">
                  <Col>
                    <Row>
                      {services.map((service, index) => {
                        return (
                          <Col lg="4">
                            <div className="form-check pb-2">
                              <Field
                                type="checkbox"
                                name={`services`}
                                value={service.id}
                                checked={
                                  values.services.findIndex(
                                    (item) => parseInt(item) === service.id
                                  ) >= 0
                                }
                                className="form-check-input"
                              />
                              <label>{service.title}</label>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                  <Col lg="2">
                    {/* Submit Option */}
                    {item ? <Button
                      onClick={loadMore}
                      className="btn bg-primary btn-full"
                      id="singup-btn"
                      disabled={submitBtn.disabled}
                    >
                      Load Results
                    </Button> : <Button
                      type="submit"
                      className="btn bg-primary btn-full"
                      id="singup-btn"
                      disabled={submitBtn.disabled}
                    >
                      {submitBtn.label}
                    </Button>}
                  </Col>
                </Row>
              </Form>
            )}
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default EasmForm;
