import React from 'react'
import { ReactComponent as NoDataIcon } from '../../assets/images/no-data.svg'

function NoData() {
    return (
        <div className='no-data-container'>
            <div className='no-data-icon'><NoDataIcon /></div>
            <div className='no-data-text'>Data in Process</div>
        </div>
    );
}

export default NoData
