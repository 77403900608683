import React, { useState, useEffect } from 'react';
import globe from '../../assets/images/globe.png';
import subarrow from '../../assets/images/subarrow.png';
import up from '../../assets/images/up.png';
import down from '../../assets/images/down.png';
import info from '../../assets/images/info.png';
import line from '../../assets/images/line.png';
import location from '../../assets/images/location.png';
import stack from '../../assets/images/stack.png';
import host from '../../assets/images/host.png';

const Loader = () => {
  return (
    <div className="loader_circular">
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
}

const AssetDiscoveryCard = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set a single timeout to hide the loader after 5 seconds
    const timer = setTimeout(() => setIsLoading(false), 5000);

    // Clear the timeout on cleanup
    return () => clearTimeout(timer);
  }, []);


  const cardContents = [
    {
      image: globe,
      subContents: [
        { text: "Total" },
        { text: "Domains:" },
      ],
      subContent2: "33",
      subContent3: subarrow,
      subContents4: [
        {
          image: up,
          text: "3 uploaded"
        },
        {
          image: down,
          text: "6 removed"
        }
      ],
      subContent5: line
    },
    {
      image: location,
      subContents: [
        { text: "Total" },
        { text: "IPs:" },
      ],
      subContent2: "62",
      subContent3: subarrow,
      subContents4: [
        {
          image: up,
          text: "3 uploaded"
        },
        {
          image: down,
          text: "6 removed"
        }
      ],
      subContent5: line
    },

    {
      image: host,
      subContents: [
        { text: "Total" },
        { text: "Hostname:" },
      ],
      subContent2: "12",
      subContent5: line
    },
    {
      image: stack,
      subContents: [
        { text: "Total" },
        { text: "Web server:" },
      ],
      subContent2: "42",
    },
  ];

  return (
    <React.Fragment>
      <div className='card-asset-main font'>
        <div className='card_title'>Assets Discovery</div>
        <div className='card_assets'>
          {cardContents.map((content, index) => (
            <div className='card_content' key={index}>
              <div><img src={content.image} alt='' /></div>
              <div className='subcontent1'>
                {content.subContents.map((subContent, subIndex) => (
                  <div key={subIndex}>{subContent.text}</div>
                ))}
              </div>
              {isLoading ? (
                <Loader /> // Show the loader in place of all the contents
              ) : (
                <>
                  <div className='subcontent2'>{content.subContent2}</div>
                  {content.subContent3 && (
                    <div className='subcontent3'><img src={content.subContent3} alt='' /></div>
                  )}
                  {content.subContents4 && (
                    <div className='subcontent4'>
                      {content.subContents4.map((subContent, subIndex) => (
                        <div className='subtosubcontent1' key={subIndex}>
                          <img src={subContent.image} alt='' />
                          <div className='ms-1 me-1'>{subContent.text}</div>
                          <img src={info} alt='' />
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
              {index !== cardContents.length - 1 && (
                <img className='subcontent5' src={content.subContent5} alt='' />
              )}
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AssetDiscoveryCard;
