import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button } from "react-bootstrap";
import BaseFilter from "../../components/filter/BaseFilter";
import armorytestedIconSmall from "../../assets/images/armorytestedIconSmall.png";
import { ReactComponent as Bulb } from "../../assets/images/bulb.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import { ReactComponent as Close } from "../../assets/images/xmark.svg";
import CveArrow from "../../assets/images/border-cve.png";
import DecisionToggle from "../../components/badge/DecisionToggle";

const CvesPopup = ({ show, header, handleClose, details, isGroup,asset, ...props }) => {
  const [explanationOpen, setExplanationOpen] = useState(true);
  const [relatedAssetsOpen, setRelatedAssetsOpen] = useState(true);
  const [remediationOpen, setRemediationOpen] = useState(true);
  const [riskCalculationOpen, setRiskCalculationOpen] = useState(true);

  const toggleExplanation = () => {
    setExplanationOpen(!explanationOpen);
  };
  const toggleRelatedAssets = () => {
    setRelatedAssetsOpen(!relatedAssetsOpen);
  };
  const toggleRiskCalculation = () => {
    setRiskCalculationOpen(!riskCalculationOpen);
  };
  const toggleRemediation = () => {
    setRemediationOpen(!remediationOpen);
  };
  let color = "";
  if (details?.risk_score >= 80 && details?.risk_score <= 100) {
    color = "#ff6155";
  } else if (details?.risk_score >= 40 && details?.risk_score <= 79) {
    color = "#f3a738";
  } else if (details?.risk_score >= 0 && details?.risk_score <= 39) {
    color = "#3ddc97";
  }

  const renderRelatedAssets = (el) => {
    let assetInfo = []
    if (el?.ip)
      assetInfo.push(el.ip);
    if (el?.host)
      assetInfo.push(el.host);
    if (el?.domain)
      assetInfo.push(el.domain);
  // eslint-disable-next-line no-unused-expressions
  el?.port ? assetInfo.push(`Port ${el.port}`) : "";
    return (assetInfo.length > 0) ? assetInfo.join(" | ") : "-";

  }

  let hasGenericData = false;
  details?.assets?.map((el) => {
    if (
      el?.generic_data1 &&
      (el?.generic_data1?.length > 0 ||
        el?.generic_data1?.pages?.length > 0 ||
        el?.generic_data1?.certificates?.length > 0)
    ) {
      hasGenericData = true;
    }
  });
  let genericDataLabel = "";
  let issuesFor = "";
  let certificatesSerial = [];

  if (hasGenericData) {
    if (details?.service_id == 16 || details?.service_id == 17) {
      issuesFor = "crediantals";
      genericDataLabel = "Found Credentials";
    } else if (details?.service_id == 15) {
      issuesFor = "login";
    } else if (details?.service_id == 5) {
      issuesFor = "certificates";
      genericDataLabel = "Certificate serial number";

      details.assets.forEach((entry) => {
        if (entry.generic_data1) {
          certificatesSerial = certificatesSerial.concat(
            entry.generic_data1.certificates
          );
        }
      });
    }
  }
  return (
    <>
      <Offcanvas
        style={{ width: "40%" }}
        show={show}
        onHide={handleClose}
        placement="end"
        {...props}
      >
        <Offcanvas.Header>
          <div className="Cves-popup-header d-flex justify-content-between align-items-center w-100">
            <div className="d-flex flex-column justify-content-between align-items-start popup-left">
              <div className="d-flex justify-content-between align-items-center">
                <h3>{details?.issue_name}</h3>
              </div>
              <div className="sub-title mt-2">{details?.scan_category}</div>
            </div>
            <div className="d-flex justify-content-between align-items-center popup-right">
              <div className="d-flex flex-column justify-content-between align-items-center">
                <div className="d-flex  justify-content-between align-items-center gap-5">
                  <div className="cvss-svore">
                    <h5>Risk Score </h5>
                  </div>
                  <Close onClick={handleClose} className="close-icon" />
                </div>
                <div className="percentage" style={{ color: color }}>
                  {details?.risk_score}%
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Header>
        <hr className="popup-hr-main" />
        <Offcanvas.Body>
          <div className="Cves-popup-main">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                marginTop: "-10px",
                marginBottom: "8px",
                height: "53px",
              }}
            >
              <div>
                <h3 className="text-white m-0">Summary</h3>
              </div>
              <div style={{ marginRight: "-27px" }}>
                <BaseFilter
                  showFilter={false}
                  exportHeader={[
                    "Security Issue",
                    "Relevant Assets",
                    "Category",
                    "Impact",
                    "Exploitability",
                    "CVSS",
                    "EPSS",
                    "Risk Score",
                    "Summary",
                    "Explanation",
                    "Remediation",
                  ]}
                  exportTitle={"Issues Findings"}
                  exportRows={
                    details &&
                    [details].map((el) => [
                      el.issue_name,
                      el.assets?.length > 0
                        ? el?.assets
                          ?.map((el) =>
                            el?.host
                              ? el?.host
                              : el?.domain
                                ? el?.domain
                                : el.ip && el.port
                                  ? `${el.ip} Port: ${el.port}`
                                  : el.ip
                                    ? el.ip
                                    : "-"
                          )
                          ?.join(" | ")
                        : "",
                      el.scan_category,
                      el.impact + "%",
                      el?.exploitability + "%",
                      el?.cvss,
                      el?.epss,
                      el.risk_score + "%",
                      el.summary ? el.summary.replace(/,/g, "") : "",
                      `${el.explanation.replace(/,/g, "") || ""}`,
                      el.remediation
                        ? el.remediation
                          .replace(
                            "Please see the references for more resources supporting you with this task.",
                            ""
                          )
                          .replace(
                            "Please see the references for more information.",
                            ""
                          )
                          .replace(/^\s+|\s+$/g, "")
                          .split(" | ")
                          .filter((i) => i)
                          .join(" | ")
                        : "No Remediation found.",
                    ])
                  }
                />
              </div>
            </div>

            <div className="summary">
              <p>{details?.summary}</p>
            </div>
            {/* Explanation */}
            <div className="popup-dropdown" onClick={toggleExplanation}>
              <ArrowDown
                height={16}
                width={16}
                className={`base-drop-down-arrow ${explanationOpen ? "open" : ""
                  }`}
              />
              <h3 className="text-white">Explanation</h3>
            </div>
            {explanationOpen && (
              <div className="summary mb-3">
                <div className="summary mb-3">
                  <p
                    className="text-white mt-3"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {details?.explanation || "N/A"}
                  </p>
                </div>
                {details?.cve && (
                  <div className="summary ">
                    {details?.cve?.split(" | ")?.map((cve) => {
                      return <p className="text-white mb-3">{cve}</p>;
                    })}
                  </div>
                )}
                {hasGenericData && (
                  <h4 className="text-white">{genericDataLabel}</h4>
                )}
                {hasGenericData &&
                  issuesFor == "crediantals" &&
                  details?.assets?.map((el) => {
                    if (el?.generic_data1 && el?.generic_data1?.length > 0) {
                      return (
                        <div className="summary">
                          <p className="text-white mb-3">
                            <div>
                              <b>
                                {el?.ip} | {el?.port}
                              </b>
                            </div>
                            <hr />
                            {el?.generic_data1?.map((genericData) => {
                              return (
                                <div>
                                  Username:{" "}
                                  <b style={{ color: "#bcf4de" }}>
                                    {genericData?.login}
                                  </b>{" "}
                                  Password:{" "}
                                  <b style={{ color: "#bcf4de" }}>
                                    {genericData?.password}
                                  </b>
                                </div>
                              );
                            })}
                          </p>
                        </div>
                      );
                    }
                  })}
                {hasGenericData && issuesFor == "certificates" && (
                  <div className="summary">
                    <p className="text-white mb-3 generic-text-link">
                      {[...new Set(certificatesSerial)].map((el, index) => {
                        return (
                          <div>
                            {el}
                            {index !==
                              [...new Set(certificatesSerial)].length - 1 && (
                                <hr />
                              )}
                          </div>
                        );
                      })}
                    </p>
                  </div>
                )}
              </div>
            )}

            {/* Related Assets | 8 */}

            <div className="popup-dropdown " onClick={toggleRelatedAssets}>
              <ArrowDown
                height={16}
                width={16}
                className={`base-drop-down-arrow ${relatedAssetsOpen ? "open" : ""
                  }`}
              />
              <h3 className="text-white">
                Related Assets 
              </h3>
            </div>
            {relatedAssetsOpen &&
              (issuesFor == "login" ? (
                <div className="summary">
                  <p className="text-white mb-3 generic-text-link">
                    {details?.assets?.map((el, index) => {
                      if (el?.generic_data1?.pages?.length > 0) {
                        return (
                          <div>
                            <a
                              href={el?.generic_data1?.pages[0]}
                              target="_blank"
                            >
                              {el?.generic_data1?.pages[0]}
                            </a>
                            {index !== details?.assets?.length - 1 && <hr />}
                          </div>
                        );
                      }
                    })}
                  </p>
                </div>
              ) : (
                <div className="ip-port">
                  {details ? (
                      <div>
                        <div
                          className="  list-group-item d-flex justify-content-between align-items-center"
                        >
                          <div className="flex-fill text-white text">
                            {renderRelatedAssets(isGroup? asset : details)}
                          </div>
                        </div>
                      </div>
                  ) : (
                    <div className="text-white text-center mt-4">-</div>
                  )}
                </div>
              ))}

            {/* Risk Calculation */}
            <div className="popup-dropdown " onClick={toggleRiskCalculation}>
              <ArrowDown
                height={16}
                width={16}
                className={`base-drop-down-arrow ${riskCalculationOpen ? "open" : ""
                  }`}
              />
              <h3 className="text-white">Risk Calculation</h3>
            </div>
            {riskCalculationOpen && (
              <div className="d-flex align-items-center risk ">
                <div
                  className="d-flex flex-column gap-3 risk-first-div"
                  style={{ backgroundImage: `url(${CveArrow})` }}
                >
                  <div className="d-flex gap-3">
                    <div className="d-flex align-items-center gap-3">
                      <div>Impact:</div>
                      <div>{details?.impact}%</div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <div>Exploitability:</div>
                      <div>{details?.exploitability}%</div>
                    </div>
                  </div>
                  <div className="d-flex  align-items-center gap-3">
                    <div className="d-flex  align-items-center gap-3">
                      <div>CVSS: </div>
                      <div>{details?.cvss}</div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <div>EPSS: </div>
                      <div>{details?.epss}</div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <div>CISA KEV: </div>
                      <div
                        className="popup-cisa"
                        style={{
                          border:
                            details?.cisa_kev === "yes"
                              ? "1px solid #FF6155"
                              : details?.cisa_kev === "no"
                                ? "1px solid #fff"
                                : "none",
                          color:
                            details?.cisa_kev === "yes"
                              ? "#FF6155"
                              : details?.cisa_kev === "no"
                                ? "#fff"
                                : "#fff",
                        }}
                      >
                        {details?.cisa_kev.charAt(0).toUpperCase() +
                          details?.cisa_kev.slice(1)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between risk-second-div score gap-2">
                  <p> Risk Score :</p>{" "}
                  <span className="percentage" style={{ color: color }}>
                    {details?.risk_score}%
                  </span>
                </div>
              </div>
            )}

            {/* Remediation */}
            <div className="popup-dropdown " onClick={toggleRemediation}>
              <ArrowDown
                height={16}
                width={16}
                className={`base-drop-down-arrow ${remediationOpen ? "open" : ""
                  }`}
              />
              <h3 className="text-white">Remediation</h3>
            </div>

            {remediationOpen && (
              <div style={{ whiteSpace: "pre-line" }}>
                {details?.remediation ? (
                  details?.remediation
                    ?.replace(
                      "Please see the references for more resources supporting you with this task.",
                      ""
                    )
                    .replace(
                      "Please see the references for more information.",
                      ""
                    )
                    .replace(/^\s+|\s+$/g, "")
                    .split("|")
                    ?.filter(function (i) {
                      return i;
                    })
                    .map((item, index) => (
                      <div key={index} className="summary mb-3">
                        <p className="d-flex gap-3 text-white mt-3">
                          <Bulb height={25} width={40} />
                          <div>{item}</div>
                        </p>
                      </div>
                    ))
                ) : (
                  <div className="text-white text-center mt-4">
                    No Remediation found.
                  </div>
                )}
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default CvesPopup;
