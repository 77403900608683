import React, { useCallback, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Stack } from "react-bootstrap";

import axios from "../../util/axios";
import InfiniteScroll from "react-infinite-scroller";
import AlertSingle from "./AlertSingle";
import { Triangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import ConfirmationModalContextProvider from "../../components/ModalConfirmationContext";

async function loadItems(page, pageSize) {
	try {
		let res = await axios.get('alerts', { params: { page: page, pageSize: pageSize } });
		console.log('data', res.data);
		return res.data;

	} catch (error) {
		console.log(error);
		throw error;
	}

}

const Alerts = () => {
	const [items, setItems] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState("");
	const pageSize = 10;
	const [fetching, setFetching] = useState(false);
	const navigate = useNavigate();

	const fetchItems = useCallback(
		async () => {
			if (fetching) {
				return;
			}

			setFetching(true);

			try {
				const data = await loadItems(page, pageSize);

				setItems([...items, ...data.items]);
				setPage(data.nextPage)
				setTotal(data.total)


			} catch (error) {
				setPage(false);
				console.log('my error');

			} finally {
				setFetching(false);
			}
		},
		[items, fetching, page, pageSize]
	);
	const onRemoveItem = (id) => {
		setItems(items.filter(function (item) {
			return item.id !== id
		}));

	}

	const hasMoreItems = !!page;

	const loader = (
		<div key="loader" className="loader">
			<Triangle
				height="80"
				width="80"
				color="#fff"
				ariaLabel="triangle-loading"
				wrapperStyle={{ justifyContent: 'center' }}
				wrapperClassName=""
			/>
		</div>
	);

	return (
		<React.Fragment>

			<h1 className="fw-normal mb-5">
				<Button variant="secondary" onClick={() => navigate('/leaks')} className="px-2 rounded-1 me-3"><i className="bi bi-chevron-left text-white fs-4"></i></Button> Alerts
			</h1>
			<Stack gap={2}>
				<ConfirmationModalContextProvider>
					<InfiniteScroll
						loadMore={fetchItems}
						hasMore={hasMoreItems}
						loader={loader}
						className="container-fluids "
					>

						{items.map(item => (
							<AlertSingle key={item.id} item={item} onRemoveItem={onRemoveItem} />
						))}
						{items.length <= 0 && !fetching ? <div className="pt-3">No record found!</div> : ""}

					</InfiniteScroll>
				</ConfirmationModalContextProvider>
			</Stack>


		</React.Fragment >
	);
};

export default Alerts;
