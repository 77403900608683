import React from 'react';
import { ReactComponent as Left } from "../../assets/images/chevron-left.svg";
import { ReactComponent as Right} from "../../assets/images/chevron-right.svg";
import { ReactComponent as Option} from "../../assets/images/option.svg";

const NotificationBar = ({ message , num }) => {
  return (
    <div className="notification-bar">
        <p>Ai news {num}</p>
      <li className="notification-message"><span className='dot'>&#183;</span>{message} </li>
            <Left width={25}/>
            <Right width={25}/>
            <Option width={35}/>

    </div>
  );
};

export default NotificationBar;
