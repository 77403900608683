import React, { useState } from "react";
import { ReactComponent as RightChevron } from "../../assets/images/chevron-right.svg";
import { Button } from "react-bootstrap";
import what_new_image from "../../assets/images/whats-new-image.png";

export const UpdateList = () => {
  const [collapse, setCollapse] = useState(true);

  return (
    <div style={{ border: "1px solid #ffffff", borderRadius: "4px" }}>
      <div
        className="update-list"
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        <div className="flex gap-2 items-center">
          <RightChevron />
          <div>
            <span style={{ fontSize: "12px" }}>March 20,2024</span>
            <div className="flex items-center gap-3">
              <h4 className="m-0">
                Get Better Results For Loght Subdomain Finder Scan
              </h4>
              <div className="divider"></div>
              <Button
                className="new-badge"
                variant="outline-primary"
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "transparent";
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "transparent";
                }}
                onFocus={(e) => {
                  e.target.style.backgroundColor = "transparent";
                }}
              >
                New
              </Button>
            </div>
          </div>
        </div>
        <h4 className="text-link m-0">Visit Screen</h4>
      </div>
      {!collapse && (
        <div className="new-details">
          <p>
            We upgraded the reporting limit for light scans by 10x! <br />
            The subdomain finder now provides up to 1000 entries and includes
            unrelieved results so older subdomains are available
          </p>
          <p>
            We upgraded the reporting limit for light scans by 10x! <br />
            The subdomain finder now provides up to 1000 entries and includes
            unrelieved results so older subdomains are available
          </p>
          <img src={what_new_image} alt="" style={{ width: "100%" }} />
        </div>
      )}
    </div>
  );
};
