
import { Row, Col, Image } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form';
import mix from '../../assets/images/min.png';
import max from '../../assets/images/max.png';
import expend from '../../assets/images/expend.png';
import 'react-tree-graph/dist/style.css'
const DomainTreeFilter = ({handleTransform,zoomIn ,zoomOut}) => {  



  return (
  <>
  
     
          <div>
            <div className='d-flex '>
              <div className='d-flex flex-column ms-2'>
                <div className='border border-1 border-white p-1 d-flex align-items-center justify-content-center cursor-pointer' onClick={handleTransform}><Image src={expend} /></div>
                <div className='my-2 border border-1 border-white p-1 d-flex align-items-center justify-content-center cursor-pointer' onClick={zoomIn}><Image src={max} /></div>
                <div className='border border-1 border-white p-1 d-flex align-items-center justify-content-center cursor-pointer' onClick={zoomOut}><Image src={mix} /></div>
              </div>
            </div>
          </div>
     



    
  </>

  )
}

export default DomainTreeFilter

