import React, { useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
// import Breadcrumb from "../../components/breadcrumbs/BreadCrumb";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { ReactComponent as WhatsNewIcon } from "../../assets/images/whats-new-icon.svg";
import { ReactComponent as Stars } from "../../assets/images/icon-stars.svg";
import moment from "moment";
import { UpdateList } from "./UpdateList";

export const WhatsNew = () => {
  const breadcrumbItems = ["What's New?"];
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [scanStatus, setScanStatus] = useState(3);

  const formatDate = (date) => {
    return moment(date).isValid()
      ? moment(date).format("MMM D, YYYY HH:mm")
      : "Invalid date";
  };

  return (
    <React.Fragment>
      {isLoading && (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      )}
      <div
        style={{ display: isLoading ? "none" : "block", marginBottom: "20px" }}
      >
        <div>
          <div className="fontfam text-white breadcrumb-main">
            <div className="flex gap-2 items-center">
              <WhatsNewIcon className="pt-1" />

              <Breadcrumb>
                <Breadcrumb.Item
                  className="breadcrumb-list"
                  as="div"
                  href="#"
                  active
                >
                  What's New?
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {/* <div className="fs-5 fw-bold">{title}</div> */}
            <div>
              <span className="fw-bold">Last Updated</span> |{" "}
              {formatDate(lastUpdated)}
            </div>
          </div>
          <div className="horizontal-rule"></div>
        </div>

        <div className="py-3 px-3"></div>
        <>
          <div className="text-center whats-new-background">
            <Stars
              className="h-8 w-8"
              style={{ height: "25px", width: "25px" }}
            />
            <h3 className="mt-3">All The Latest Armory Updates</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem
              eligendi commodi eaque architecto, id culpa sint natus deleniti in
              esse officia asperiores optio molestias porro cupiditate dolores
              eveniet non debitis.
            </p>
          </div>
          <div className="mt-5 mx-3">
            <UpdateList />
          </div>
          {/* <div className="animation-section">
              <div className="video-container">
                <video
                  ref={videoRef}
                  src={LogoAnim}
                  width="400px"
                  height="400px"
                  loop
                  muted
                />
              </div>
              <div className="logs-output">
                <LogsOutput data={scanningData} isType={false} />
              </div>
              {!isLoading && <AssetsScanStats stats={stats} />}
            </div> */}
          {/* <div className="mb-3">
              <WebScanner
                title="Asset Inventory Scanner"
                data={scanningData}
                isType={false}
              />
            </div> */}
          {/* {scanStatus > -2 ? (
            <ScanStats stats={stats} totalActive={totalActive} totalActiveHost={totalActiveHost}/> ):''
            } */}

          {/* <DomainList
              setScanStatus={servicesStatusCheck}
              webScanner={handelWebScanner}
              onStatsFetch={handleStatsFetch}
              setIsScanStarted={setIsScanStarted}
              setIsLoading={setIsLoading}
              setTotalActive={setTotalActive}
              setTotalActiveHost={setTotalActiveHost}
            /> */}
        </>
      </div>
    </React.Fragment>
  );
};
