import React, { useState, useEffect } from "react";
import wide from "../../assets/images/wide.png";
import upred from "../../assets/images/upred.png";
import downred from "../../assets/images/downred.png";
import dataloader from "../../assets/images/dataloader.png"


const DataLoader = () => {
  return (
    <div className="offensivetesting_dataLoader">
        <img src={dataloader}/>
    </div>
  )
}

const OffensiveTestingCard = () => {

    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        // Hide loader after 5 seconds
        const timer = setTimeout(() => setLoading(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    const cardsData = [
        {
            value1: 40,
            image1: upred,
            text1: "that SUCCEDED",
            value2: 36,
            image2: downred,
            text2: "that FAILED"
        },
    ];

    return (
        <React.Fragment>
            {cardsData.map((card, index) => (
                <div className='card-2 font' key={index}>
                    <div className='card_header'>
                        <div className='card_title'>Offensive Testing</div>
                        <img src={wide} alt='' />
                    </div>
                    {loading ? <DataLoader /> : (
                    <div className='card_content_main'>

                        <div className='card_content_style'>
                            <div className='content_values_style' style={{ color: card.value1 > card.value2 ? '#FF6155' : '#3DDC97' }}>
                                <div className='content_value'>{card.value1}</div>
                                <img src={card.image1} alt='' />
                            </div>
                            <div className='content_text_style'>
                                <div>Offensive Testing</div>
                                <div>{card.text1}</div>
                            </div>
                        </div>
                        <div className='card_content_style'>
                            <div className='content_values_style' style={{ color: card.value2 > card.value1 ? '#FF6155' : '#3DDC97' }}>
                                <div className='content_value'>{card.value2}</div>
                                <img src={card.image2} alt='' />
                            </div>
                            <div className='content_text_style'>
                                <div>Offensive Testing</div>
                                <div>{card.text2}</div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            ))}
        </React.Fragment>
    );
}

export default OffensiveTestingCard;
