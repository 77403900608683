import React from "react";
import { Outlet } from "react-router-dom";
import AuthFooter from "./footer/AuthFooter";
import AuthNavbar from "./navbar/AuthNavbar";
import { Card, Stack } from "react-bootstrap";
import SplashScreenWrapper from "../components/SplashScreenWrapper";

const Auth = () => {
    return (
        <React.Fragment>
            {/* <AuthNavbar /> */}
            <div className="d-flex flex-column align-items-center justify-content-center frame auth rounded w-100 h-100">
                <Outlet />
            </div>
            <AuthFooter />
        </React.Fragment>
    );
}

export default SplashScreenWrapper(Auth);