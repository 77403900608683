import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Nav, Row } from 'react-bootstrap';

import UrlForm from './UrlForm';
import Urls from './Urls';

const Index = () => {

	return (<React.Fragment>
		<div className="main_container_style" >
			<Row className="justify-content-start gap-3">
				<Col>
					<UrlForm />
				</Col>
			</Row>
			<Row className="justify-content-start py-4">
				<Col >
					<Urls />
				</Col>

			</Row>
		</div>

	</React.Fragment >
	)
}

export default Index;