import React, { createContext, useCallback, useState } from 'react';

export const ServicesContext = createContext();

export const ServicesProvider = ({ children }) => {
    const [scannerServices, setScannerServices] = useState([]);

    const fetchAllServicesItems = useCallback((allServices) => {
        setScannerServices(allServices)
    }, []);

    return (
        <ServicesContext.Provider value={{ fetchAllServicesItems, scannerServices }}>
            {children}
        </ServicesContext.Provider>
    );
};