import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import WebUpdate from "../../../../components/website-updates/WebUpdate";
import GenericCard from "../../../../components/card/GenericCard";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning";
import NoData from "../../../../components/empty/NoData";
import BarChart from "../../../../components/charts/BarChart";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import { useParams, useOutletContext } from "react-router-dom";
import { ScannerContext } from "../../../../components/ScannerContext";
import { ThreeCircles } from "react-loader-spinner";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import moment from "moment";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import HorizontalBarChart from "../../../../components/charts/HorizontalBarChart";
import DecisionToggle from "../../../../components/badge/DecisionToggle";

const LoginPagesSecurity = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [horizontalBarChartType, setHorizontalBarChartType] = useState([]);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [noDataDoughnutChart, setNoDataDoughtnutChart] = useState(false);
  const [noDataBarChart, setNoDataBarChart] = useState(false);
  const [barChartType, setBarChartType] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const routeParams = useParams();
  const [tabs, setTabs] = useState([
    {
      eventKey: "1",
      title: "Hostname",
      contentTitle: "Hostname",
      data: [],
    },
    {
      eventKey: "2",
      title: "Login Page",
      contentTitle: "Login Page",
      data: [],
    },
    {
      eventKey: "3",
      title: "Login Over HTTPS",
      contentTitle: "Login Over HTTPS",
      data: [],
    },
    {
      eventKey: "4",
      title: "	Login Over HTTP",
      contentTitle: "Login Over HTTP",
      data: [],
    },
  ]);
  const [barChartData, setBarChartData] = useState({
    labels: ["Secured", "Unsecured"],
    datasets: [
      {
        label: "",
        data: [4, 6],
        backgroundColor: ["#3DDC97", "#FF6155"],
        borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderWidth: 1,
      },
    ],
  });

  const sampleData = [
    { id: 1, type: "upload" },
    { id: 2, type: "upload" },
    { id: 3, type: "removal" },
    { id: 4, type: "removal" },
    { id: 5, type: "upload" },
  ];

  const exampleData = [
    {
      host: "Hostname 1",
      login_page: "wwww.example.com",
      https_pages: 1,
      http_pages: 1,
    },
    {
      host: "Hostname 2",
      login_page: "wwww.example2.com",
      https_pages: 0,
      http_pages: 1,
    },
    {
      host: "Hostname 3",
      login_page: "wwww.example3.com",
      https_pages: 1,
      http_pages: 0,
    },
    {
      host: "Hostname 4",
      login_page: "wwww.example4.com",
      https_pages: 0,
      http_pages: 0,
    },
  ];

  const columns = [
    { Header: "Hostname", accessor: "host", isSortable: true },
    {
      Header: "Login Page",
      accessor: "login_page",
      isSortable: true,
      key: "login_page",
    },
    {
      Header: "Login Over HTTPS",
      accessor: "https_pages",
      isSortable: true,
      accessor: (row) => (
        <div>
          <DecisionToggle
            label={!row?.https_pages ? "Yes" : "No"}
            isIndication={!row?.https_pages ? true : false}
          />
        </div>
      ),
    },
    {
      Header: "Login Over HTTP",
      accessor: "http_pages",
      isSortable: true,
      accessor: (row) => (
        <div>
          <DecisionToggle
            label={!row?.http_pages ? "Yes" : "No"}
            isIndication={!row?.http_pages ? true : false}
          />
        </div>
      ),
    },
    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => (
        <div>{moment("02/01/24", "DD/MM/YY").format("DD/MM/YY")}</div>
      ),
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => <CurrentStatus status={"New"} />,
    },
  ];
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "white",
          padding: 30,
          fillStyle: "none",
          usePointStyle: true,
          pointStyle: "rectRounded",
          boxSize: 0,
          boxWidth: 0,
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        padding: 20,
        yPadding: 90,
        callbacks: {
          title: () => {
            return "";
          },
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        yAlign: "bottom",
        xAlign: "center",
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "top",
        formatter: (value, context) => {
          return value;
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
          precision: 0,
        },
        border: { dash: [4, 4], borderColor: "black" },
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          borderDash: [10, 10],
        },
      },
      x: {
        ticks: {
          color: "white",
        },
        display: true,
        grid: {
          color: "transparent",
          drawOnChartArea: true,
          borderDash: [10, 10],
          borderDashOffset: function (context) {
            return context.index === context.chart.data.labels.length ? 0 : 10;
          },
        },
        border: {
          color: "#959595",
          width: 1,
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        bottom: -40,
      },
    },
  };

  const horizontalBarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          color: "white",
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        padding: 20,
        yPadding: 90,
        callbacks: {
          title: () => "",
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },

        yAlign: "bottom",
        xAlign: "center",
      },
      title: {
        display: false,
        text: "Most Used Ports",
        color: "white",
        font: {
          size: 24,
        },
      },
      datalabels: {
        anchor: "end",
        align: "end",
        formatter: Math.round,
        font: {
          weight: "bold",
          size: 12,
        },
        color: "white",
        padding: {
          right: 10,
        },
        clip: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
          font: {
            family: "Kodchasan",
            weight: 400,
            size: 10,
          },
        },
        border: { dash: [4, 4] },

        grid: {
          color: "rgba(255, 255, 255, 0)",
        },
      },
      x: {
        ticks: {
          precision: 0,
          color: "white",
        },
        border: { dash: [4, 4] },

        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
        beginAtZero: true,
      },
    },
    layout: {
      padding: {
        right: 20,
      },
    },
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleRowSelect = (selectedRows) => {};

  const horizontalBarChartData = useMemo(
    () => ({
      labels: [
        "Login Over HTTP",
        "Login Over Both HTTP and HTTPS",
        "Login Over HTTPS",
      ],
      datasets: [
        {
          label: "Certificate",
          data: [3, 4, 5],
          backgroundColor: ["#FF6155", "#FF6155", "#3DDC97"],
          hoverOffset: 4,
          borderColor: "transparent",
          borderWidth: 0,
          width: 50,
          borderAlign: "center",
        },
      ],
    }),
    [horizontalBarChartType]
  );

  return (
    <>
      {!isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row waf-main web-updates-container">
            <div className="col-12  col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 waf-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      noDataDoughnutChart ? (
                        <NoDataAfterScanning />
                      ) : isDatainProcess ? (
                        <NoData />
                      ) : (
                        <HorizontalBarChart
                          HorizontalBarChartdata={horizontalBarChartData}
                          HorizontalBarChartoptions={horizontalBarChartOptions}
                          isChartLabelsOnTop={true}
                        />
                      )
                    }
                    title={"HTTP vs HTTPS Login Pages"}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={<WebUpdate data={sampleData} />}
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7 h-100">
                      <GenericCard
                        children={
                          noDataBarChart ? (
                            <NoDataAfterScanning />
                          ) : isDatainProcess ? (
                            <NoData />
                          ) : (
                            <BarChart
                              isChartLabelsOnTop
                              barData={barChartData}
                              optionsData={barChartOptions}
                              isData={
                                !!(
                                  barChartType &&
                                  barChartType.no !== undefined &&
                                  barChartType.yes !== undefined
                                )
                              }
                            />
                          )
                        }
                        title={"Login Pages Security"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Login Pages Security Updates"} />
            </div>
          </div>

          <div className=" pb-5">
            <div>
              <BaseFilter
                tabs={tabs}
                onFilterChangeWaf={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                iskeyfilter={true}
                className="mb-3"
                totalRecords={exampleData.length}
                exportTitle={`LoginPagesSecurity_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={exampleData}
                selectable={true}
                onRowSelect={handleRowSelect}
                showCheckboxes={false}
                action={false}
                isDatainProcess={isDatainProcess}
                loading={!isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPagesSecurity;
