import React from 'react';
import { Button, ButtonGroup, Col, Container, Dropdown, DropdownButton, Image, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { decodeHTML } from 'entities';
import { Link } from 'react-router-dom';
import axios from "../../util/axios";
import { ReactComponent as NewspaperIcon } from "../../assets/images/nav-icon-newspaper.svg";
import { toast } from 'react-toastify';
const ExportNews = ({ item }) => {
    const handleExport = (duration) => {
        console.log('export news', duration);
        let pdfGenerator = axios.get("news/export", {
            params: { duration: duration },
        });
        toast.promise(
            pdfGenerator,
            {
                pending: {
                    theme: 'dark',
                    render() {
                        return "Generating PDF..."
                    },
                    icon: false,
                },
                success: {
                    autoClose:false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: 'dark',
                    render({ data }) {
                        return <React.Fragment>
                            News Export Completed. <br /> 
                            <a className='mt-3 btn btn-primary btn-text' href={data?.data?.url} target='_blank'>Download</a>

                        </React.Fragment>
                    },
                    // other options
                    icon: NewspaperIcon,
                },
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return "Something went wrong, please try again!"
                    }
                }
            }
        )



    };
    return (
        <React.Fragment>
            <Navbar  className='mb-3' bg="primarys">
                <Container fluid>
                    <DropdownButton id="dropdown-basic-button" title="Export">
                        <Dropdown.Item onClick={() => { handleExport('today') }}>Today's news</Dropdown.Item>
                        <Dropdown.Item onClick={() => { handleExport('week') }}>Latest week</Dropdown.Item>
                        <Dropdown.Item onClick={() => { handleExport('month') }}>Latest month</Dropdown.Item>
                    </DropdownButton>


                </Container>
            </Navbar>

        </React.Fragment>
    )
}

export default ExportNews;