import React, { useState } from "react";
import BaseTable from '../../../../components/table/BaseTable';
import BaseFilter from '../../../../components/filter/BaseFilter';
import BaseInput from '../../../../components/form/BaseInput';
import WebScanner from '../../../../components/web-scanner/WebScanner';
import WebUpdate from '../../../../components/website-updates/WebUpdate';
import { ReactComponent as Search } from "../../../../assets/images/search.svg";
import CircularChart from "../../../../components/charts/CircularChart";
import GenericCard from "../../../../components/card/GenericCard"
import { BarChart } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import axios from "../../../../util/axios";
import { ThreeCircles } from 'react-loader-spinner'

const HeadersAndCookies = () => {
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);
    const [cms, setCms] = useState([]);
    const routeParams = useParams();

    const data = [{
        id: 1,
        server: 'https://a.a.com',
        CSP: "✓",
        CORS: "✓",
        HSTS: "✓",
        RD: "✗",
        XCO: "✗",
        XFO: "✗",
    },
    {
        id: 2,
        server: 'https://b.a.com',
        CSP: "✗",
        CORS: "✓",
        HSTS: "✗",
        RD: "✓",
        XCO: "✓",
        XFO: "✓",

    },
    ];

    const CircularChartdata = {
        labels: ['Websites with Secure Headers', 'Websites with Unsecure Headers'],
        datasets: [
            {
                label: 'Headers',
                data: [2, 5],
                backgroundColor: ['#ff6155', '#2AA16E'],
                hoverOffset: 4,
                borderColor: 'transparent',
                borderWidth: 0,
                borderAlign: 'center'
            }
        ]
    };

    const CircularChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '80%',
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    padding: 10,
                    color: '#F5F5F5',
                }
            },
            title: {
                display: false,
                text: 'Headers Security',
                color: 'white',
                font: {
                    family: 'Kodchasan',
                    size: 24,
                }
            }
        }
    };

    const columns = [
        { Header: 'Web Server', accessor: 'server', isSortable: false },
        { Header: 'Content Security Policy', accessor: 'CSP' },
        { Header: 'cross-origin resource sharing (CORS)', accessor: 'CORS' },
        { Header: 'HTTP Strict Transport Security (HSTS)', accessor: 'HSTS' },
        { Header: 'Redirection from HTTP to HTTPS', accessor: 'RD' },
        { Header: 'X-Content-TypeOptions', accessor: 'XCO' },
        { Header: 'X-Frame-Options (XFO)', accessor: 'XFO' },
    ];

    const filteredCms = cms.filter(item =>
        item.cms?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.host?.includes(searchValue)
    );

    const sampleData = [
        { id: 1, type: 'upload' },
        { id: 2, type: 'upload' },
        { id: 3, type: 'removal' },
        { id: 4, type: 'removal' },
        { id: 5, type: 'upload' },
    ];

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleRowSelect = (selectedRows) => {

    };

    const handleAction = (rowData) => {

    };



    return (
        <React.Fragment>
            {
                isLoading ? <div className="content-loader">
                    <ThreeCircles
                        visible={true}
                        height="60"
                        width="60"
                        color="#ffff"
                        ariaLabel="three-circles-loading"
                        wrapperClass=""
                    />
                </div>
                    :
                    <div className="main_container_style">
                        <div className="pb-4 row top-container-main web-updates-container" >
                            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
                                <div className="row gap-4 top-container-main-row" >
                                    <div className="col-12 h-50" >
                                        <GenericCard children={<CircularChart CircularChartdata={CircularChartdata} CircularChartoptions={CircularChartoptions} />} title={'Headers Security'} isCentered={false} />
                                    </div>
                                    <div className="col-12 h-50" >
                                        <div className="row h-100" >
                                            <div className="col-12">
                                                <GenericCard children={<WebUpdate data={sampleData} />} title={'Web Servers Updates'}
                                                    subtitle={"View Issues"}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
                                <WebScanner title={'Headers And Cookies Scanner'} />
                            </div>
                        </div>

                        <div className="pb-5 pt-3">
                            <div className='table-container' >
                                <div className='table-left'>
                                    Results
                                </div>
                                <div >
                                    <BaseInput
                                        type="text"
                                        placeholder="Search..."
                                        className="search-input"
                                        icon={Search}
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <div >
                                <BaseFilter totalRecords={filteredCms.length} exportTitle={'CMS_TARGET_' + routeParams?.target_id} exportHeader={["Web Servers", "Indication of CMS", "CMS Name", "CMS Version"]} exportRows={filteredCms.map(cms => [cms.host, cms.has_cms, cms.cms, cms.cms_version])} />

                            </div>
                            <div>
                                <BaseTable className="mt-3 mb-3"
                                    columns={columns}
                                    data={filteredCms}
                                    selectable={true}
                                    onRowSelect={handleRowSelect}
                                    showCheckboxes={false}
                                    action={false}
                                    loading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
            }
        </React.Fragment>
    )
}

export default HeadersAndCookies;