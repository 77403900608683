import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./axios";

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    axios.get("ping").then((res) => {
      console.log('user', res.data)
      if (res.data?.data) {
        localStorage.setItem("user", JSON.stringify(res.data.data));
      }
    });
    const userToken = localStorage.getItem("user-token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      // return navigate("/auth/login");
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  });

  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};

export default ProtectedRoute;
