import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const RiskScoreChart = ({ dataPoints }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = chartRef.current;

        if (chart) {
            const ctx = chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
            gradient.addColorStop(0, 'rgba(57, 143, 254, 0.2)');
            gradient.addColorStop(1, 'rgba(57, 143, 254, 0.05)');

            chart.data.datasets[0].backgroundColor = gradient;
            chart.update();
        }
    }, [dataPoints]); // Dependency array ensures this effect is only run when dataPoints change

    const data = {
        labels: dataPoints.map((_, index) => `Point ${index + 1}`),
        datasets: [
            {
                label: 'Risk Score',
                data: dataPoints,
                fill: true,
                borderColor: '#398FFE',
                borderWidth: 0.5, // Adjust the line thickness here
                pointBackgroundColor: '#398FFE',
                tension: 0.1,
                // backgroundColor will be set in the useEffect hook
            },
        ],
    };

    const options = {
        scales: {
          x: {
            grid: {
              drawBorder: false, // Do not draw the border line at the edges of the X-axis
              display: false, // Hide X-axis grid lines inside the chart area
              drawTicks: false, // Do not draw tick marks on the X-axis
            },
            ticks: {
              display: false, // Hide X-axis tick labels
            },
          },
          y: {
            grid: {
              drawBorder: true, // Ensure the border line for the Y-axis is drawn
              borderColor: 'rgba(255, 255, 255, 1)', // Set the color for the Y-axis border line (visible as the opposite axis line at x=0)
              borderWidth: 2, // Specify the width of the border line for the Y-axis
              display: true, // Show Y-axis grid lines
              color: '#818181', // Make Y-axis grid lines inside the chart area invisible
              drawTicks: false, // Do not draw tick marks on the Y-axis
            },
            ticks: {
              display: false, // Optionally, set to true if you want to display Y-axis labels
              color: 'rgba(255, 255, 255, 1)', // Set color for Y-axis labels, if displayed
            },
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            display: false, // Hide the legend
          },
        },
        maintainAspectRatio: false,
        responsive: true,
      };
      

    return (
        <div className="risk-score-chart" >
            <Line ref={chartRef} data={data} options={options} />
        </div>
    );
};

export default RiskScoreChart;
