import React, { useMemo } from 'react';
import SecurityIssueCard from '../../components/securityissue-card/SecurityIssueCard';
import GroupIssuesTitle from './GroupIssuesTitle';

const CveList = ({ isChecked, filteredCves, collapsedGroups, toggleCollapse }) => {
  const renderItems = useMemo(() => {
    return filteredCves.map((item) => {
      const { id, risk_score } = item;
      const cvssRisk =
        risk_score >= 80 && risk_score <= 100
          ? "primary"
          : risk_score >= 40 && risk_score <= 79
          ? "secondary"
          : "tertiary";

      if (isChecked) {
        return (
          <GroupIssuesTitle
            key={id}
            group={item}
            collapsedGroups={collapsedGroups}
            toggleCollapse={toggleCollapse}
            cvssRisk={cvssRisk}
            cvssScore={risk_score}
          />
        );
      } else {
        return (
          <SecurityIssueCard
            key={id}
            classNameVariant={cvssRisk}
            RiskPercentage={risk_score}
            data={item}
          />
        );
      }
    });
  }, [isChecked, filteredCves, collapsedGroups, toggleCollapse]);

  return <>{renderItems}</>;
};

export default CveList;
