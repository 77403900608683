import React, { createContext, useCallback, useState } from "react";
import axios from "../util/axios";

export const ScannerContext = createContext();

export const ScannerProvider = ({ children }) => {
  const [dropdownItems, setDropdownItems] = useState([]);

  const fetchTargets = useCallback(async () => {
    try {
      const { data } = await axios.get(`targets?page=1&pageSize=1000`);
      setDropdownItems(data?.items);
    } catch (err) {}
  }, []);

  return (
    <ScannerContext.Provider value={{ fetchTargets, dropdownItems }}>
      {children}
    </ScannerContext.Provider>
  );
};
