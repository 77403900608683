import React, { useState } from "react";
import { Triangle, ThreeCircles } from "react-loader-spinner";
import { Image, Table, Button, FormControl } from "react-bootstrap";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash.svg";
import axios from "../../../util/axios";

const Organizations = ({ items, setItems }) => {
  const [deletingId, setDeletingId] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [newIpLimit, setNewIpLimit] = useState("");

  const deleteOrganization = async (id) => {
    setDeletingId(id);
    try {
      await axios.delete(`/organizations?id=${id}`);
      setItems((currentItems) => currentItems.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Failed to delete organization", error);
    } finally {
      setDeletingId(null);
    }
  };

  const editIpLimit = (id, currentLimit) => {
    setEditingId(id);
    setNewIpLimit(currentLimit);
  };

  const saveIpLimit = async (id) => {
    try {
      await axios.post(`/organizations/update`, {
        id,
        ip_limit: parseInt(newIpLimit),
      });
      setItems((currentItems) =>
        currentItems.map((item) =>
          item.id === id ? { ...item, ip_limit: newIpLimit } : item
        )
      );
    } catch (error) {
      console.error("Failed to update IP limit", error);
    } finally {
      setEditingId(null);
      setNewIpLimit("");
    }
  };

  return (
    <React.Fragment>
    
      <Table variant="dark" striped bordered hover responsive>
        <thead>
          <tr>
            <th>Id</th>
            <th>Organization Name</th>
            <th>Users Count</th>
            <th>IP Limit</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item,index) => (
            <tr key={`${item.id}-${index}`}>
              <td>{item.id}</td>
              <td>{item.org_name}</td>
              <td>{item?.users_count || "N/A"}</td>
              <td>
                {editingId === item.id ? (
                  <FormControl
                    type="number"
                    value={newIpLimit}
                    onChange={(e) => setNewIpLimit(e.target.value)}
                  />
                ) : (
                  item?.ip_limit || 0
                )}
              </td>
              <td>
                {editingId === item.id ? (
                  <Button
                    variant="success"
                    onClick={() => saveIpLimit(item.id)}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => editIpLimit(item.id, item.ip_limit)}
                  >
                    Edit
                  </Button>
                )}
                {deletingId === item.id ? (
                  <div key="loader" className="loader">
                    <ThreeCircles
                      visible={true}
                      height="30"
                      width="30"
                      color="#017df9"
                      ariaLabel="three-circles-loading"
                      wrapperClass=""
                    />
                  </div>
                ) : (
                  <DeleteIcon
                    className="cursor-pointer"
                    onClick={() => deleteOrganization(item.id)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default Organizations;
