import React, { Component } from "react";
import SplashSvg from '../assets/images/splash.svg';
function SplashMessage() {
  return (
    <div className="text-center d-block d-flex align-items-center justify-content-center  vw-100 vh-100">
      <img
        src={SplashSvg}
        className="App-logo center-block img-fluid"
        alt="splash screen"
      />
    </div>
  );
}

export default function SplashScreenWrapper(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true, //we ll make it true once completed
      };
    }

    async componentDidMount() {
      try {
        // Put here your await requests/ API requests
        setTimeout(() => {
            this.setState({
                loading: false,
            });
        }, 3500)
      } catch (err) {
        console.log(err);
        this.setState({
          loading: false,
        });
      }
    }

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading) return SplashMessage();

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}
