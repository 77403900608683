import React, { useLayoutEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const IssueStatusChart = ({ data, options,onIssueClick }) => {
    const [maxLabelWidth, setMaxLabelWidth] = useState(0);
    const labelRefs = useRef([]);
    const chartRef = useRef(null);

    useLayoutEffect(() => {
        const calculateMaxLabelWidth = () => {
            const widths = labelRefs.current.map(ref => ref?.offsetWidth || 0);
            setMaxLabelWidth(Math.max(...widths));
        };
        calculateMaxLabelWidth();
    }, [labelRefs.current, data.labels]);
    
    const handleClick = (event) => {
        const chartInstance = chartRef.current; // Access the chart instance using the ref
        const activePoints = chartInstance.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

        if (activePoints.length > 0) {
            const firstPoint = activePoints[0];
            const label = data.labels[firstPoint.index];
            const value = data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
            onIssueClick(label);
        }
    };


    return (
        <div className='chart-container p-0'>
          
            <div className='status-issues-chart px-4'>
                <div className='chart-legend-width'>
                    <div className='canvas-doughnut-graph ml-1 mr-5'>
                        <Doughnut ref={chartRef} data={data} options={options} onClick={handleClick} />
                    </div>
                </div>
                <div className='chart-legend-set'>
                    {data.labels.map((label, key) => (
                        <div key={key} className='legend-item' style={{ width: 'fit-content' }}>
                            <span className='legend-span'>
                                <span className='legend-box' style={{ background: data.datasets[0].backgroundColor[key] }}></span>
                                <span 
                                    ref={el => labelRefs.current[key] = el} 
                                    className='legend-box-label text-decoration-underline cursor-pointer' 
                                    style={{ minWidth: `${maxLabelWidth}px` }}
                                    onClick={() => onIssueClick(label)} 
                                >
                                    {label}
                                </span>
                            </span>
                            <span 
                                className='legend-box-value' 
                                style={{ marginLeft: `${30}px` }}
                            >
                                {data.datasets[0].data[key]}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default IssueStatusChart;
