import React from 'react';

const ValueChart = ({ color, value, size }) => {
  
  const fontSize = size === 'small' ? '3rem' : size === 'medium' ? '4.5rem' : '4.5rem'; // Default to '4.5rem' if not 'small' or 'medium'

  return (
    <React.Fragment>
        <div style={{ color: color, fontSize: fontSize }} className='fw-bold'>
            {value}
        </div>
    </React.Fragment>
  );
}

export default ValueChart;
