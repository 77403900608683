import React, { useCallback, useEffect } from "react";
import { useState } from "react";

import { Triangle } from "react-loader-spinner";
import {
  Badge,
  Card,
  Col,
  Container,
  Dropdown,
  Image,
  ListGroup,
  OverlayTrigger,
  Row,
  Stack,
  Table,
  Tooltip,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";

import axios from "../../util/axios";
import UrlForm from "./UrlForm";
import Moment from "react-moment";
import core from "../../util/core";
// Svgs as component
import { ReactComponent as IconWarning } from "../../assets/images/icon-warning.svg";
import { ReactComponent as IconCheckCircle } from "../../assets/images/icon-check-circle.svg";
import { ReactComponent as IconLoader } from "../../assets/images/icon-loader.svg";
import { Link } from "react-router-dom";

async function fetchUrls(page, pageSize) {
  try {
    let res = await axios.get("urls", {
      params: { page: page, pageSize: pageSize },
    });
    console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
const Urls = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 100;
  const [fetching, setFetching] = useState(false);

  const refresh = async () => {
    setPage(0);
    // setItems(null);
    console.log("reloading...");
    loadUrls();
  };
  const loadUrls = async () => {
    if (fetching) {
      return;
    }

    setFetching(true);

    try {
      const data = await fetchUrls(page, pageSize);
      // if (page == 0)
      // 	setItems([]);
      setItems([...items, ...data.items]);
      setPage(data.nextPage);
      console.log("news", items, page);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
	console.log('its working....')
    const timeout = setTimeout(async () => {
      await refresh();
    }, 5000);

    return () => {
		console.log('detached...')
      clearTimeout(timeout);
    };
  }, [items]);
  const fetchItems = useCallback(async () => {
    await loadUrls();
  }, [items, fetching, page, pageSize]);

  const hasMoreItems = !!page;

  const loader = (
    <div key="loader" className="loader">
      <Triangle
        height="80"
        width="80"
        color="#fff"
        ariaLabel="triangle-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );

  const renderItem = (item) => {
    // let userHandle = JSON.parse(JSON.parse(JSON.stringify(item.userHandle)));
    let temp = null;
    temp = core.parseJSON(item.device_info);
    let location = item.address
      ? item.address
      : item.location
      ? JSON.stringify(core.parseJSON(item.location))
      : "N/A";

    return (
      <Link to={"/urls/view/" + item.id} key={item.id}>
        <div className="my-2 rounded border p-md-2 p-2 mx-auto fs-md-3 ">
          <Table variant="custom" borderless>
            <tbody>
              <tr>
                <td colSpan={2}>
                  {0 == item.status ? (
                    <IconLoader className="align-middle  me-2" width="24" />
                  ) : 1 === item.status ? (
                    <IconCheckCircle className="align-middle me-2" width="24" />
                  ) : (
                    <IconWarning className="align-middle me-2" width="24" />
                  )}
                  {0 == item.status
                    ? "Sent"
                    : 1 === item.status
                    ? "Confirmed"
                    : "Rejected"}
                </td>
                <td className="text-end text-md-start"></td>
              </tr>
              <tr>
                <th>Full Name</th>
                <td className="text-end text-md-start">{item.fullname}</td>
              </tr>
              <tr>
                <th nowrap="true" width="100px">
                  Phone Number
                </th>
                <td className="text-end text-md-start">
                  {item.phone || "N/A"}
                </td>
              </tr>
              <tr>
                <th>Location</th>
                <td className="text-end text-md-start">{location || "N/A"}</td>
              </tr>
              <tr>
                <th>Date & Time</th>
                <td className="text-end text-md-start">
                  <Moment format="DD.MM.YYYY h:mmA">
                    {item.viewed_at ? item.viewed_at : item.created_at}
                  </Moment>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Link>
    );
  };
  return (
    <React.Fragment>
      <InfiniteScroll
        loadMore={fetchItems}
        hasMore={hasMoreItems}
        loader={loader}
        className=""
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="fw-bold fs-6 text-white my-3">Location History</div>

          <Dropdown className="">
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              All
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>Sent</Dropdown.Item>
              <Dropdown.Item>Confirmed</Dropdown.Item>
              <Dropdown.Item>Rejected</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Row>
          <Col>{items ? items.map((item) => renderItem(item)) : loader}</Col>
        </Row>
      </InfiniteScroll>
    </React.Fragment>
  );
};

export default Urls;
