import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PublicLayout from "../portal/layout/PublicLayout";

export const PublicRoute = (props) => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    console.log("rooute", routeParams, location);
    // check if is user is trying to register...
    if (location.pathname.indexOf("auth/register") && routeParams.code) {
      localStorage.clear();
    } else {
      const userToken = localStorage.getItem("user");
      if (userToken) {
        setIsLoggedIn(true);
        return navigate("/");
      }
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkUserToken();
  });

  return (
    <React.Fragment>
      <PublicLayout>
        <div>{!isLoggedIn ? props.children : null}</div>
      </PublicLayout>
    </React.Fragment>
  );
};
