import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../assets/scss/_6_pages/404.scss";
import { Link } from "react-router-dom";
const NotFound = () => {
  const year = new Date().getFullYear();
  return (
    <React.Fragment>
      <Container className="max-80 my-5 not-found invitation-form-set">
        <Row>
          <Col className="error-container">
            <span className="four">
              <span className="screen-reader-text">4</span>
            </span>
            <span className="zero">
              <span className="screen-reader-text">0</span>
            </span>
            <span className="four">
              <span className="screen-reader-text">4</span>
            </span>
          </Col>
        </Row> 
        <Row>
          <Col className="terminal">
            <h1>
              <span className="errorcode">Oops</span> Page Note Found!
            </h1>
            <p className="output">
              The page you are looking for might have been removed, had its name
              changed or is temporarily unavailable.
            </p>
            <p className="output">
              <Link
                to="/"
              >
                {" return to the homepage"}
              </Link>
             
            </p>
            <p className="output">Good luck.</p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default NotFound;
