import React, { useState, useEffect, useRef, useContext } from "react";
import DashedImage from "../../assets/images/Frame.png";
import { Link, useLocation } from "react-router-dom";
import { Col, Collapse, Accordion } from "react-bootstrap";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import { ReactComponent as Error } from "../../assets/images/Error.svg";
import { ReactComponent as Loader } from "../../assets/images/loader.svg";
import { ReactComponent as IconWarning } from "../../assets/images/icon-warning.svg";

import { categories, services } from "../../util/scans";
import axios from "../../util/axios";
import { ServicesContext } from "../../components/ServicesContext";
import { ScannerContext } from "../../components/ScannerContext";

const LeftNavbar = ({ open, selectedItem, disableScanner }) => {
  const { fetchAllServicesItems, scannerServices } =
    useContext(ServicesContext);
  const { dropdownItems } = useContext(ScannerContext);
  const [allServices, setAllServices] = useState(services);
  const sortedCategories = categories.sort(
    (a, b) => a.categ_order - b.categ_order
  );
  const [openSections, setOpenSections] = useState(
    sortedCategories.map((_, index) => `${index}`)
  );
  const location = useLocation();
  const timeoutRef = useRef(null);

  useEffect(() => {
    let isSubscribed = true;

    const fetchServices = async (id) => {
      if (!id) return;

      try {
        const { data } = await axios.get(`scans/${id}/services`);
        if (!isSubscribed) return;
        const updatedServices = services.map((service) => {
          const foundService = data?.services?.find(
            (item) => item.service_id === service.id
          );
          if (foundService) {
            let menuEnabled = typeof foundService['menu_enabled'] == 'undefined' ? service.menu_enabled : foundService?.menu_enabled;
            let tmpService = {
              ...service,
              status: foundService
                ? foundService.service_status
                : service.status,
              updated_at: foundService?.updated_at,
              has_issues: foundService?.has_issues,
              menu_enabled: menuEnabled
            };


            return tmpService;
          } else {
            return {
              ...service,
              menu_enabled: 0,
              has_issues: null,
            };
          }
        });
        fetchAllServicesItems(updatedServices);
        setAllServices(updatedServices);

        const allServicesUpdated = updatedServices.every(
          (service) =>
            service.status !== 0 && service.status !== 1 && service.status !== 2
        );
        if (!allServicesUpdated) {
          timeoutRef.current = setTimeout(() => fetchServices(id), 5000);
        }
      } catch (err) {
        if (err?.response?.data?.status == false) {
          const updatedServices = services.map((service) => {
            if (service.menu_enabled == 1) {
              return {
                ...service,
                status: 0,
              };
            } else {
              return {
                ...service,
              };
            }
          });
          fetchAllServicesItems(updatedServices);
          setAllServices(updatedServices);
        }
        if (isSubscribed) {
          timeoutRef.current = setTimeout(() => fetchServices(id), 5000);
        }
      }
    };

    if (selectedItem?.id) {
      console.log(selectedItem);
      fetchServices(selectedItem.target_uuid);
    }

    return () => {
      isSubscribed = false;
      clearTimeout(timeoutRef.current);
    };
  }, [selectedItem?.id]);

  const toggleSection = (eventKey) => {
    setOpenSections((prev) => {
      if (prev.includes(eventKey)) {
        return prev.filter((item) => item !== eventKey);
      } else {
        return [...prev, eventKey];
      }
    });
  };
  return (
    <React.Fragment>
      <Collapse in={open} dimension="width">
        <Col
          lg="auto"
          md="12"
          className="p-0 sidebar-col border-end sub-sidebar"
        >
          <Accordion
            activeKey={openSections}
            onSelect={toggleSection}
            className="custom-accordion-sub"
          >
            {sortedCategories.map((category, categoryIndex) => (
              <Accordion.Item
                eventKey={`${categoryIndex}`}
                className="custom-accordion-item"
                key={categoryIndex}
              >
                <Accordion.Header className="custom-accordion-header custom-child-header">
                  <ArrowDown
                    height={25}
                    width={25}
                    className="accordion-arrow svg-white pe-2"
                  />
                  {category.categ_name}
                </Accordion.Header>
                {allServices
                  .filter(
                    (service) =>
                      service.category_id === category.id &&
                      service.show_in_menu
                  )
                  .map((service, serviceIndex) => {
                    let active =
                      service.key?.replace(
                        ":target_id",
                        selectedItem?.target_uuid
                      ) === location.pathname;
                    return (
                      <Accordion.Body
                        key={`${categoryIndex}-${serviceIndex}`}
                        className={`custom-accordion-body sub-body ${active ? "sub-body-active" : ""
                          } ${!service.menu_enabled || dropdownItems?.length == 0
                            ? "menu-disabled"
                            : ""
                          }`}
                        style={{
                          backgroundImage: `url(${DashedImage})`,
                          cursor:
                            dropdownItems?.length > 0
                              ? service.menu_enabled
                                ? "pointer"
                                : "no-drop"
                              : "no-drop",
                        }}
                        as={
                          dropdownItems?.length > 0
                            ? service.menu_enabled
                              ? Link
                              : "div"
                            : "div"
                        }
                        to={
                          service.key?.replace(
                            ":target_id",
                            selectedItem?.target_uuid
                          ) || "#"
                        }
                      >
                        <span
                          style={{
                            fontSize: 14,
                            color: active ? "#3DDC97" : "inherit",
                          }}
                        >
                          {service.title}
                        </span>
                        {!!service.is_new && (
                          <span className="new-tag">new</span>
                        )}
                        {service.has_issues === 1 && service.menu_enabled ? (
                          <IconWarning
                            className="warning-icon"
                            width="24"
                            style={{
                              right:
                                service.status !== -1 &&
                                  service.status !== 3 &&
                                  service.status !== null &&
                                  service.menu_enabled
                                  ? "33px"
                                  : "5px",
                            }}
                          />
                        ):null}
                        {" "}
                        {service.status !== -1 &&
                          service.status !== 3 &&
                          service.status !== null &&
                          !!service.menu_enabled && (
                            <Loader
                              className="Loader-icon"
                              height={20}
                              width={20}
                            />
                          )}
                      </Accordion.Body>
                    );
                  })}
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Collapse>
    </React.Fragment>
  );
};

export default LeftNavbar;
