import React, { Component } from 'react';
import Display from './display/Index';
import Keypad from './keypad/Index';
import Action from './action/Index';
import { Stack } from 'react-bootstrap';

class InitialView extends Component {
  constructor(props) {
    super();
    let contact = props.contact;
    this.state = {
      display: (contact && contact.number ? contact.number : "")
    };
    this.onDigitPressed = this.onDigitPressed.bind(this);
    this.onBackPressed = this.onBackPressed.bind(this);
    this.onCallPressed = this.onCallPressed.bind(this);
  }

  onDigitPressed(value, keepCurrent = true) {
    const currentDisplay = this.state.display;
    let tmpVal = (keepCurrent ? currentDisplay : "") + value;
    this.setState({
      display: tmpVal
    });
  }


  onBackPressed() {
    const currentDisplay = this.state.display;

    this.setState({
      display: currentDisplay.slice(0, -1)
    });
  }

  onCallPressed() {
    const { onCallPressed } = this.props;

    if (onCallPressed) {
      onCallPressed(this.state.display);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.contact?.number && this.props.contact?.number !== prevProps.contact.number
    ) {
      // this.setState({display: this.props.contact});
      this.setState({
        display: this.props.contact?.number
      });
    }
  }


  render() {
    const { style } = this.props;

    return (
      <Stack gap={5}>
        <Display
          value={this.state.display}
          updateValue={this.onDigitPressed}
        />
        {/* <Keypad onDigitPressed={this.onDigitPressed} /> */}
        <Action
          disabled={this.state.display}
          onBackPressed={this.onBackPressed}
          onCallPressed={this.onCallPressed}
        />
      </Stack>
    );
  }
}

export default InitialView;
