import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const VerticalBarChart = ({ VerticalBarChartdata, VerticalBarChartoptions }) => {
    return (

        <div className='chart-container d-flex align-items-center justify-content-center h-100 w-100'>
            <div className='col-12 '>
                <Bar data={VerticalBarChartdata} options={VerticalBarChartoptions} />
            </div>
        </div>

    );
};

export default VerticalBarChart;
