import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import Card from 'react-bootstrap/Card';
import NoData from '../empty/NoData';


const CircularChart = ({ CircularChartdata, CircularChartoptions }) => {
  const hasData =CircularChartdata.datasets[0].data.length>0;
  return (
    <React.Fragment>
      {hasData ? (
        <Doughnut data={CircularChartdata} options={CircularChartoptions} />
      ) : (
        <NoData />
      )}
    </React.Fragment>
  );
};

export default CircularChart;
