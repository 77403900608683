import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as NoScanIcon } from "../../assets/images/no-scanner.svg";

const TypingEffect = ({ message, containerRef, lastItemRef }) => {
  const [displayedMessage, setDisplayedMessage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const typingSpeed = 70;

  useEffect(() => {
    setDisplayedMessage("");
    setCurrentIndex(0);
  }, [message]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < message.length) {
        setDisplayedMessage((prevMessage) => prevMessage + message[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval);
      }
    }, typingSpeed);

    return () => clearInterval(interval);
  }, [currentIndex, message, typingSpeed]);

  useEffect(() => {
    if (containerRef.current && lastItemRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [displayedMessage, containerRef, lastItemRef]);

  return (
    <span>
      {displayedMessage}
      {currentIndex < message.length ? "" : <span className="blinking-cursor">|</span>}
    </span>
  );
};

const WebScanner = ({ title, data }) => {
  const [scannerEvents, setScannerEvents] = useState([]);
  const containerRef = useRef(null);
  const lastItemRef = useRef(null);

  useEffect(() => {
    console.log("data-logs", data);
    setScannerEvents(data);
  }, [data]);

  useEffect(() => {
    if (containerRef.current && lastItemRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [scannerEvents]);

  return (
    <React.Fragment>
      <div className="waf font">
        {scannerEvents?.length > 0 ? (
          <div
            className="waf-card_subsection border-0 m-h-13"
            style={{
              background: "#141416",
              overflowY: "auto",
              maxHeight: "500px",
              height: "100%", // Ensure it takes up all available space
            }}
            ref={containerRef}
          >
            {scannerEvents.map((event, index) => (
              <div
                key={index}
                ref={index === scannerEvents.length - 1 ? lastItemRef : null}
              >
                <div
                  className={`${
                    event.type === "Scanner Initiated"
                      ? "waf-top-left"
                      : "waf-mid-left"
                  } ${event.isDivider ? "logs-dashed-borders" : "border-0"}`}
                  style={{ marginTop: index === 0 ? "0px" : "10px" }}
                >
                  {!event.isTimestamp && (
                    <>
                      {event.time && `[${event.time}]`}{" "}
                      {event.type && `${event.type}:`}{" "}
                    </>
                  )}
                  <span
                    className={
                      event.type === "Scanner Initiated"
                        ? "waf-top-right"
                        : "waf-mid-right"
                    }
                  >
                    {index === scannerEvents.length - 1 ? (
                      <TypingEffect
                        message={event.message}
                        containerRef={containerRef}
                        lastItemRef={lastItemRef}
                      />
                    ) : (
                      event.message
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="waf-card_subsection_empty">
            <div className="no_scanner_container">
              <NoScanIcon />
              <div className="no_scanner_text">Scanner Output Coming Soon</div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default WebScanner;
