import React, { useState } from "react";
import ManageIcon from "../../assets/images/manage.svg";
import ManageBlueIcon from "../../assets/images/manage-b.svg";

const ManageColumnsButton = ({ allColumns, setAllColumns }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleSelectAllToggle = () => {
    const allSelected = !allColumns.every((column) => column.visibility);
    setAllColumns(
      allColumns.map((column) => ({
        ...column,
        visibility: column?.default ? true : allSelected,
      }))
    );
  };

  const handleClearAll = () => {
    setAllColumns(
      allColumns.map((column) => ({
        ...column,
        visibility: column?.default ? true : false,
      }))
    );
  };

  const handleCheckboxChange = (column_) => {
    if (!column_?.default) {
      setAllColumns(
        allColumns.map((column) => ({
          ...column,
          visibility:
            column.key === column_?.key
              ? !column.visibility
              : column.visibility,
        }))
      );
    }
  };

  const isAllSelected = allColumns.every((column) => column.visibility);

  return (
    <div className="manage-columns-container">
      <div
        className={`manage-columns-button ${isDropdownVisible ? "active" : ""}`}
        onClick={toggleDropdown}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={isHovered || isDropdownVisible ? ManageBlueIcon : ManageIcon}
          alt="Manage Icon"
          className="manage-icon"
        />
        <span>Manage Columns</span>
      </div>
      {isDropdownVisible && (
        <div className="custom-dropdown" style={{ marginTop: "10px" }}>
          <div className="select-clear-all">
            <input
              type="checkbox"
              id="select-all"
              checked={isAllSelected}
              onChange={handleSelectAllToggle}
            />
            <label htmlFor="select-all">Select All</label> |
            <span onClick={handleClearAll}>Clear All</span>
          </div>
          <hr />
          <div className="dropdown-items">
            {allColumns.map((column) => (
              <div className="dropdown-item" key={column.key}>
                <input
                  type="checkbox"
                  id={column.key}
                  checked={column?.default || column.visibility}
                  disabled={column?.default}
                  onChange={() => {
                    handleCheckboxChange(column);
                  }}
                />
                <label htmlFor={column.key}>{column.header}</label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageColumnsButton;
