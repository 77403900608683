import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const DecisionToggle = ({ isIndication, label }) => {
    let badgeClass;

    if (isIndication === "yellow") {
        badgeClass = "custom-yellow-badge";
    } else {
        badgeClass = isIndication ? "custom-green-badge" : "custom-red-badge";
    }

    return (
        <Button
        className={`d-flex align-items-center justify-content-center  ${badgeClass}`}
            variant="outline-primary"
            onMouseOver={(e) => { e.target.style.backgroundColor = 'transparent'; }}
            onMouseOut={(e) => { e.target.style.backgroundColor = 'transparent'; }}
            onFocus={(e) => { e.target.style.backgroundColor = 'transparent'; }}
        >
            {label}
        </Button>
    );
};




export default DecisionToggle;