import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import { Triangle } from "react-loader-spinner";

const schema = Yup.object().shape({
  recipient_name: Yup.string().required("Please provide recipient name."),
  fullname: Yup.string().required("Please provide name."),
  url: Yup.string().required("Please provide URL."),
});

const UrlWidget = () => {
  const navigate = useNavigate();
  const [submitBtn, setSubmitBtn] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const myInfo = JSON.parse(localStorage.getItem("user"));
  let myName = "";
  if (myInfo && myInfo["fullname"]) {
    myName = myInfo.fullname;
  }

  let initialValues = {
    recipient_name: "",
    fullname: myName,
    url: "https://",
  };
  const init = async () => {
    //
  };
  useEffect(() => {
    //passing getData method to the lifecycle method
    init();
  }, []);

  const handleSubmit = (values, helpers) => {
    console.log("my data", values);
    setIsLoading(true);
    navigate('/urls')
    navigate({
        pathname: '/urls',
        search: `?${createSearchParams(values)}`,
      });
  };
  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Generating URL..." });
    } else {
      setSubmitBtn({ disabled: false, label: "Next" });
    }
  }, [isLoading]);
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            render={({
              handleChange,
              handleSubmit,
              handleBlur,
              values,
              errors,
              validateForm,
              dirty,
              isValid,
            }) => (
              <Form>
                {/* Sender Name Field */}
                <Field
                  name="recipient_name"
                  render={({ field, form: { touched, errors }, meta }) => (
                    <FormGroup controlId="recipient_name" className="my-3">
                      <FloatingLabel
                        controlId="floatingSenderName"
                        label="Recipient Name"
                      >
                        <FormControl
                          type={"text"}
                          size="lg"
                          value={field.value}
                          onChange={field.onChange}
                          placeholder="John Doe"
                          className={
                            meta.touched && meta.error ? "is-invalid" : ""
                          }
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="invalid-feedback">{meta.error}</div>
                        ) : null}
                      </FloatingLabel>
                    </FormGroup>
                  )}
                />
                {/* Full Name Field */}
                <Field
                  name="fullname"
                  render={({ field, form: { touched, errors }, meta }) => (
                    <FormGroup controlId="fullname" className="my-3">
                      <FloatingLabel
                        controlId="floatingFullName"
                        label="Your Name"
                      >
                        <FormControl
                          type={"text"}
                          size="lg"
                          value={field.value}
                          onChange={field.onChange}
                          placeholder="John Doe"
                          className={
                            meta.touched && meta.error ? "is-invalid" : ""
                          }
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="invalid-feedback">{meta.error}</div>
                        ) : null}
                      </FloatingLabel>
                    </FormGroup>
                  )}
                />
                {/* URL Field */}
                <Field
                  name="url"
                  placeholder="https:/anywhere.com"
                  render={({ field, form: { touched, errors }, meta }) => (
                    <FormGroup controlId="url" className="my-3">
                      <FloatingLabel
                        controlId="url"
                        label="URL"
                        placeholder="https:/anywhere.com"
                      >
                        <FormControl
                          type={"text"}
                          size="lg"
                          value={field.value}
                          onChange={field.onChange}
                          className={
                            meta.touched && meta.error ? "is-invalid" : ""
                          }
                          placeholder="https:/anywhere.com"
                          {...field}
                        />
                        {meta.touched && meta.error ? (
                          <div className="invalid-feedback">{meta.error}</div>
                        ) : null}
                      </FloatingLabel>
                    </FormGroup>
                  )}
                />
                {/* URL Field */}
                <Button
                  type="submit"
                  className="btn bg-primary btn-full -3"
                  id="singup-btn"
                  disabled={submitBtn.disabled || !(dirty && isValid)}
                >
                  {submitBtn.label}
                </Button>
              </Form>
            )}
          />
          <div key="loader" className="loader">
            <Triangle
              height="80"
              width="80"
              color="#185f95"
              ariaLabel="triangle-loading"
              wrapperStyle={{ justifyContent: "center" }}
              wrapperClassName=""
              visible={isLoading}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UrlWidget;
