import React from "react";
import { ReactComponent as Globe } from "../../assets/images/globe.svg";
import { ReactComponent as PlusBlue } from "../../assets/images/plus-blue.svg";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb";

const EmptyScan = () => {
  const breadcrumbItems = ["Assets Inventory"];
  return (
    <React.Fragment>
      <Breadcrumb breadcrumbItems={breadcrumbItems} isScanning={false} isLastUpdate={false}/>
      <div className="empty-scan-container">
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <div className="position-relative globe-easm d-flex align-items-center justify-content-center mb-1">
            <PlusBlue className="globe-plus-position" />
            <Globe />
          </div>
          <div className="text-center">
            <h1 className="fw-bold m-2 pt-1">No Target Scanned Yet</h1>
            <p className="fw-normal text-white font-18 py-2">Get started by adding your first target</p>
            <Link to="/assets-inventory/new" className="btn btn-primary rounded-4 align-self-start w-auto link-color">
              Add Your First Target
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmptyScan;
