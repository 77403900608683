import React from "react";
class AudioPlayer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <audio controls src={this.props.src}  className="audioplayer" />
      </React.Fragment>
    );
  }
}

export default AudioPlayer;
