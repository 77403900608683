import React, { Component } from 'react';
import STATES from '../../../../../util/states';

class Status extends Component {
  constructor() {
    super();
  }

  render() {
    const { state } = this.props;

    return (
      <div className="status py-4">
        {STATES[state] ? STATES[state]  : state}
      </div>
    );
  }
}

export default Status;
