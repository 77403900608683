// GenericInput.js
import React from 'react';
import { FormControl } from 'react-bootstrap';

const BaseInput = ({ icon: Icon, value, onChange, ...props }) => {
  return (
    <div className="search-container">
      <FormControl  value={value}
        onChange={onChange}
         {...props} />
      <div className="input-group-append">
        {Icon && <Icon />}
      </div>
    </div>
  );
};

export default BaseInput;
