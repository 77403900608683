import React,{useEffect} from "react";
import { ReactComponent as Check } from "../../assets/images/check.svg";
import { ReactComponent as Cancel } from "../../assets/images/cancel.svg";

const PasswordRequirements = ({ password ,setPasswordValid}) => {
  const checkPasswordRequirements = (password) => {
    const requirements = [
      { regex: /.{8,}/, label: "Minimum 8 characters" },
      { regex: /[A-Z]/, label: "At least one uppercase letter" },
      { regex: /[a-z]/, label: "At least one lowercase letter" },
      { regex: /[0-9]/, label: "At least one number" },
      { regex: /[!@#$&*]/, label: "At least one special character" },
    ];
    return requirements.map((req) => ({
      ...req,
      isValid: req.regex.test(password),
    }));
  };

  const passwordRequirements = checkPasswordRequirements(password);
  useEffect(() => {
    const isValid = passwordRequirements.every((req) => req.isValid);
    setPasswordValid(isValid);
  }, [password, passwordRequirements, setPasswordValid]);

  return (
    <React.Fragment>
      <p className="m-0">Your password must Include:</p>
      <ul className="password-requirements p-0 ms-2">
        {passwordRequirements.map((req) => (
          <li
            key={req.label}
            className={`password-requirement d-flex align-items-center ${
              password !== ""
                ? req.isValid
                  ? "text-white"
                  : "text-danger"
                : ""
            }`}
          >
            {password !== "" ? (
              req.isValid ? (
                <Check color="green" height={20} width={20} />
              ) : (
                <Cancel color="red " />
              )
            ) : <span className="dot me-2"></span>}
           <span className="">{req.label}</span>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default PasswordRequirements;
