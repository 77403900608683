import React, { useState } from "react";
import BaseTable from '../../../../components/table/BaseTable';
import BaseFilter from '../../../../components/filter/BaseFilter';
import BaseInput from '../../../../components/form/BaseInput';
import { ReactComponent as Search } from "../../../../assets/images/search.svg";
import GenericCard from "../../../../components/card/GenericCard"
import ValueChart from "../../../../components/charts/ValueChart";
import WebScanner from '../../../../components/web-scanner/WebScanner';
import WebUpdate from '../../../../components/website-updates/WebUpdate';
import { useParams } from "react-router-dom";
import { ThreeCircles } from 'react-loader-spinner'

const GitHubExposure = () => {
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);
    const [cms, setCms] = useState([]);
    const routeParams = useParams();

    const data = [{
        id: 1,
        URL: '-',
        title: "-",
        description: "-"

    },
    {
        id: 1,
        URL: '-',
        title: "-",
        description: "-"

    },
    ];

    const columns = [
        { Header: 'GitHub URL', accessor: 'URL', isSortable: false },
        { Header: 'Title', accessor: 'title' },
        { Header: 'Description', accessor: 'description' },
    ];

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleRowSelect = (selectedRows) => {

    };

    const handleAction = (rowData) => {

    };

    const filteredCms = cms.filter(item =>
        item.cms?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.host?.includes(searchValue)
    );

    const sampleData = [
        { id: 1, type: 'upload' },
        { id: 2, type: 'upload' },
        { id: 3, type: 'removal' },
        { id: 4, type: 'removal' },
        { id: 5, type: 'upload' },
    ];


    return (
        <React.Fragment>
            {
                isLoading ? <div className="content-loader">
                    <ThreeCircles
                        visible={true}
                        height="60"
                        width="60"
                        color="#ffff"
                        ariaLabel="three-circles-loading"
                        wrapperClass=""
                    />
                </div>
                    :
                    <div className="main_container_style">
                        <div className="pb-4 row top-container-main" >
                            <div className="col-12 col-lg-6 h-100">
                                <div className="row gap-4 top-container-main-row" >
                                    <div className="col-12 h-50" >
                                        <GenericCard children={<ValueChart color="#BCF4DE" value="100" />} title={"GitHub Mentions"} subtitle={"View Scanner"} />
                                    </div>
                                    <div className="col-12 h-50" >
                                        <div className="row h-100" >
                                            <div className="col-12">
                                                <GenericCard children={<WebUpdate data={sampleData} />} title={'GitHub Mentions Updates'}
                                                    subtitle={"View Issues"}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 h-100">
                                <WebScanner title={'GitHub Exposure Scanner'} />
                            </div>
                        </div>

                        <div className="pb-5 pt-3">
                            <div className='table-container' >
                                <div className='table-left'>
                                    Results
                                </div>
                                <div >
                                    <BaseInput
                                        type="text"
                                        placeholder="Search..."
                                        className="search-input"
                                        icon={Search}
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <div >
                                <BaseFilter totalRecords={filteredCms.length} exportTitle={'CMS_TARGET_' + routeParams?.target_id} exportHeader={["Web Servers", "Indication of CMS", "CMS Name", "CMS Version"]} exportRows={filteredCms.map(cms => [cms.host, cms.has_cms, cms.cms, cms.cms_version])} />

                            </div>
                            <div>
                                <BaseTable className="mt-3 mb-3"
                                    columns={columns}
                                    data={filteredCms}
                                    selectable={true}
                                    onRowSelect={handleRowSelect}
                                    showCheckboxes={false}
                                    action={false}
                                    loading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
            }

        </React.Fragment>
    )
}

export default GitHubExposure;