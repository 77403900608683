import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import WebUpdate from "../../../../components/website-updates/WebUpdate";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import HorizontalBarChart from "../../../../components/charts/HorizontalBarChart";
import BarChart from "../../../../components/charts/BarChart";
import { ThreeCircles } from "react-loader-spinner";
import axios from "../../../../util/axios";
import { useParams, useOutletContext } from "react-router-dom";
import GenericCard from "../../../../components/card/GenericCard";
import { Button, ButtonGroup } from "react-bootstrap";
import moment from "moment";
import NoData from "../../../../components/empty/NoData.js";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning.js";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import {
  CreateBarChartData,
  CreateBarChartByType,
  barChartOptions,
  CreateHorizontalBarChartData,
  horizontalBarChartOptions,
  baseColumns,
  webSpecificColumns,
  networkSpecificColumns,
} from "./ChartsHelp.js";
import { webUpdatesFilter } from "../../../../util/genericFunctions.js";

const Certificates = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const [selectedTable, setSelectedTable] = useState("web");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [noDataHorizontalBarChart, setNoDataHorizontalBarChart] =
    useState(false);
  const [noDataBarChart, setNoDataBarChart] = useState(false);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [horizontalBarChartType, setHorizontalBarChartType] = useState([]);
  const [barChartType, setBarChartType] = useState([]);
  const routeParams = useParams();
  const [certificates, setCertificates] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  const calculateDaysLeft = (validFrom, validTo) => {
    if (!validFrom || !validTo) return null;
    const from = moment().startOf("day");
    const to = moment(validTo).startOf("day");
    return to.diff(from, "days");
  };

  const updateCertificatesWithDaysLeft = (certificateGroups) => {
    const updatedGroups = [];
    certificateGroups.forEach((cert) => {
      const daysLeft = calculateDaysLeft(cert.valid_from, cert.valid_to);
      updatedGroups.push({
        ...cert,
        days: daysLeft,
        ip: cert.scan_port?.ip?.ip,
        service:
          cert.scan_port?.service && cert.scan_port?.port
            ? `${cert.scan_port.service}:${cert.scan_port.port}`
            : null,
        host: cert.host?.host,
        is_expired: daysLeft < 0,
      });
    });
    return updatedGroups;
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const getColumns = () => {
    const base = [...baseColumns];
    return selectedTable === "web"
      ? [...webSpecificColumns, ...base]
      : [...networkSpecificColumns, ...base];
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters, "7"));
  };

  // table
  const sampleData = [
    { id: 1, type: "upload" },
    { id: 2, type: "upload" },
    { id: 3, type: "removal" },
    { id: 4, type: "removal" },
    { id: 5, type: "upload" },
  ];
  useEffect(() => {
    updateTabsWithData(filteredCertificates);
  }, [selectedTable, certificates]);

  const updateTabsWithData = (data) => {
    const hosts = Array.from(
      new Set(
        data.map((cert) => {
          if (cert.host && cert.host !== "-") {
            return cert.host;
          } else if (cert.scan_port?.ip?.ip && cert.scan_port?.ip?.ip !== "-") {
            return cert.scan_port.ip.ip;
          } else {
            return "-";
          }
        })
      )
    ).map((hostname) => ({
      id: hostname,
      name: hostname,
      type: "Hostname: " + hostname,
      key: "1",
      active: false,
    }));

    const ip = Array.from(
      new Set(
        data
          .map((cert) => cert.scan_port?.ip.ip)
          .flat()
          .filter((ip) => ip)
      )
    ).map((ip) => ({
      id: ip,
      name: ip,
      type: "IP Address: " + ip,
      key: "1",
      active: false,
    }));

    const commonNames = Array.from(
      new Set(data.map((cert) => cert.common_name))
    ).map((common) => ({
      id: common,
      name: common ? common : "-",
      type: common ? "Common Name: " + common : "Common Name: -",
      key: "3",
      active: false,
    }));

    const expireds = Array.from(
      new Set(data.map((cert) => cert.is_expired))
    ).map((expire) => ({
      id: expire,
      name: expire ? "Yes" : "No",
      type: expire ? "Expired: Yes" : "Expired: No",
      key: "4",
      active: false,
    }));

    const issues = Array.from(new Set(data.map((cert) => cert.issuer))).map(
      (issuer) => ({
        id: issuer,
        name: issuer ? issuer : "-",
        type: issuer ? "Issuer: " + issuer : "Issuer: -",

        key: "5",
        active: false,
      })
    );
    const services = Array.from(
      new Set(
        data
          .map((cert) => cert.scan_port?.service)
          .flat()
          .filter((service) => service)
      )
    ).map((service) => ({
      id: service,
      name: service,
      type: "Service: " + service,
      key: "6",
      active: false,
    }));
    const baseTabs = [
      {
        eventKey: "1",
        title: selectedTable === "web" ? "Hostname" : "IP Address",
        contentTitle: selectedTable === "web" ? "Hostname" : "IP Address",
        data: selectedTable === "web" ? hosts : ip,
      },
      {
        eventKey: "3",
        title: "Common Name",
        contentTitle: "Common Name",
        data: commonNames,
      },
      {
        eventKey: "4",
        title: "Expired",
        contentTitle: "Expired",
        data: expireds,
      },
      { eventKey: "5", title: "Issuer", contentTitle: "Issuer", data: issues },
    ];

    if (selectedTable === "network") {
      baseTabs.splice(1, 0, {
        eventKey: "6",
        title: "Service",
        contentTitle: "Service",
        data: services,
      });
    }

    setTabs(baseTabs);
  };

  const fetchCertificate = async () => {
    try {
      const { data } = await axios.get(
        `scans/${routeParams.target_id}/certificates`
      );
      const updatedCertificateGroups = updateCertificatesWithDaysLeft(
        data.certificates
      );
      setWebUpdatesData(data?.groups?.changes);
      const barChartData = CreateBarChartByType(updatedCertificateGroups);
      setNoDataBarChart(barChartData?.every((value) => value === 0));
      setBarChartType(barChartData);
      if (
        data?.certificates?.length === 0 &&
        (scanningStatus === 2 || scanningStatus === 1)
      ) {
        setIsDataInProcess(true);
      } else {
        setIsDataInProcess(false);
      }
      if (scanningStatus === -1 || scanningStatus === 3) {
        if (
          data.groups.security.oldProtocols ||
          data.groups.security.unsupportedCiphers ||
          data.groups.security.secure
        ) {
          setNoDataHorizontalBarChart(false);
        } else {
          setNoDataHorizontalBarChart(true);
        }
      }

      setCertificates(Object.values(updatedCertificateGroups));
      updateTabsWithData(Object.values(updatedCertificateGroups));
      setHorizontalBarChartType({
        oldProtocols: data.groups.security.oldProtocols,
        unsupportedCiphers: data.groups.security.unsupportedCiphers,
        secure: data.groups.security.secure,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);
    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (scanningStatus === 3 || scanningStatus === -1) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchCertificate();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchCertificate();
        fetchIntervalRef.current = setInterval(fetchCertificate, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const getExportHeaders = [
    ...(selectedTable === "web"
      ? ["Hostname", "Serial #"]
      : ["IP Address", "Service", "Serial"]),
    "Common Name",
    "Expired",
    "Issuer",
    "Valid From",
    "Expired On",
    "Days Left",
    "Secured Ciphers",
    "Unsecured Ciphers",
    "Secured Protocols",
    "Unsecured Protocols",
  ];

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  // For Web: host should be available or if scan port service is http
  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredCertificates = certificates.filter((cert) => {
    // Ensure unique services
    const uniqueServices = Array.from(
      new Set(cert.services?.map(JSON.stringify))
    ).map(JSON.parse);
    cert.services = uniqueServices;
    const matchesTable =
      (selectedTable === "web" &&
        ((cert.host && cert.host.length > 0) ||
          cert?.scan_port?.service?.indexOf("http") >= 0)) ||
      (selectedTable !== "web" &&
        cert.scan_port &&
        Object.keys(cert.scan_port).length > 0 &&
        cert.scan_port?.service &&
        cert.scan_port.service?.indexOf("http") < 0);

    const matchesSearch =
      searchValue === "" ||
      (cert.serial &&
        cert.serial.toLowerCase().includes(searchValue.toLowerCase())) ||
      (cert.common_name &&
        cert.common_name.toLowerCase().includes(searchValue.toLowerCase())) ||
      (cert.scan_port?.service &&
        cert.scan_port?.service
          .toLowerCase()
          .includes(searchValue.toLowerCase())) ||
      (cert.hosts &&
        cert.hosts.some((host) =>
          host.toLowerCase().includes(searchValue.toLowerCase())
        )) ||
      (cert.scan_port &&
        cert.scan_port.ip.ip
          .toLowerCase()
          .includes(searchValue.toLowerCase())) ||
      (cert.ips &&
        cert.ips.some((ipObj) =>
          ipObj.ip.toLowerCase().includes(searchValue.toLowerCase())
        ));

    // Check if item matches all groups of filters (intersection)
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      // Check if item matches at least one filter in the current group (union)
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return (
            (filter.name === "-" && (!cert.host || cert.host === "-")) ||
            (cert.host && cert.host.toLowerCase() === lowerCaseFilter) ||
            (filter.name === "-" && (!cert.hosts || cert.hosts.length === 0)) ||
            (filter.name === "-" && (!cert.ip || cert.ip === "-")) ||
            (cert.ip && cert.ip.toLowerCase() === lowerCaseFilter)
          );
        } else if (eventKey === "3") {
          return (
            (filter.name === "-" &&
              (!cert.common_name || cert.common_name === "-")) ||
            (cert.common_name &&
              cert.common_name.toLowerCase() === lowerCaseFilter)
          );
        } else if (eventKey === "4") {
          return (
            (lowerCaseFilter === "no" && !cert.is_expired) ||
            (lowerCaseFilter === "yes" && cert.is_expired === true)
          );
        } else if (eventKey === "5") {
          return (
            (filter.name === "-" && (!cert.issuer || cert.issuer === "-")) ||
            (cert.issuer && cert.issuer.toLowerCase() === lowerCaseFilter)
          );
        } else if (eventKey === "6") {
          return (
            (filter.name === "-" &&
              (!cert.scan_port?.service || cert.scan_port?.service === "-")) ||
            (cert.scan_port?.service &&
              cert.scan_port?.service.toLowerCase() === lowerCaseFilter)
          );
        } else if (eventKey === "7") {
          return cert?.change_status === statusMap[filter.name.toLowerCase()];
        }
        return false;
      });
    });
    return matchesTable && matchesSearch && matchesFilters;
  });

  const getExportRows = () => {
    const rows = [];

    filteredCertificates.forEach((cert) => {
      const commonFields = [
        cert.serial,
        cert.common_name,
        cert.is_expired ? "Yes" : "No",
        cert.issuer,
        cert.valid_from
          ? moment(new Date(cert.valid_from)).format("DD/MM/YYYY")
          : "-",
        cert.valid_to
          ? moment(new Date(cert.valid_to)).format("DD/MM/YYYY")
          : "-",
        cert.days,
        cert.ciphers ? `"${cert.ciphers.secure.join(", ")}"` : "",
        cert.ciphers ? `"${cert.ciphers.insecure.join(", ")}"` : "",
        cert.protocols ? `"${cert.protocols.secure.join(", ")}"` : "",
        cert.protocols ? `"${cert.protocols.insecure.join(", ")}"` : "",
      ];

      if (selectedTable === "web") {
        const hosts = cert.hosts ? cert.hosts : ["-"];
        hosts.forEach((host) => {
          rows.push([host, ...commonFields]);
        });
      } else if (selectedTable === "network") {
        const ips = cert.services
          ? cert.services.map((service) => service.ip)
          : ["-"];
        const services = cert.scan_port?.service
          ? cert.scan_port?.service
          : ["-"];
        ips.forEach((ip, index) => {
          rows.push([ip, services[index], ...commonFields]);
        });
      }
    });
    return rows;
  };
  const handleTabChange = (tabValue) => {
    setActiveFilters([]);
    setSearchValue("");
    setSelectedTable(tabValue);
  };

  const barData = useMemo(
    () => CreateBarChartData(barChartType),
    [barChartType]
  );

  const horizontalBarChartData = useMemo(
    () => CreateHorizontalBarChartData(horizontalBarChartType),
    [horizontalBarChartType]
  );

  return (
    <>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      noDataHorizontalBarChart ? (
                        <NoDataAfterScanning />
                      ) : isDatainProcess ? (
                        <NoData />
                      ) : (
                        <HorizontalBarChart
                          HorizontalBarChartdata={horizontalBarChartData}
                          HorizontalBarChartoptions={horizontalBarChartOptions}
                          isChartLabelsOnTop={true}
                        />
                      )
                    }
                    title={"Certificate Security"}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100 ">
                    <div className="col-5">
                      <GenericCard
                        children={
                          <WebUpdate
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7">
                      <GenericCard
                        children={
                          noDataBarChart ? (
                            <NoDataAfterScanning />
                          ) : isDatainProcess ? (
                            <NoData />
                          ) : (
                            <BarChart
                              barData={barData}
                              optionsData={barChartOptions}
                              isChartLabelsOnTop
                              isData={
                                !!(
                                  barChartType &&
                                  barChartType.no !== undefined &&
                                  barChartType.yes !== undefined
                                )
                              }
                            />
                          )
                        }
                        title={"Certificate Expiration"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Certificates Scanner"} />
            </div>
          </div>

          <div className="pt-3 pb-5">
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center  justify-content-center">
                <span style={{ width: "85px" }}>View By :</span>
                <ButtonGroup aria-label="Basic example" className="rounded-0 ">
                  <Button
                    style={{ width: "185px" }}
                    variant={selectedTable === "web" ? "primary" : "#0a0a0e"}
                    onClick={() => handleTabChange("web")}
                    className="rounded-end rounded-r-0 px-4 rounded-1 btn-outline-primary "
                  >
                    Web Certificates
                  </Button>
                  <Button
                    style={{ width: "220px" }}
                    variant={
                      selectedTable === "network" ? "primary" : "#0a0a0e"
                    }
                    onClick={() => handleTabChange("network")}
                    className="rounded-start px-4 rounded-l-0 rounded-1 btn-outline-primary"
                  >
                    Network Certificates
                  </Button>
                </ButtonGroup>
              </div>
            </div>

            <div className="">
              <BaseFilter
                tabs={tabs}
                onFilterChangeWaf={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                iskeyfilter={true}
                totalRecords={filteredCertificates.length}
                exportHeader={getExportHeaders}
                exportTitle={`Certificates_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportRows={getExportRows()}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
              />
            </div>
            <div>
              <div className="certificate-table">
                <BaseTable
                  columns={getColumns()}
                  data={filteredCertificates}
                  selectable={true}
                  showCheckboxes={false}
                  isDatainProcess={isDatainProcess}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Certificates;
