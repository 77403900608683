import React, { useEffect, useState } from "react";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Blank } from "../../assets/images/blank-cyber-services.svg";
import BaseInput from "../../components/form/BaseInput";
import { Link } from "react-router-dom";
import CyberServicesCard from "../../components/cyber-services-card/CyberServicesCard";
import axios from "../../util/axios";
import { Dropdown } from 'react-bootstrap';
import { ThreeCircles } from 'react-loader-spinner'


const CyberServices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [servicesData, setServicesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("New-Old"); 


  const fetchCyberServices = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`cyber-services`);
      console.log("data-dat", data);
      setServicesData(data?.items);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCyberServices();
  }, []);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  const sortedAndFilteredServices = () => {
    return servicesData
      .filter(service =>
        service.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const dateA = new Date(a.submit_date);
        const dateB = new Date(b.submit_date);
        if (sortOrder === "New-Old") {
          return dateB - dateA;
        } else if (sortOrder === "Old-New") {
          return dateA - dateB;
        } else {
          return 0; 
        }
      });
  };
  
  const renderedServices = sortedAndFilteredServices();


  if (isLoading || servicesData.length === 0 ) {

    return (
    <React.Fragment>
      <div className="main_container_style cyber-blank-page">
      
        {isLoading ?
        <div className="content-loader">
          <ThreeCircles visible={true} height="60" width="60" color="#ffff" ariaLabel="three-circles-loading"
            wrapperClass="" />
        </div> :
        <>
          <Blank />
          <p className="fs-6 mt-2 text-white">
            {" "}
            You don't have any reports yet{" "}
          </p>
    
          <Link className="btn btn-primary mt-1 cyber-button" to="/contact">
          Contact us to Add Your First One
          </Link>
        </>}
      </div>
    </React.Fragment>
    );
    }
  return (
    <React.Fragment>
      <div className="main_container_style">
        <div className="w-100">
          <div className="table-container mb-5">
            <div>
              <BaseInput
                type="text"
                placeholder="Search..."
                className="search-input"
                icon={Search}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <Link
              className="text-size text-center btn-primary btn w-25 h-100 d-flex align-items-center justify-content-center"
              to="/contact"
            >
              Want a New Cyber Service? Contact Us
            </Link>
          </div>
          <div className="">
            <div className="">
              <div className=" d-flex align-items-center">
                <span className="fw-bold me-2">{renderedServices.length} Results</span> |
                <span className="mx-2">Sort by:</span>
                <Dropdown autoClose="inside " className="bg-transparent w-auto">
                <Dropdown.Toggle id="dropdown-autoclose-inside-" className="dropdown-toggle-button bg-transparent new-old ">
                  {sortOrder}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className="newold-btn" onClick={() => handleSortChange("New-Old")}>New-Old</Dropdown.Item>
                  <Dropdown.Item className="newold-btn" onClick={() => handleSortChange("Old-New")}>Old-New</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
            
              
            </div>
            </div>
          {renderedServices.map((service) => (
            <>
              <CyberServicesCard
            data={service}
              classNameVariant={
                service.risk_score >= 80
                  ? "primary"
                  : service.risk_score >= 40 && service.risk_score < 80
                  ? "secondary"
                  : "tertiary"
              }
            
            />
            </>
          
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CyberServices;
