import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import IconBox from "../../components/IconBox";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IconPhone } from '../../assets/images/icon-phone.svg';
import Image from "react-bootstrap/esm/Image";
import DialPad from "../../components/dialpad/Index";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import axios from "../../util/axios";

// list devices
const listDevices = async () => {


	await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
	const devices = await navigator.mediaDevices?.enumerateDevices?.();

	console.log("my devices", devices);
	devices.forEach((device) => {
		console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
	});
	if (devices) {
		const video = [];
		const speaker = [];
		const mic = [];
		for (const device of devices) {
			// if ('communications' == device.deviceId || 'default' == device.deviceId) continue;
			// if ('communications' == device.deviceId) continue;
			switch (device.kind) {
				case "videoinput":
					video.push(device);
					break;
				case "audioinput":
					mic.push(device);
					break;
				case "audiooutput":
					speaker.push(device);
					break;
			}
		}

		return { video, mic, speaker };
	} else {
		throw new Error("No support for multimedia devices.");
	}
};
const SpoofCall = () => {
	const params = new URLSearchParams(window.location.search) // id=123

	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	const [dialpad, setDialpad] = useState("");
	const [cid, setCid] = useState(params.has('cid') ? params.get('cid') : user.phone);
	const [isLoading, setIsLoading] = useState(false);
	const [to, setTo] = useState(params.has('to') ? params.get('to') : "");
	// const [spoofId, setSpoofId] = useState(null);
	const [devices, setDevices] = useState(null);
	const [isCalling, setIsCalling] = useState(false);
	const [error, setError] = React.useState(null);


	const fetchDetails = async () => {
		if (params.has("id")) {
			let id = params.get("id");
			setIsLoading(true);
			try {
				const { data } = await axios.get("/spoof/" + id);
				console.log("my data", data);
				setCid(data.from);
				setTo(data.to);
				// initialValues.to = data.to;

				setIsLoading(false);
			} catch (err) {
				setIsLoading(false);
				console.log(err);
				toast.error("Unable to fetch details", {
					toastId: "error1",
					position: "bottom-right",
					autoClose: 1500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
			}
		}
	};

	const init = async () => {
		await fetchDetails();
	};
	useEffect(() => {
		//passing getData method to the lifecycle method
		init();
	}, []);

	React.useEffect(() => {
		const promise = listDevices(); // <--- lists available input audio and input video devices
		promise
			.then((devices) => setDevices(devices))
			.catch((error) => { setError(error) });
	}, []);


	const getPremissions = () => {
		return new Promise((resolve, rej) => {
			navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then(stream => {
					console.log('stream', stream)
					this.stream = stream;
					resolve(stream);
				})
				.catch(err => {
					toast.error("Unable to fetch stream " + err, {
						position: "bottom-right",
						autoClose: 1500,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "dark",
					});
					throw new Error("Unable to fetch stream " + err);
				});
		});
	}

	const bookIt = async (values) => {


		let response = await axios.post("/spoof", values);
		console.log('response', response)
		return response.data;


	};
	const getEventHandlers = (spoofId) => {
		// Register callbacks to desired call events
		var eventHandlers = {
			'progress': function (e) {
				toast.success('call is in progress', {
					position: "bottom-right",
					autoClose: 1500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});


			},
			'failed': function (e) {
				dialpad.endCall();
				setIsCalling(false);
				console.log('call failed', e, spoofId);
				if (spoofId) {
					let info = {
						id: spoofId,
						status: 4
					};
					bookIt(info);

				}
				toast.error('call failed with cause: ' + e?.cause, {
					position: "bottom-right",
					autoClose: 1500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});

			},
			'ended': function (e) {

				// here you need to close the modal !
				dialpad.endCall();
				setIsCalling(false);
				if (spoofId) {
					let info = {
						id: spoofId,
						status: 3
					};
					bookIt(info);

				}
				toast.error('call ended with cause: ' + e?.cause, {
					position: "bottom-right",
					autoClose: 1500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
				console.log('call ended with cause: ', e);
			},
			'confirmed': function (e) {
				dialpad.setOnCall();
				if (spoofId) {
					let info = {
						id: spoofId,
						status: 2
					};
					bookIt(info);

				}
				console.log('call confirmed', e);
				toast.success('call is in confirmed', {
					position: "bottom-right",
					autoClose: 1500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
				});
			}
		};
		return eventHandlers;
	}



	const registerCallEvents = (call) => {
		call.on('stream', (remoteStream) => {
			dialpad.setOnCall();
		});
		call.on('close', () => {
			dialpad.endCall();
		});
	}

	const onStateChanged = (state) => {
		console.log("to state", state);
	};
	const onCallStarted = async (number) => {



		let info = {
			to: number,
			from: cid,
			type: 'phone',
			status: 1
		}
		let spoofInfo = await bookIt(info);
		setIsCalling(true);
		// await setSpoofId(spoofInfo.id);
		if (devices?.speaker.length > 0)
			dialpad.attachSinkId(devices.speaker[0].deviceId)
		dialpad.dial(number, cid, getEventHandlers(spoofInfo.id));
		dialpad.setRinging();

	};



	const onCallRejected = () => {
		console.log('stopping dialer')

		dialpad.endCall();

	}

	const onCallReplied = () => {
		this.getUserMedia().then((stream) => {
			this.call.answer(stream); // Answer the call with an A/V stream.
			this.registerCallEvents(this.call);
		}).catch((err) => {
			console.log('Failed to get local stream', err);
		});
	}




	const onCallEnded = () => {
		if (dialpad.session) {
			dialpad.session.terminate();

		}
		dialpad.endCall();
	};
	const onActionInvoked = async (action, data) => {

		switch (action) {
			case "CALL_STARTED":
				await onCallStarted(data.number);

				break;
			case "CALL_ENDED":
				onCallEnded();

				break;
			case "CALL_REPLIED":
				this.onCallReplied();
				break;
			case "CALL_REJECTED":
				this.onCallRejected();
				break;
			default:
				break;
		}
	};
	const style = {
		// margin: "0 auto",
		// borderRadius: "20px",
	};

	return (
		<React.Fragment>

			<h1 className="fw-normal mb-5">
				<Button variant="secondary" onClick={() => navigate('/spoof')} className="px-2 rounded-1 me-3"><i className="bi bi-chevron-left text-white fs-4"></i></Button><IconPhone width="35" className="me-3" />Call  Spoof
			</h1>
			<Stack gap={4}>

				<div disabled={isCalling || isLoading}>
					<h3 className="mb-2">From</h3>
					<PhoneInput
						placeholder={"Please Enter Caller ID"}
						name="cid"
						type="text"
						alwaysDefaultMask={true}
						defaultMask=".. ........."
						defaultCountry="NO"
						value={cid}
						onChange={(value) => {
							console.log('change...', value)
							setCid(value);
						}}
					/>
				</div>
				<div>
					<h3 className="mb-2">To</h3>
					<DialPad
						style={style}
						number={to}
						ref={(dialpad) => setDialpad(dialpad)}
						onActionInvoked={onActionInvoked}
						onStateChanged={onStateChanged}
					/>
				</div>
			</Stack>





		</React.Fragment >
	);
};

export default SpoofCall;
