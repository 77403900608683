export function formatScannerName(name) {
  // Replace delimiters with a space
  let formattedName = name?.replace(/[-&,.]/g, " ");

  // Remove extra spaces
  formattedName = formattedName?.replace(/\s+/g, " ")?.trim();

  // Convert to camel case
  formattedName = formattedName
    ?.split(" ")
    ?.map((word) => {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
    })
    ?.join("");

  return formattedName;
}
