import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import BaseInput from "../../../../components/form/BaseInput";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import WebUpdate from "../../../../components/website-updates/WebUpdate";
import { ReactComponent as Search } from "../../../../assets/images/search.svg";
import CircularChart from "../../../../components/charts/CircularChart";
import GenericCard from "../../../../components/card/GenericCard";
import { ThreeCircles } from "react-loader-spinner";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import NoData from "../../../../components/empty/NoData";
import { useParams, useOutletContext } from "react-router-dom";
import axios from "../../../../util/axios";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import WebUpdatesHorizontal from "../../../../components/website-updates/WebUpdatesHorizontal.js";
import { webUpdatesFilter } from "../../../../util/genericFunctions.js";

const EmailSecurity = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [noDataDoughnutChart, setNoDataDoughtnutChart] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(null);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [tabs, setTabs] = useState([
    {
      eventKey: "1",
      title: "Domains",
      contentTitle: "Domains",
      data: [],
    },
  ]);
  const [allEmails, setAllEmails] = useState([]);
  const [chartData, setChartData] = useState({
    labels: ["Secured", "Unsecured"],
    datasets: [
      {
        data: [],
        backgroundColor: ["#3DDC97", "#FF6155"],
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
        borderAlign: "center",
      },
    ],
  });

  const CircularChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Secure vs Unsecured Email Configuration",
        color: "white",
        font: {
          family: "Kodchasan",
          size: 24,
        },
      },
    },
  };

  const columns = useMemo(
    () => [
      { Header: "Domain", accessor: "host", isSortable: true },
      {
        Header: "SPF",
        isSortable: true,
        key: "is_spf_secured",
        accessor: (row) => {
          const popover = row.spf_results_raw && (
            <Popover
              id="popover-basic-comming"
              className="custom-popover-arrow"
            >
              <Popover.Body className="email-tool-body scrollable-checkbox-list ">
                {row.spf_results_raw.split(/;|\s/).map((item, index) => (
                  <span key={index} className="text-black d-block">
                    {item.trim()}
                  </span>
                ))}
              </Popover.Body>
            </Popover>
          );
          const spfStatus = row.is_spf_secured.replace(
            /Using multiple spf Records/gi,
            "Using Multiple Records"
          );

          return (
            <div>
              {popover ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={popover}
                >
                  <div style={{ display: "inline-block" }}>
                    <DecisionToggle
                      label={spfStatus}
                      isIndication={
                        row.is_spf_secured === "Securely Configured"
                      }
                    />
                  </div>
                </OverlayTrigger>
              ) : (
                <div style={{ display: "inline-block" }}>
                  <DecisionToggle
                    label={spfStatus}
                    isIndication={row.is_spf_secured === "Securely Configured"}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "DMARC",
        isSortable: true,
        key: "is_dmarc_secured",
        accessor: (row) => {
          const boxLabel =
            row.is_dmarc_secured === "p=none"
              ? "Too Lenient Policy"
              : row.is_dmarc_secured;

          const popover = row.dmarc_results_raw && (
            <Popover
              id="popover-basic-comming"
              className="custom-popover-arrow"
            >
              <Popover.Body className="email-tool-body scrollable-checkbox-list">
                {row.dmarc_results_raw.split(/;|\s/).map((item, index) => (
                  <span key={index} className="text-black d-block">
                    {item.trim()}
                  </span>
                ))}
              </Popover.Body>
            </Popover>
          );

          return (
            <div>
              {popover ? (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={popover}
                >
                  <div style={{ display: "inline-block" }}>
                    <DecisionToggle
                      label={boxLabel}
                      isIndication={
                        row.is_dmarc_secured === "Securely Configured"
                      }
                    />
                  </div>
                </OverlayTrigger>
              ) : (
                <div style={{ display: "inline-block" }}>
                  <DecisionToggle
                    label={row.is_dmarc_secured}
                    isIndication={
                      row.is_dmarc_secured === "Securely Configured"
                    }
                  />
                </div>
              )}
            </div>
          );
        },
      },
      // {
      //   Header: "DMARC",
      //   isSortable: true,
      //   key: "is_dmarc_secured",
      //   accessor: (row) => (
      //     <div>
      //       <DecisionToggle
      //         label={row.is_dmarc_secured}
      //         isIndication={row.is_dmarc_secured === "Securely Configured"}
      //       />
      //     </div>
      //   ),
      // },
      {
        Header: "Secure vs Unsecured",
        isSortable: true,
        key: "secureStatus",
        accessor: (row) => {
          const isSPFSecured = row.is_spf_secured === "Securely Configured";
          const isDMARCSecured = row.is_dmarc_secured === "Securely Configured";
          const isStatusSecure = isSPFSecured && isDMARCSecured;
          const statusLabel = isStatusSecure ? "Secured" : "Unsecured";

          return (
            <div>
              <DecisionToggle
                label={statusLabel}
                isIndication={isStatusSecure}
              />
            </div>
          );
        },
      },
      {
        Header: "First Detected",
        key: "first_detected",
        isSortable: true,
        accessor: (row) => {
          return (
            <div>
              {row?.first_detected
                ? moment(row?.first_detected).format("DD/MM/YY")
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Current State",
        key: "current_state",
        isSortable: true,
        accessor: (row) => {
          return (
            <div>
              {row?.change_status != null ? (
                <CurrentStatus
                  status={row?.change_status}
                  tooltipInfo={row?.changes}
                  headerKeys={{
                    host: "Domain",
                    is_spf_secured: "SPF",
                    is_dmarc_secured: "DMARC",
                    secureStatus: "Secure vs Unsecured",
                    first_detected: "First Detected",
                    change_status: "Current State",
                  }}
                />
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const sampleData = [
    { id: 1, type: "upload" },
    { id: 2, type: "upload" },
    { id: 3, type: "removal" },
    { id: 4, type: "removal" },
    { id: 5, type: "upload" },
  ];

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const updateTabsWithData = (emails) => {
    const domains = Array.from(
      new Set(emails.map((domain) => domain.host))
    ).map((domain, index) => ({
      id: index,
      name: domain,
      type: "Domain: " + domain,
      key: "1",
      active: false,
    }));

    setTabs([
      {
        eventKey: "1",
        title: "Domains",
        contentTitle: "Domains",
        data: Array.from(domains),
      },
    ]);
  };

  const fetchEmailSecurity = async () => {
    try {
      const { data } = await axios.get(
        `scans/${routeParams.target_id}/email-security`
      );
      setIsLoading(false);
      setWebUpdatesData(data?.groups?.changes);
      setAllEmails(
        data?.domains?.map((el) => {
          let spfMessage = el?.domainInfo?.is_spf_secured
            ? "Securely Configured"
            : el?.domainInfo?.spf_results?.reason?.length > 0
            ? el?.domainInfo?.spf_results?.reason[0]
            : "No SPF Record Found";
          let dmarcMessage = el?.domainInfo?.is_dmarc_secured
            ? "Securely Configured"
            : el?.domainInfo?.dmarc_results?.reason?.length > 0
            ? el?.domainInfo?.dmarc_results?.reason[0]
            : "No Record Found";
          const isSecure =
            el?.domainInfo?.is_spf_secured && el?.domainInfo?.is_dmarc_secured;
          return {
            ...el,
            is_spf_secured: spfMessage,
            spf_results_raw: el?.domainInfo?.spf_results?.raw,
            dmarc_results_raw: el?.domainInfo?.dmarc_results?.raw,
            is_dmarc_secured: dmarcMessage,
            status: el?.domainInfo?.spf_results?.status,
            secureStatus: isSecure ? "Secured" : "Unsecured",
            change_status: el?.domainInfo?.mx_change_status,
            changes: el?.domainInfo?.mx_changes,
            first_detected: el?.domainInfo?.first_detected,
          };
        })
      );
      setChartData({
        labels: ["Secured", "Unsecured"],
        datasets: [
          {
            data: [data.groups?.secure?.yes, data.groups?.secure?.no],
            backgroundColor: ["#3DDC97", "#FF6155"],
            hoverOffset: 4,
            borderColor: "transparent",
            borderWidth: 0,
            borderAlign: "center",
          },
        ],
      });
      if (scanningStatus === -1 || scanningStatus === 3) {
        if (data.groups?.secure?.yes || data.groups?.secure?.no) {
          setNoDataDoughtnutChart(false);
        } else {
          setNoDataDoughtnutChart(true);
        }
      }
      updateTabsWithData(data?.domains);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (scanningStatus === 3 || scanningStatus === -1) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchEmailSecurity();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchEmailSecurity();
        fetchIntervalRef.current = setInterval(fetchEmailSecurity, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const filteredEmails = useMemo(() => {
    return allEmails.filter((item) => {
      const matchesFilters =
        activeFilters.length === 0 ||
        activeFilters.some(
          (filter) =>
            (filter.eventKey === "1" &&
              item.host?.toLowerCase() === filter.name.toLowerCase()) ||
            (filter.eventKey === "6" &&
              item?.change_status === statusMap[filter.name.toLowerCase()])
        );
      const matchesSearch =
        searchValue === "" ||
        item.host?.toLowerCase().includes(searchValue.toLowerCase());
      return matchesFilters && matchesSearch;
    });
  }, [allEmails, activeFilters, searchValue]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      noDataDoughnutChart ? (
                        <NoDataAfterScanning />
                      ) : isDatainProcess ? (
                        <NoData />
                      ) : (
                        <DoughnutChart
                          data={chartData}
                          options={CircularChartoptions}
                        />
                      )
                    }
                    title={"Secured vs Unsecured Email Configuration"}
                    isCentered={false}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-12">
                      <GenericCard
                        children={
                          <WebUpdatesHorizontal
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                          />
                        }
                        title={"Updates"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Email Security Scanner"} />
            </div>
          </div>

          <div className="pb-5">
            {/* <div className="table-container">
              <div className="table-left">Results</div>
              <div>
                <BaseInput
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  icon={Search}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
            <div>
              <BaseFilter
                tabs={tabs}
                className="mt-3"
                onFilterChangeWaf={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                iskeyfilter={true}
                totalRecords={allEmails.length}
                exportTitle={`EmailSecurity_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportHeader={[
                  "Domain",
                  "SPF",
                  "DMARC",
                  "Secured VS Unsecured",
                ]}
                exportRows={filteredEmails.map((email) => {
                  const isSPFSecured =
                    email.is_spf_secured === "Securely Configured";
                  const isDMARCSecured =
                    email.is_dmarc_secured === "Securely Configured";
                  const isStatusSecure = isSPFSecured && isDMARCSecured;
                  const statusLabel = isStatusSecure ? "Secured" : "Unsecured";

                  return [
                    email.host,
                    email.is_spf_secured.replace(/\bspf \b/gi, ""),
                    email.is_dmarc_secured,
                    statusLabel,
                  ];
                })}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredEmails}
                selectable={true}
                showCheckboxes={false}
                action={false}
                isDatainProcess={isDatainProcess}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EmailSecurity;
