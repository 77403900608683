export const detectCountry = async () => {
  const res = await fetch("https://api.db-ip.com/v2/free/self");
  const data = await res.json();
  if (data) {
    console.log("res", data);
    console.log("i am here");
    return data.countryCode;
  }
};
export const detectLocation = async () => {
  const res = await fetch("https://freeipapi.com/api/json");
  const data = await res.json();
  if (data) {
    console.log("res", data);
    console.log("i am here");
    return { lat: data.latitude, lng: data.longitude };
  }
};

export const ipToLocation = async (ip) => {
  return fetch(`https://freeipapi.com/api/json/${ip}`);
};
