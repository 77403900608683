import { useState } from "react";
import Figure from "react-bootstrap/Figure";

function IconBox({ icon, title, type = "public" }) {
  const [path, setPath] = useState("/images/" + icon);

  if ("assets" === type) {
    import("../assets/images/" + icon).then((image) => {
      setPath(image.default);
    });
  }
  return (
    <Figure>
      <Figure.Image width={171} height={180} alt="171x180" src={path} />
      <Figure.Caption>{title}</Figure.Caption>
    </Figure>
  );
}

export default IconBox;
