import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import Linker from '../../../components/Linker';
import { useConfirmationModalContext } from '../../../components/ModalConfirmationContext';
import { toast } from 'react-toastify';
import axios from '../../../util/axios';
import { FallingLines } from 'react-loader-spinner';
import {decodeHTML} from 'entities';

const NewSingle = ({ item, fetchItems }) => {
    const [loading, setLoading] = useState(false);
    let modalContext = useConfirmationModalContext();
    const handleSubmit = (values) => {


        setLoading(true);
        axios
            .post("/admin/news/decide", values)
            .then((response) => {

                const data = response.data;
                setLoading(false);
                if (data.msg) {
                    fetchItems();
                    let tostr = 1 == values.mod ? toast.success : toast.error;
                    tostr(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });

                }

            })
            .catch((e) => {
                setLoading(false);
                if (e.response.status === 422) {
                    for (const fieldKey in e.response.data) {
                        const field = e.response.data[fieldKey];
                        toast.error(field.msg, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                        // helpers.setFieldError(field.path, field.msg);
                    }
                }
            });
    };
    const handleOnClick = async (mod) => {
        let title = 'Approval Confirmation!';
        let msg = 'Are you sure, want to approve this news?';
        let icon = 'accept';
        if (2 == mod) {
            title = 'Rejection Confirmation!';
            msg = 'Are you sure, want to reject this news?';
            icon = 'reject';
        }
        const result = await modalContext.showConfirmation(
            title,
            (<div className='p-3'>
                <p>
                    {msg}

                </p>
            </div>),
            icon
        );
        if (result) {
            handleSubmit({ id: item.id, mod });
        }
    };

    return (
        <Row className='news-item rounded m-0 my-3 mb-4  rounded shadow-sm' >
            <Col className='d-flex flex-md-row flex-column  p-0'>
                {loading && <div className="p-2 flex-grow-1" style={{ position: 'absolute' }}>
                    <FallingLines
                        height="100"
                        width="100"
                        color="#fff"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{ justifyContent: 'center' }}
                        wrapperClassName=""
                        visible={loading}
                    />
                </div>}
                <div className="p-2 flex-grow-1" style={{ opacity: (loading ? 0.1 : 1) }}>
                    <a href={item.permalink} rel="noreferrer" target='_blank'>
                        <h2>{decodeHTML(item.title)}</h2>
                    </a>
                    <p className='py-2 m-0'>
                        {decodeHTML(item.open_text)}
                    </p>
                    <div className='mt-2'>
                        <i className="bi bi-calendar3 me-2" title='Published Date'></i>
                        <small className="text-muted">
                            <Moment format="MMM DD, YYYY">
                                {item.published_at}
                            </Moment>
                        </small>

                        <i className="bi bi-lightbulb mx-2" title='Source'></i>
                        <small className="text-muted">
                            {item.feed.title}
                        </small>
                        <div>{item.audio_url}</div>
                    </div>
                </div>
                <div style={{ opacity: (loading ? 0.1 : 1) }} className='bg-primary d-flex flex-md-column flex-row justify-content-center'>
                    <Linker
                        title="Approve this News" className="m-2"
                        onClick={() => { handleOnClick(1) }}
                    ><img
                            alt=""
                            src="/images/icon-mini-accept.png"


                        /></Linker>
                    <Linker

                        title="Reject this News" className="m-2"
                        onClick={() => { handleOnClick(2) }}
                    >
                        <img
                            alt=""
                            src="/images/icon-mini-reject.png"


                        /></Linker>
                    <Linker
                        to={"/admin/news/edit/" + item.id}
                        title="Edit this news"
                        className="m-2"
                    ><img
                            alt=""
                            src="/images/icon-mini-edit.png"


                        />
                    </Linker>
                </div>
            </Col>
        </Row>)
};

export default NewSingle;