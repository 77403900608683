import { useField } from 'formik';
import React from 'react';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
const PhoneField = ({ country, label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <PhoneInput
        country={country ? country.toLowerCase() : ''}
        placeholder={label}
        {...props}
        {...field}
        alwaysDefaultMask={true}
        defaultMask=".. ........."
        value={field.value}
        defaultCountry="NO"
        onChange={(value) => {
          // console.log('change...', value)
          helpers.setValue(value);
        }}
        className={
          meta.touched && meta.error ? "is-invalid" : ""
        }
        
      />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback ">{meta.error}</div>
      ) : null}
    </>
  );
};

export default PhoneField;