import React from "react";
import { ReactComponent as LinkHttp } from "../../assets/images/link-http.svg";

const WebPortsTemplate = ({ rowData, cellData, hosts, showAllIds }) => {
  return (
    <>
      {Array.isArray(hosts) && hosts.length > 0 ? (
        hosts.slice(0, showAllIds?.includes(rowData.id) ? hosts.length : 4).map((item, index) => (
          <div key={index} className="my-2 d-flex align-items-center" style={{ height: '30px' }}>
            {cellData && cellData.indexOf('80') >= 0 && (
              <a className="me-3" href={`http://${item.host.host}`} target="_blank" rel="noopener noreferrer">
                <div className="d-flex align-items-center">
                  <span className="hover-none me-2 text-link">HTTP</span>
                  <LinkHttp />
                </div>
              </a>
            )}
            {cellData && cellData.indexOf('443') >= 0 && (
              <a href={`https://${item.host.host}`} target="_blank" rel="noopener noreferrer">
                <div className="d-flex align-items-center">
                  <span className="hover-none me-2 text-link">HTTPS</span>
                  <LinkHttp />
                </div>
              </a>
            )}
          </div>
        ))
      ) : (
        <>
          <div className="my-2 d-flex align-items-center" style={{ height: '30px' }}>
            {cellData && cellData.indexOf('80') >= 0 && (
              <a className="me-3" href={`http://${rowData?.ip?.ip}`} target="_blank" rel="noopener noreferrer">
                <div className="d-flex align-items-center">
                  <span className="hover-none me-2 text-link">HTTP</span>
                  <LinkHttp />
                </div>
              </a>
            )}
            {cellData && cellData.indexOf('443') >= 0 && (
              <a href={`https://${rowData.ip.ip}`} target="_blank" rel="noopener noreferrer">
                <div className="d-flex align-items-center">
                  <span className="hover-none me-2 text-link">HTTPS</span>
                  <LinkHttp />
                </div>
              </a>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default WebPortsTemplate;
