
/**
 * Dependencies
 * @ignore
 */
import React, { useState } from 'react'
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import Client from 'webauthn/client'

/**
 * Module Dependencies
 * @ignore
 */

/**
 * Login
 * @ignore
 */
function Login(props) {
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [webauthn] = useState(new Client({ pathPrefix: 'https://app.cisotronix.com/webauthn' }))

  function onRegister() {
    const createCredentialDefaultArgs = {
      publicKey: {
        // Relying Party (a.k.a. - Service):
        rp: {
          name: "Acme",
        },
        // User:
        user: {
          id: new Uint8Array(16),
          name: "carina.p.anand@example.com",
          displayName: "Carina P. Anand",
        },
        pubKeyCredParams: [
          {
            type: "public-key",
            alg: -7,
          },
        ],
        attestation: "direct",
        timeout: 60000,
        challenge: new Uint8Array([
          // must be a cryptographically random number sent from a server
          0x8c, 0x0a, 0x26, 0xff, 0x22, 0x91, 0xc1, 0xe9, 0xb9, 0x4e, 0x2e, 0x17, 0x1a,
          0x98, 0x6a, 0x73, 0x71, 0x9d, 0x43, 0x48, 0xd5, 0xa7, 0x6a, 0x15, 0x7e, 0x38,
          0x94, 0x52, 0x77, 0x97, 0x0f, 0xef,
        ]).buffer,
      },
    };

    // sample arguments for login
    const getCredentialDefaultArgs = {
      publicKey: {
        timeout: 60000,
        // allowCredentials: [newCredential] // see below
        challenge: new Uint8Array([
          // must be a cryptographically random number sent from a server
          0x79, 0x50, 0x68, 0x71, 0xda, 0xee, 0xee, 0xb9, 0x94, 0xc3, 0xc2, 0x15, 0x67,
          0x65, 0x26, 0x22, 0xe3, 0xf3, 0xab, 0x3b, 0x78, 0x2e, 0xd5, 0x6f, 0x81, 0x26,
          0xe2, 0xa6, 0x01, 0x7d, 0x74, 0x50,
        ]).buffer,
      },
    };

    // register / create a new credential
    navigator.credentials
      .create(createCredentialDefaultArgs)
      .then((cred) => {
        console.log("NEW CREDENTIAL", cred);
        // normally the credential IDs available for an account would come from a server
        // but we can just copy them from above…
        const idList = [
          {
            id: cred.rawId,
            transports: ["internal"],
            type: "public-key",
          },
        ];
        getCredentialDefaultArgs.publicKey.allowCredentials = idList;
        return navigator.credentials.get(getCredentialDefaultArgs);
      })
      .then((assertion) => {
        console.log("ASSERTION", assertion);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
    if (username === "") {
      setError('Please enter a username')
      return
    }
    webauthn.register({ name, username }).then(response => {
      console.log('Register response: ', response)
      setSuccess('Registration successful. Try logging in.')
    }).catch(error => {
      setError(error.message)
    })
  }

  function onLogin() {
    if (username === "") {
      setError('Please enter a username')
      return
    }
    webauthn.login({ username }).then(response => {
      console.log('Login response: ', response);
      if (response && response.status === "ok")
        props.onLogin({
          username,
        });
    }).catch(error => setError(error.message))
  }

  return (
    <Container>
      <Row className='login-padding'>
        <Col>
          {error && <Alert variant="danger" dismissible onClose={() => setError('')}>
            {error}
          </Alert>}
          {success && <Alert variant="success" dismissible onClose={() => setSuccess('')}>
            {success}
          </Alert>}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Register</h3>
          <Form>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={e => setUsername(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              ></Form.Control>
              <Form.Text className="text-muted">This name will be displayed publicly.</Form.Text>
            </Form.Group>
            <Button variant="primary" onClick={onRegister}>
              Register
            </Button>
          </Form>
        </Col>
        <Col>
          <h3>Login</h3>
          <Form>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" value={username} onChange={e => setUsername(e.target.value)}></Form.Control>
            </Form.Group>
            <Button variant="primary" onClick={onLogin}>
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

/**
 * Exports
 * @ignore
 */
export default Login;