import Carousel from 'react-bootstrap/Carousel';
import NewsItem from './NewsItem';
import { Card, ProgressBar } from 'react-bootstrap';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from "../../util/axios";
import { Triangle } from 'react-loader-spinner';


function NewsSlider() {

    const [index, setIndex] = useState(0);
    // number displayed by component
    const [count, setCount] = useState(0)
    const [items, setItems] = useState([])

    const [showBar, setShowBar] = useState(false);
    const [paused, setPaused] = useState(null);
    const [intervalId, setIntervalId] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const duration = 10;
    const intervalRef = useRef();
    const end = 100;
    const navigate = useNavigate();


    async function fetchNews(page = 1, pageSize = 20) {
        try {
            let res = await axios.get('news', { params: { page: page, pageSize: pageSize, imageOnly:true } });
            if(res.data?.items){
                setItems(res.data.items)
            }
            
    
        } catch (error) {
            console.log(error);
            throw error;
        }
    
    }

    const init = async () => {
        setIsLoading(true);
        await fetchNews();
        setIsLoading(false);
    }
    useEffect(() => {
        init();
    },[])
    useEffect(() => {
        
        let start = count || 0;
        // find duration per increment
        let totalMilSecDur = parseInt(duration);
        let incrementTime = (totalMilSecDur / end) * 1000;
        const id = setInterval(() => {
            // ...
            setShowBar(true);

            start += 1;
            setCount(start);


            if (start === end) {
                clearInterval(id);

                setTimeout(() => {

                    setIndex((index < items.length - 1 ? index + 1 : 0));

                    setCount(0);
                }, 500);
                setShowBar(false);
            }

        }, incrementTime);
        intervalRef.current = id;
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [index, duration, paused]);


    const shouldPause = useCallback((isPaused) => {
        if (intervalRef.current && isPaused) {
            clearInterval(intervalRef.current);
        }
        setPaused(isPaused);
    }, [intervalRef])

    return (
        <Card className="px-1 align-items-lg-stretch" onTouchStart={(e) => { shouldPause(true); }} onTouchEnd={(e) => { shouldPause(false); }} onMouseOver={(e) => { shouldPause(true); }} onMouseOut={(e) => { shouldPause(false); }} >
            <Card.Body>
                <Card.Title className="text-uppercase d-flex justify-content-between text-white p-1 pb-3" as="h3"  style={{cursor: 'pointer'}} onClick={()=>{navigate('/news')}} >AI Powered News <Link to="/news" ><i className="bi bi-chevron-right text-white "></i></Link></Card.Title>
                <div key="loader" className="loader">
                    <Triangle
                        height="80"
                        width="80"
                        color="#fff"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{ justifyContent: 'center' }}
                        wrapperClassName=""
                        visible={isLoading}
                    />
                </div>
                {!isLoading &&
                    <>
                        <Carousel pause="hover" onTouchStart={(e) => { shouldPause(true); }} onTouchEnd={(e) => { shouldPause(false); }} onMouseOver={(e) => { shouldPause(true); }} onMouseOut={(e) => { shouldPause(false); }} activeIndex={index} controls={false} indicators={false} onSlid={(e) => { console.log('e', e); console.log('counter', count) }}>
                            {items.map(item => (
                                <Carousel.Item key={item.id}>
                                    <NewsItem item={item} />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        {showBar && <ProgressBar className='mb-3' onMouseOver={(e) => { shouldPause(true); }} onMouseOut={(e) => { shouldPause(false); }} animated now={count} />}
                    </>
                }
            </Card.Body>

        </Card>
    );
}

export default NewsSlider;