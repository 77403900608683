import React from 'react';
import { Modal, Button } from 'react-bootstrap';
const BaseModal = ({
  showModal,
  onHide,
  onSave,
  title = "Are You Sure", 
  children, 
  cancelLabel = "Cancel", 
  confirmLabel = "Confirm" ,
  isClose=true,
  isCenter=true,
  size='sm'
}) => (
  <Modal  size={size} className='base-modal' show={showModal} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered={isCenter}>
    <Modal.Header closeButton={isClose}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
  </Modal>
);

export default BaseModal;
