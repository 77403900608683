import React, { useState, useEffect } from 'react';
import { Tab } from 'react-bootstrap';
import BaseInput from '../form/BaseInput';
import BaseCheckbox from '../form/BaseCheckbox';
import { ReactComponent as Search } from "../../assets/images/search.svg";

const TabPaneContent = ({ eventKey, title, data, onDomainChange }) => {
    const [searchValue, setSearchValue] = useState('');
    const [displayData, setDisplayData] = useState([]);
    const [checkedItems, setCheckedItems] = useState(new Map());
    const [initialized, setInitialized] = useState(new Set()); // Tracks which checkboxes have been initialized

    useEffect(() => {
        let newCheckedItems = new Map(checkedItems);
        let newInitialized = new Set(initialized);
        let shouldUpdate = false;
    
        data.forEach(item => {
            if (!newInitialized.has(item.name)) {
                newCheckedItems.set(item.name, item.active);
                newInitialized.add(item.name);
                shouldUpdate = true; // Mark for update if a new item is initialized
            } else if (item.active !== checkedItems.get(item.name) && !newInitialized.has(item.name)) {
                newCheckedItems.set(item.name, item.active);
                shouldUpdate = true; // Mark for update if the active state changed and it's not initialized
            }
        });
    
        if (shouldUpdate) {
            setCheckedItems(newCheckedItems);
            setInitialized(newInitialized);
        }
    
        // Update display data
        const filteredData = searchValue.trim() === '' ? data : data.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
        setDisplayData(filteredData);
    }, [searchValue, data]); // Remove checkedItems and initialized from dependencies
    

    const handleCheckboxChange = (item) => {
        const currentState = checkedItems.get(item.name);
        const newState = !currentState;
        setCheckedItems(new Map(checkedItems.set(item.name, newState)));
        onDomainChange(item, newState);
    };

    const clearAllCheckboxes = () => {
        let newCheckedItems = new Map();
        displayData.forEach(item => {
            newCheckedItems.set(item.name, false);
            onDomainChange(item, false, 'clear');
        });
        setCheckedItems(newCheckedItems);
    };

    return (
        <Tab.Pane eventKey={eventKey}>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <span className='tab-pane-title'>{title} {'(' + displayData.length + ')'} </span>
                <div>
                    <span className="cursor-pointer tab-pane-title" onClick={clearAllCheckboxes}>Clear All</span>
                </div>
            </div>
            <BaseInput
                type="text"
                placeholder="Search..."
                className="search-input"
                icon={Search}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
            />
            <div className='mt-2 scrollable-checkbox-list'>
                {displayData.map((item, index) => (
                    <div key={index} className='d-flex'>
                        <BaseCheckbox
                            type="checkbox"
                            name={`checkbox-${item.name}`}
                            label={item.name}
                            checked={checkedItems.get(item.name) || false}
                            onChange={() => handleCheckboxChange(item)}
                            className="mb-2 base-checkbox"
                        />
                     
                    </div>
                ))}
            </div>
        </Tab.Pane>
    );
};

export default TabPaneContent;
