import React, { useState, useCallback, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Organizations from "./organizations";
import OrganizationForm from "./OrganizationForm";
import axios from "../../../util/axios";
import InfiniteScroll from "react-infinite-scroller";
import { Triangle } from "react-loader-spinner";
import { Image } from "react-bootstrap";

async function fetchOrganizations(page, pageSize) {
  try {
    let res = await axios.get("organizations", {
      params: { page: page, pageSize: pageSize },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const OrganizationWrapper = ({ getAllOrganizations }) => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [fetching, setFetching] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const fetchItems = useCallback(async (page) => {
    if (fetching) {
      return;
    }

    setFetching(true);

    try {
      const data = await fetchOrganizations(page, pageSize);
      setItems((currentItems) => [...currentItems, ...data.items]);
      setTotalPages(data.totalPages);
      setCurrentPage(page);
    } finally {
      setFetching(false);
    }
  }, [fetching, pageSize]);

  useEffect(() => {
    fetchItems(1); // Load the first page on component mount
  }, []);

  const handlePageChange = (pageNumber) => {
    fetchItems(pageNumber);
  };

  const loader = (
    <div key="loader" className="loader">
      <Triangle
        height="80"
        width="80"
        color="#fff"
        ariaLabel="triangle-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );

  return (
    <Container className="main_container_style">
      <Row>
        <Col sm="12" lg="8">
        <h1 className="fw-normal mb-5">
        <Image src="/images/icon-invitation.png" width={50} rounded /> Organizations
      </h1>
          <div className="scroll-container">
            <InfiniteScroll
              pageStart={0}
              loadMore={() => fetchItems(currentPage + 1)}
              hasMore={currentPage < totalPages}
              loader={loader}
              useWindow={false}
            >
              <Organizations
                items={items}
                setItems={setItems}
                total={totalPages}
                current={currentPage}
                setCurrent={(page) => {
                  handlePageChange(page);
                }}
              />
            </InfiniteScroll>
          </div>
        </Col>
        <Col sm="12" lg="4">
          <OrganizationForm onOrganizationAdded={fetchItems} />
        </Col>
      </Row>
    </Container>
  );
};

export default OrganizationWrapper;
