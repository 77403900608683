import React, { Component } from 'react';

class Timer extends Component {
  constructor() {
    super();
  }
  prependZero(time, length) {
    time = new String(time);    // stringify time
    return new Array(Math.max(length - time.length + 1, 0)).join("0") + time;
  }

  defineTime(time){
    let min = Math.floor(time/60);
    let sec = Math.floor(time%60);
    return this.prependZero(min,2) + ':'+ this.prependZero(sec,2); 
  }

  render() {
    const { timer } = this.props;

    return (
      <div className="timer my-4">
        {this.defineTime(timer)}
      </div>
    );
  }
}

export default Timer;
