import React from 'react';
import './StatsCard.scss';

const StatsCard = ({ title, value, isCentered, valueColor }) => {
  const textClass = `statsCard_text ${isCentered ? 'statsCard_text_center' : ''}`;
  const valueStyle = { color: valueColor }; // Inline style for the value color

  return (
    <React.Fragment>
        <div className='statsCard_main'>
            <div className={textClass}>
                <p>{title}</p>
            </div>
            <div className='statsCard_value' style={valueStyle}>
                <p>{value}</p>
            </div>
        </div>
    </React.Fragment>
  )
}

export default StatsCard;
