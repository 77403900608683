import React, { useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Overlay,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import Moment from "react-moment";
import { decodeHTML } from "entities";
import { Link } from "react-router-dom";
import axios from "../../util/axios";
import { ReactComponent as NewspaperIcon } from "../../assets/images/nav-icon-newspaper.svg";

const UnHash = ({ hash }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState(null);
  const handleClick = (event) => {
    setShow(true);
    setTarget(event.target);
    setIsLoading(true);
    setIsError(false);
    axios({
      // Endpoint to send files
      url: "/leaks/crack",
      method: "GET",
      // Attaching the form data
      params: { hash },
    })
      // Handle the response from backend here
      .then((res) => {
        setIsLoading(false);
        setMsg(res.data.hash);
      })

      // Catch errors if any
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        setMsg(err.response.data.msg);
        console.log("my error", err.response.data.msg);
      });
  };

  return (
    <span ref={ref}>
      <Button variant="link" className="btn " onClick={handleClick}>Crack</Button>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Header className="text-white bg-secondarysss">
            Password Cracker
          </Popover.Header>
          <Popover.Body>
            <strong>
              {isLoading ? (
                "Cracking for your..."
              ) : (
                <span className={isError ? "text-danger" : ""}>{msg}</span>
              )}
            </strong>
          </Popover.Body>
        </Popover>
      </Overlay>
    </span>
  );
};

export default UnHash;
