import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const BarChart = ({
  barData,
  optionsData,
  marginBottom,
  isChartLabelsOnTop,
}) => {
  return (
    <div
      className="bar-chart-container "
      style={{ marginBottom: marginBottom || "3px" }}
    >
      <div className="col-12 d-flex align-items-center justify-content-center h-100">
        <Bar
          data={barData}
          options={optionsData}
          plugins={isChartLabelsOnTop ? [ChartDataLabels] : []}
        />
      </div>
    </div>
  );
};

export default BarChart;
