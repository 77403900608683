import { ReactComponent as ExternalLinkIcon } from "../../../../assets/images/external-link-icon.svg";

const HostnamesList = ({ hostname = "", ip = {} }) => {
  return (
    <div>
      {hostname.length > 0 && (
        <div className="mb-2">
          <a
            href={`http://${hostname}`}
            target="_blank"
            rel="noopener noreferrer"
            className="hostname-link "
            style={{ textDecoration: "none" }}
          >
            {hostname} <ExternalLinkIcon className="ms-1" />
          </a>
        </div>
      )}

      {ip.ip && (
        <div className="mb-2">
          <a
            className="hostname-link"
            href={`https://${ip.ip}:${ip.port}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            {ip.ip}:{ip.port} <ExternalLinkIcon className="ms-1" />
          </a>
        </div>
      )}
    </div>
  );
};

export default HostnamesList;
