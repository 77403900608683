import React from "react";
import { Container } from "react-bootstrap";

const AuthFooter = () => {
    const year = new Date().getFullYear();
    return (
        <React.Fragment>
            {/* <footer className="bg-dark bg-black border-top py-3 fixed-bottom">
                <Container fluid className="max-80">
                &copy; ARMORY BY Cisotronix - All rights reserved  {year}
                    
                </Container>
            </footer> */}
        </React.Fragment>
    );
}


export default AuthFooter;