import React, { useCallback } from "react";
import { useState } from "react";

import Image from "react-bootstrap/esm/Image";

import {
  Button,
  ButtonGroup,
  Carousel,
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";

import axios from "../../util/axios";
import NewSingle from "./NewsSingle";

import { ReactComponent as IconStoryView } from "../../assets/images/icon-story-view-dark.svg";
import { ReactComponent as IconListView } from "../../assets/images/icon-list-view.svg";
import { Link } from "react-router-dom";
import ExportNews from "./ExportNews";

async function fetchNews(page, pageSize) {
  try {
    let res = await axios.get("news", {
      params: { page: page, pageSize: pageSize },
    });
    console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
const News = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState("next");
  const [viewType, setViewType] = useState("listview");
  const [index, setIndex] = useState(0);
  const pageSize = 11;
  const [fetching, setFetching] = useState(false);
  const switchStory = () => {
    if ("list" === viewType) {
      if (items.length > 11) setItems(items.slice(-11));
    }
    setViewType(viewType === "story" ? "list" : "story");
  };
  const loadMoreItems = useCallback(
    async (mod = null) => {
      if (fetching) {
        return;
      }

      setFetching(true);
      console.log("viewtype", viewType, page, items);
      try {
        if ("story" === viewType) {
          let tmpPage = "-" === mod ? page - 2 : page;
          const data = await fetchNews(tmpPage, pageSize);
          let startIndex = 0;

          if ("+" === mod || "-" === mod) {
            let tmpItems = [];
            if ("+" === mod) {
              startIndex = 5;
              tmpItems = [...items, ...data.items];
            } else if ("-" === mod) {
              startIndex = 5;
              tmpItems = [...data.items, ...items];
            }
            let itemsToBe = [];
            for (var i = startIndex; i <= startIndex + 10; i++) {
              itemsToBe.push(tmpItems[i]);
            }
            if (itemsToBe) {
              setItems(itemsToBe);
            }
            if ("+" === mod) {
              setIndex(5);
            } else if ("-" === mod) {
              setIndex(0);
            }
          } else {
            setItems(data.items);
          }

          if (data.nextPage) setPage(data.nextPage);
          else setPage(1); //moving back to page 1
        } else {
          const data = await fetchNews(page, pageSize);
          console.log("appending data", items);
          setItems([...items, ...data.items]);
          setPage(data.nextPage);
        }
      } finally {
        setFetching(false);
      }
    },
    [items, fetching, page, pageSize, viewType]
  );

  const handleSelect = async (selectedIndex, e) => {
    //carousel next
    // alert(`selected=${selectedIndex}, direction=${e.direction}`);
    console.log("direction index", selectedIndex, direction);

    if ((selectedIndex <= 0 && page > 2) || 10 === selectedIndex) {
      await loadMoreItems(selectedIndex <= 0 ? "-" : "+");
    } else {
      setIndex(selectedIndex);
    }
  };

  const hasMoreItems = !!page;

  const loader = (
    <div key="loader" className="loader">
      Loading ...
    </div>
  );

  const renderStoryView = (items) => {
    return (
      <Carousel
        className="main-news-carousel"
        activeIndex={index}
        direction={direction}
        onSelect={(i, e) => handleSelect(i, e)}
        interval={6000}
        controls={false}
        pause="hover"
        onSlide={(e) => {
          console.log("event", e);
          // if (e <= 0) {
          // 	loadMoreItems('-');
          // }
          // if (e === 10) {
          // 	loadMoreItems('+');
          // }
        }}
      >
        {items.map((item) => (
          <Carousel.Item key={item.id}>
            <NewSingle item={item} />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  const renderListView = (items) => {
    return (
      <>
        {items.map((item) => (
          <div className="py-3 px-5 pb-4 mb-4 border-bottom border-2 border-secondary news-item">
            <NewSingle item={item} />
          </div>
        ))}
      </>
    );
  };
  return (
    <React.Fragment>
      <div className="main_container_style" >
        <InfiniteScroll
          loadMore={loadMoreItems}
          hasMore={hasMoreItems}
          loader={loader}
        >
          <ExportNews className="pb-5" />
          {renderListView(items)}

        </InfiniteScroll>
      </div >
    </React.Fragment>
  );
};

export default News;
