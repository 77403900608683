import React, { useEffect, useState } from "react";

import { useParams, useNavigate, useLocation } from "react-router-dom";


import axios from "../../util/axios";
import {
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import OTPInput, { ResendOTP } from "otp-input-react";
import { ReactComponent as OTPImage } from '../../assets/images/otp-em.svg';
import { Stack } from "react-bootstrap/esm";

const renderButton = (buttonProps) => {
  return <Button size="sm" className="btn-text-custom" variant="link"  {...buttonProps}>Resend OTP</Button>;
};
const renderTime = (remainingTime) => {
  return <span>Resend OTP in 00:{remainingTime}</span>;
};

const OTP = () => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const location = useLocation()
  const [user, setUser] = useState({});
  const [submitBtn, setSubmitBtn] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [OTP, setOTP] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const resendOTP = () => {
    setIsLoading(true);

    let REQ_URL = "/resend-otp";
    axios
      .post(REQ_URL, { code: routeParams.code })
      .then((response) => {
        setIsLoading(false);
        const data = response.data;
        console.log('my message', data)
        if (data.message) {
          toast.success(data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

        }
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 422) {
          console.log('error', e.response.data.message)
          setErrorMsg(e.response.data.message)
        }
      });
  };


  const handleSubmit = () => {
    setIsLoading(true);

    let SUBMIT_URL = "/verify-otp";
    axios
      .post(SUBMIT_URL, { otp: OTP, code: routeParams.code, organization_id: location?.search?.split("organization_id=")?.[1] || null })
      .then((response) => {
        setIsLoading(false);
        const data = response.data;
        const token = data.token;
        if (!token) {
          toast.error(data.message, {
            toastId: 'error1',
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          // alert("Unable to login. Please try after some time.");
          return;
        }
        localStorage.clear();
        localStorage.setItem("user-token", token);
        localStorage.setItem("user", JSON.stringify(data.data));
        setTimeout(() => {
          navigate("/");
        }, 500);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 422) {
          console.log('error', e.response.data.message)
          setErrorMsg(e.response.data.message)
        }
      });
  };



  useEffect(() => {
    if (isLoading) {
      setSubmitBtn({ disabled: true, label: "Please wait.." })
    } else {
      setSubmitBtn({ disabled: false, label: "Verify" })
    }
  }, [isLoading]);

  useEffect(() => {
    const params = {
      code: routeParams.code
    };
    axios
      .get('/user-by-code', { params })
      .then((res) => {
        setUser(res.data.data)
      }).catch((e) => {

        setIsInvalidCode(true);

      });
  }, []);
  return (
    <React.Fragment>
      <ToastContainer />
      <Stack  gap={4}>
            <div className="is-invalid"></div>
            {
              isInvalidCode ?
                <div className="invalid-feedback my-3 fs-2 text-center">Something went wrong</div> :
                <>
                  <h2 className="my-3 text-center">Please check your phone!</h2>


                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <p className="mb-5 text-center">We have sent a 6 digit confirmation code to  <strong>+{user.phone}</strong>, please enter it in the box below for verification.</p>

                    {errorMsg.length > 0 ? <div className="invalid-feedback my-3">{errorMsg}</div> : ""}

                    <OTPInput  className="justify-content-center" inputClassName={(errorMsg.length > 0 ? "is-invalid bg-danger" : "") + " otp form-control"} value={OTP} onChange={setOTP} autoFocus
                      OTPLength={6}
                      otpType="number"
                      disabled={false} />

                  </div>

                  


                  <Button
                    type="submit"
                    className="btn btn-primary btn-full mx-auto my-3"
                    id="singup-btn"
                    disabled={submitBtn.disabled || OTP.length < 6}
                    onClick={handleSubmit}
                  >
                    {submitBtn.label}
                  </Button>
                  <ResendOTP
                    className="d-flex justify-content-between"
                    renderButton={renderButton}
                    renderTime={renderTime}
                    onResendClick={resendOTP}

                  />
                </>
            }
          </Stack>
    </React.Fragment>
  );
};

export default OTP;
