import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import TopBarHeader from "../navbar/TopNavbar";
import Sidebar from "../navbar/Sidebar";

function AdminProtectedLayout(props) {
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" && window.innerWidth < 1024
  );
  const [open, setOpen] = useState(!isMobile);
  const [targetId, setTargetId] = useState(null);

  useEffect(() => {
    if (isMobile && open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMobile, open]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 1024);
      if (!isMobile) setOpen(true);
    }

    if (typeof window !== "undefined") {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <React.Fragment>
      <Header
        open={open}
        onToggle={() => {
          if (isMobile) {
            setOpen(!open);
          }
        }}
      ></Header>
      <TopBarHeader targetId={targetId} />
      <Container fluid>
        <Row className="flex-nowrap">
          <Sidebar
            open={open}
            targetId={setTargetId}
            onToggle={() => {
              if (isMobile) {
                setOpen(!open);
              }
            }}
          />
          <Col className={`main-section p-0`}>
            <div>{props.children}</div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </React.Fragment>
  );
}

export default AdminProtectedLayout
