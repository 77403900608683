import React, { useState } from "react";
import BaseTable from '../../../../components/table/BaseTable';
import BaseFilter from '../../../../components/filter/BaseFilter';
import BaseInput from '../../../../components/form/BaseInput';
import WebScanner from '../../../../components/web-scanner/WebScanner';
import WebUpdate from '../../../../components/website-updates/WebUpdate';
import { ReactComponent as Search } from "../../../../assets/images/search.svg";
import GenericCard from "../../../../components/card/GenericCard"
import VerticalBarChart from "../../../../components/charts/VerticalBarChart";
import HorizontalBarChart from "../../../../components/charts/HorizontalBarChart";
import { useParams } from "react-router-dom";
import axios from "../../../../util/axios";
import { ThreeCircles } from 'react-loader-spinner'

const OsAndLifeOfIndication = () => {
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const data = [{
        id: 1,
        ip: '1.1.1.2',
        OS: "Windows Server 2012",
        indication: "End of life",
        date: "11.10.2015",
    },
    {
        id: 2,
        ip: '5.5.5.6',
        OS: "Windows Server 2016",
        indication: "Soon to be",
        date: "11.10.2015",
    },
    {
        id: 3,
        ip: '1.1.1.4',
        OS: "Linux 1.1-5.3",
        indication: "Uncertain",
        date: "Uncertain",
    },
    ];

    const columns = [
        { Header: 'IP Addresses ', accessor: 'ip', isSortable: false },
        { Header: 'OS Name', accessor: 'OS' },
        { Header: 'End Of Life Indication', accessor: 'indication' },
        { Header: 'End of Life Date', accessor: 'date' },
    ];

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleRowSelect = (selectedRows) => {

    };

    const handleAction = (rowData) => {

    };
    const [verticalBarChartType, setVerticalBarChartType] = useState({
        labels: [],
        datasets: [
            {
                label: 'Operating System',
                data: [],
                backgroundColor: [
                    '#398FFE'
                ],
                size: 24,
                borderWidth: 1,
            },
        ],
    });

    const [horizontalBarChartType, setHorizontalBarChartType] = useState({
        labels: ['Supported', 'Uncertain', 'End of Life'],
        datasets: [
            {
                data: [1, 2, 1.7],
                backgroundColor: ['#3DDC97', '#398FFE', '#FF6155'],
                hoverBackgroundColor: '#398FFE'
            },
        ],
    });

    const VerticalBarChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: 'white',
                    font: {
                        family: 'Kodchasan',
                        size: 16,
                    }
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.2)'
                }
            },
            x: {
                ticks: {
                    color: 'white',
                    font: {
                        family: 'Kodchasan',
                        size: 16,
                    }
                },
                grid: {
                    color: 'transparent'
                },
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    color: 'white',
                    font: {
                        family: 'Kodchasan',
                        size: 18,
                    }
                }
            },
            title: {
                display: false,
                text: 'Operating System',
                color: 'white',
                font: {
                    family: 'Kodchasan',
                    size: 24,
                }

            }
        }
    };

    const HorizontalBarChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y', // This will make the bar chart horizontal
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'bottom', // Positions the legend at the bottom of the chart
                labels: {
                    color: 'white', // Sets the text color of the legend labels to white
                    font: {
                        family: 'Kodchasan', // Sets the font family for the legend labels
                        size: 18, // Sets the font size for the legend labels
                    }
                }
            },
            title: {
                display: false,
                text: 'Most Used Ports',
                color: 'white',
                font: {
                    size: 24  // Adjust the value to your desired font size
                }
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    color: 'white'
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0)'
                }
            },
            x: {
                ticks: {
                    color: 'white'
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.1)'
                }
            }
        }
    };

    const sampleData = [
        { id: 1, type: 'upload' },
        { id: 2, type: 'upload' },
        { id: 3, type: 'removal' },
        { id: 4, type: 'removal' },
        { id: 5, type: 'upload' },
    ];


    return (
        <React.Fragment>
            {
                isLoading ? <div className="content-loader">
                    <ThreeCircles
                        visible={true}
                        height="60"
                        width="60"
                        color="#ffff"
                        ariaLabel="three-circles-loading"
                        wrapperClass=""
                    />
                </div>
                    :
                    <div className="main_container_style">
                        <div className="pb-4 row top-container-main web-updates-container" >
                            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
                                <div className="row gap-4 top-container-main-row" >
                                    <div className="col-12 h-50" >
                                        <GenericCard children={<VerticalBarChart VerticalBarChartdata={verticalBarChartType} VerticalBarChartoptions={VerticalBarChartoptions} />} title={'Operating System'} isCentered={false}
                                        />
                                    </div>
                                    <div className="col-12 h-50" >
                                        <div className="row h-100" >
                                            <div className="col-5">
                                                <GenericCard children={<WebUpdate data={sampleData} />} title={'IP Addresses Updates'}

                                                />
                                            </div>
                                            <div className="col-7">
                                                <GenericCard children={<HorizontalBarChart HorizontalBarChartdata={horizontalBarChartType} HorizontalBarChartoptions={HorizontalBarChartoptions} />} title={'End of Life OS'} isCentered={false}
                                                    subtitle={'View in Table'}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
                                <WebScanner title={'OS and Life Of Indication Scanner'} />
                            </div>
                        </div>

                        <div className="px-10 py-5">
                            <div className='table-container' >
                                <div className='table-left'>
                                Results
                                </div>
                                <div >
                                    <BaseInput
                                        type="text"
                                        placeholder="Search..."
                                        className="search-input"
                                        icon={Search}
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <div >
                                <BaseFilter  />
                            </div>
                            <div>
                                <BaseTable className="mt-3 mb-3"
                                    columns={columns}
                                    data={data}
                                    selectable={true}
                                    onRowSelect={handleRowSelect}
                                    showCheckboxes={false}
                                    action={false}
                                />
                            </div>
                        </div>
                    </div>
            }
        </React.Fragment>
    )

}

export default OsAndLifeOfIndication;