import { Button } from "react-bootstrap";
import SocialShare from "./SocialShare";

function ShareLocation(props) {
  const {location, title} = props;
  const iconSize = 55;
  let shareUrl =
    "https://maps.google.com/maps?q=" + (location.lat + "," + location.lng);

  return (
    <div className="d-flex flex-wrap justify-content-center mt-sm-0 mt-4 pt-3">
      <SocialShare url={shareUrl} title={title} />
      <Button text
        href={shareUrl}
        className="btn m-3 py-3"
        id="singup-btn"
        target="_blank"
      >
        <i className="bi bi-geo-alt me-2"></i>
        View On Google Maps
      </Button>
    </div>
  );
}

export default ShareLocation;
