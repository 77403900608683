import React, { useState, useEffect } from "react";
import RiskScoreChart from './RiskScoreChart';
import wide from "../../assets/images/wide.png";
import dataloader from "../../assets/images/dataloader.png"

const DataLoader = () => {
  return (
    <div className="riskscorecard_dataLoader">
        <img src={dataloader}/>
    </div>
  )
}


const RiskScoreCard = () => {
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    // Example data points
    const dataPoints = [16, 50, 75, 25, 65, 30, 0];
    const chartHeight = 200; // Set the height to use in pixels

    return (
        <div className="risk-score-card font">
            <div className="risk-score-header">
                <h2>Company overall risk score</h2>
                <h3>|</h3>
                <div className="current-score">Current Risk Score: <span className="current-score-value">26</span></div>
                <img src={wide} alt="Expand"/>
            </div>
            {loading ? <DataLoader /> : (
            <RiskScoreChart 
                dataPoints={dataPoints}
                chartWidth="100%"
                chartHeight={`${chartHeight}px`} 
            />
            )}
        </div>
    );
};

export default RiskScoreCard;
