import React from 'react'
import { ReactComponent as NoUpdateIcon } from '../../assets/images/no-update.svg'

function NoUpdate() {
    return (
        <div className='no-data-container'>
            <div className='no-data-icon'><NoUpdateIcon /></div>
            <div className='no-data-text'>No Updates</div>
        </div>
    );
}

export default NoUpdate

