import React from "react";
import { ReactComponent as NoDataIcon } from "../../assets/images/no-data-found.svg";

export const NoDataAfterScanning = () => {
  return (
    <div className="no-data-container">
      <div className="no-data-icon">
        <NoDataIcon />
      </div>
      <div className="no-data-text">No Data Was Found</div>
    </div>
  );
};
