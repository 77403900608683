import React, { useState, useEffect } from 'react';
import wide from "../../assets/images/wide.png";
import chevrondown from "../../assets/images/chevron-down.png";

const Loader = () => {
    return (
        <div className='topissueCard_loader'>
            <div className="loader_circular">
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <div>Data In progress</div>
        </div>
    );
}

const TopIssuesCard = () => {
    const [isAdditionalTextVisible, setIsAdditionalTextVisible] = useState(false);

    const toggleAdditionalText = () => {
        setIsAdditionalTextVisible(!isAdditionalTextVisible);
    };

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Set a single timeout to hide the loader after 5 seconds
        const timer = setTimeout(() => setIsLoading(false), 5000);

        // Clear the timeout on cleanup
        return () => clearTimeout(timer);
    }, []);

    return (
        <React.Fragment>
            <div className='card-3 font'>
                <div className='card_header'>
                    <div className='card_title'>Top Issues</div>
                    <img src={wide} />
                </div>
                <div className='margindiv'></div>
                {isLoading ? (
                    <Loader /> // Show the loader in place of all the contents
                ) : (
                    <div className='card_subsection'>
                    
                        <ul>
                            <li className="issue-item">Improvement Recommendation: <span>3</span></li>
                            <li className="issue-item">Threats Detected:</li>
                            <li onClick={toggleAdditionalText} className={isAdditionalTextVisible ? 'active issue-item' : 'issue-item'}>
                                Additional Text{' '}
                                <img src={chevrondown} alt='' />
                                {isAdditionalTextVisible && (
                                    <div>
                                        <div className="additional-text">
                                            Implement WAF
                                        </div>
                                        <div className="additional-text">
                                            Update Encryption Standards
                                        </div>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default TopIssuesCard;
