import React from 'react';
import Form from 'react-bootstrap/Form';

function GroupSwitch({ id, label, checked, onChange, className }) {
  return (
    <Form className={`custom-switch ${className}`}>
      <Form.Check
        type="switch"
        id={id || 'custom-switch'}
        label={label || 'Group Issues by Title'}
        checked={checked}
        onChange={onChange}
      />
    </Form>
  );
}

export default GroupSwitch;
