import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

const PrivacyPolicyModal = ({ show, onHide, onContinue, onDecline }) => {
  const [bottom, setBottom] = useState(false);
  const handleScroll = (e) => {
    const target = e.target;
    const isBottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1;
    setBottom(isBottom);
  };

  useEffect(() => {
    if (show) {
      setBottom(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="base-modal"
      backdrop={true}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Logo className="mt-3 d-block mx-auto img-fluid logo-img" />
        <h2 className="mt-4 text-center ">Privacy Policy</h2>
      </div>
      <Modal.Body
        className="scrollable-checkbox-list-privacy"
        onScroll={handleScroll}
      >
        <div>
          <ol>
            <li>
              <h4 className="underline">General</h4>
              <p>
                We at Cisotronix Ltd. (the "Company", "We", "Us" or "Our"), are
                committed to protecting your ("You", "Your" or "User”) personal
                data and Your privacy. This privacy policy (the “Policy”)
                provides You with information regarding the types of information
                collected, processed, and used by the Company and others on Our
                behalf to provide You with Our services (the “Services”) and on
                Our website: http://www.cisotronix.com and Our online platform:
                http://app.cisotronix.com for provision of the Services(the
                "Platform").
              </p>
            </li>
            <li>
              <h4 className="underline">The Scope</h4>
              <p>
                This Policy describes what kind of Personal Data the Company
                collects about natural persons, and how it collects it, uses it,
                shares it with third parties, secures it, processes it, etc.
              </p>
              <p>
                This Policy is part of and should be read in conjunction with
                our Terms of Use.
              </p>
              <p>
                In this Policy, “Personal Data” refers to any information
                relating to an identified or identifiable natural person. An
                identifiable natural person is one who can be identified,
                directly or in combination with additional information that We
                have or that We have access to.
              </p>
              <p>
                In this Policy, the “processing” of Personal Data refers to any
                operation or set of operations performed on Personal Data,
                including the collection, recording, organization, structuring,
                storage, adaptation or alteration, retrieval, consultation, use,
                disclosure by transmission, dissemination or otherwise making
                available, alignment or combination, restriction, erasure, or
                destruction of Personal Data.
              </p>
            </li>
            <li>
              <h4 className="underline">
                When do We collect information about You?
              </h4>
              <ol>
                <li>
                  We may collect Personal Data about You in the following
                  circumstances:
                  <ul>
                    <li>
                      When You access or use the Platform (e.g. if you are a
                      representative of a company that is interested in using
                      Our Services);
                    </li>
                    <li>When You register as a user and use Our Services;</li>
                    <li>
                      When You register Your company (the “Customer”) as a user
                      of our Services;
                    </li>
                    <li>
                      When We receive Personal Data from third parties or Our
                      Customers;
                    </li>
                    <li>
                      When You Share Personal Data with Us to use our Services;
                    </li>
                    <li>
                      When You interact with Us in any manner, including by
                      sending Us messages through third-party messaging
                      applications
                    </li>
                  </ul>
                </li>
                <li>
                  You are not obligated to provide Us with any Personal Data
                  about You. However, in some instances, not providing such
                  Personal Data will prevent Us from providing You with the
                  Services You requested Us to provide You and will prevent Your
                  use of the Services or a part thereof
                </li>
                <li>
                  When You provide Us with any type of Personal Data during Your
                  use of Our Services, You are responsible for making sure You
                  have the authorization and permission legally required to
                  share this Personal Data with Us.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">
                What types of information do We collect?
              </h4>
              <span>
                We (or others on Our behalf) may collect the following types of
                Personal Data about You:
              </span>
              <p>When you are a User</p>
              <ol>
                <li>
                  <u>Registration information and personal data:</u> upon
                  creation of an account with Us, You provide Us with certain
                  information, including Your full name, email address, Contact
                  information, Customer name and address, position with our
                  Customer, as well as any other information the Company may
                  deem necessary to collect at the point of registration. While
                  some of the details mentioned above are required fields
                  registration pages, others are not required, but We recommend
                  that You complete them so that We can provide You with the
                  best services.
                </li>
                <li>
                  <u>Communication information:</u> When you contact Us, through
                  any channel whatsoever, including social media networks, You
                  may provide Us with Your name, certain contact information
                  (such as email address and phone), and the content of Your
                  communication with Us.
                </li>
                <li>
                  <u>Information we receive from third-party platforms:</u> to
                  provide You with the services we receive information from
                  third-party Platforms. The information we receive is collected
                  by such third parties and shared with Us under their
                  respective privacy policy.
                </li>
                <li>
                  <u>Platform usage information:</u> This information includes
                  online activity log, traffic information (including, without
                  limitation, IP address, time of access, date of access, web
                  and mobile page(s) visited, language used, operating system
                  information, Your device information, crash reports and type
                  of browser used, information regarding the device You used,
                  including the hardware model, operating system and version and
                  other device identifiers). Some of this information may not
                  identify You personally and therefore does not constitute
                  Personal Data.
                </li>
                <li>
                  <u>Platform activity information:</u> Whenever You use the
                  Platform, We monitor Your use of the Services and record Your
                  activity. The information that We gather about You in this
                  context includes any activity that You perform on the
                  Platform, such as dates and times on which You will use the
                  Platform, the length of time You will be using the Services on
                  the Platform, the type of Services used, etc.
                </li>
                <li>
                  <u>Use on behalf of Customer:</u> When You are using the
                  Services on behalf of an organization (Your company/ the
                  Customer), in certain circumstances, we can link the natural
                  person using the Services to the organization on whose behalf
                  such person created the account, and therefore, the
                  information you provide and that We collect about you as
                  mentioned above may constitute Personal Data about You.
                </li>
                Aggregate Information
                <li>
                  We may create statistical, aggregated, and anonymized data
                  relating to our Users and Services for analytical purposes,
                  including business development and Platform improvements.
                  Aggregated data is derived from Personal Data and User Data
                  but in its aggregated form it does not relate to or identify
                  any particular client or individual or any specific user’s
                  data. This data is used to understand our customer base and to
                  develop, improve, and market our Platform
                </li>
                Communication Information
                <li>
                  When You contact Us, through any channel whatsoever, including
                  the "Contact Us" page or when You subscribe to Our Newsletter
                  on the Platform or social media networks, you may provide Us
                  with Your full name, business name, and e-mail address, job
                  description, telephone number and the content of your
                  communication with Us.
                </li>
              </ol>
            </li>
            You are responsible for ensuring the accuracy of the information You
            provide Us (or others on Our behalf). Inaccurate information will
            affect Our ability to provide You with some of the Services, as well
            as Our ability to contact You as contemplated in this Policy.
            <li>
              <h4 className="underline">
                The Purposes Of The Processing Of Personal Data{" "}
              </h4>
              <p>
                The Company processes Your Personal Data for one or more of the
                purposes outlined in this section according to the appropriate
                legal basis.
              </p>
              <p>
                The Company will not process Personal Data about you unless
                there is a legal basis for such processing. The legal bases
                according to which the Company may process Personal Data about
                you are as follows:
              </p>
              <ol>
                <li>
                  <p>
                    Your consent that the Company will process Personal Data
                    about You for one or more specific purposes. By way of
                    example, to send marketing materials to You. Where We rely
                    on Your consent, You may withdraw Your consent for the
                    purposes for which You provided Your consent at any time by
                    sending a notice free of charge to the following email
                    address: Office@cisotronix.com.
                  </p>
                  <p>
                    Where You withdraw Your consent for the processing of
                    Personal Data about You, We might not be able to provide You
                    with some or all of the Services You requested or in the
                    form intended to be provided to You, and You will have no
                    claim in respect of that
                  </p>
                </li>
                <li>
                  <p>
                    Processing is necessary for the performance of a contract to
                    which You are party or to take steps at Your request before
                    entering into a contract. This refers to all the information
                    required for us to provide You with the Services and grant
                    You access to the Platform and the various Services offered
                    in connection therewith.
                  </p>
                </li>
                <li>
                  <p>
                    Processing is necessary for the purposes of the legitimate
                    interests pursued by the Company or by a third party. By way
                    of example, for the purpose of improving Our Services, or
                    for the exercise or defense of legal claims.{" "}
                  </p>
                  <p>
                    At any time, you may approach Us by sending a notice to the
                    email address Office@cisotronix.com in order to receive
                    information concerning the review performed by Us. This is
                    so you can conclude that We may process the Personal Data
                    about you on account of such processing being necessary for
                    the purposes of the legitimate interests pursued by the
                    Company or by a third party.
                  </p>
                </li>
              </ol>
            </li>
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>Purpose</b>
                  </td>
                  <td>
                    <b>Legal Basis</b>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>1. In order to register you with Our Services</b>
                    </p>
                    <p>
                      Upon your request to open an account and register to use
                      the Services, We will process Personal Data about you in
                      order to allow Us to perform such request.
                    </p>
                  </td>
                  <td className="content-start">
                    Processing is necessary for the performance of a contract to
                    which you are party or in order to take steps at your
                    request prior to entering into a contract.
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>
                        2. In order to assess the security risk posed to the
                        Customer from leaked credentials and documents.
                      </b>
                    </p>
                    <p>
                      As part of our services, we perform an investigation of
                      leaked credentials, assets and document. These may contain
                      certain Personal Data.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      The data subject has given consent to the processing of
                      his or her personal data for one or more specific
                      purposes.
                    </p>
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>
                        3. In order to assess the security risk posed to
                        Customer assets
                      </b>
                    </p>
                    <p>
                      As part of our services, we perform an investigation of
                      security aspects of Customer assets. These may contain
                      certain Personal Data.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      The data subject has given consent to the processing of
                      his or her personal data for one or more specific
                      purposes.
                    </p>
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>4. In order to demonstrate call and email spoofing</b>
                    </p>
                    <p>
                      When the Customer performs a demonstration of call and SMS
                      spoofing by our Customers, we use Personal Data such as
                      name and phone number.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      The data subject has given consent to the processing of
                      his or her personal data for one or more specific
                      purposes.
                    </p>
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>5. In order to demonstrate location tracking</b>
                    </p>
                    <p>
                      When the Customer performs a demonstration of location
                      tracking, we use Personal Data such as name, phone number
                      and location data.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      The data subject has given consent to the processing of
                      his or her personal data for one or more specific
                      purposes.
                    </p>
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>
                        6. In order to respond to your queries, requests, and/or
                        complaints
                      </b>
                    </p>
                    <p>
                      Processing of Personal Data about you is required in order
                      to respond to queries you have concerning your use of the
                      Services.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>
                        7. In order to contact you for the purpose of
                        operational requirements
                      </b>
                    </p>
                    <p>
                      In some circumstances, We will contact you in order to
                      update you in respect of certain operational matters—for
                      instance, if We are wish to update you of new Services
                      features. In these circumstances, We will need to use
                      Personal Data about you accordingly.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      Processing is necessary for the performance of a contract
                      to which you are party or in order to take steps at your
                      request prior to entering into a contract.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>
                        8. In order to comply with any legal obligations or
                        judicial or administrative orders
                      </b>
                    </p>
                    <p>
                      We process Personal Data about you in order to comply with
                      Our various legal obligations.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      Processing is necessary for compliance with a legal
                      obligation to which the Company is subject.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>9. In order to improve the Services</b>
                    </p>
                    <p>
                      We may use Personal Data about you in order to improve the
                      Services. Such processing will include, inter alia, any
                      comments and complaints received in respect of the
                      Services, as well as any errors and malfunctions.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>
                        10. In order to prevent fraud, or abusive use of the
                        Services
                      </b>
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                    <p>
                      Processing is necessary for compliance with a legal
                      obligation to which the Company is subject.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>
                        11. In order to perform and maintain various activities,
                        supporting products and services
                      </b>
                    </p>
                    <p>
                      Such activities include back-office functions, business
                      development activities, strategic decision-making,
                      oversight mechanisms, etc.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>
                        12. In order to perform analysis, including statistical
                        analysis.
                      </b>
                    </p>
                    <p>
                      We use various analytical measures (including statistical
                      ones) to make decisions on various issues.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 content-start">
                    <p>
                      <b>
                        13. In order to protect Our and third parties’
                        interests, rights, and assets, including initiation,
                        exercise, or defense of legal claims and fullfill
                        regulatory requirements.
                      </b>
                    </p>
                    <p>
                      We may process Personal Data about you in order to protect
                      Our interests, rights, and assets, or those of third
                      parties, according to any law, regulation, or agreement,
                      including any of Our terms and conditions and policies.
                    </p>
                  </td>
                  <td className="content-start">
                    <p>
                      Processing is necessary for the purpose of the legitimate
                      interests pursued by the Company or by a third party.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <li>
              <h4 className="underline">Sharing of Personal Data</h4>
              <ol>
                <li>
                  We will not disclose Personal Data about You to third parties
                  except as detailed in this Policy.
                </li>
                <li>
                  We may share Personal Data about You with third parties for
                  the following purposes:
                  <ol>
                    <li>Upon Your consent or instruction;</li>
                    <li>
                      Third parties providing Us services: Storage and hosting
                      providers, including cloud computing services and data
                      security services; IP address information; Analysis of
                      user experience; Support; Marketing; CRM data management;
                      Accounting and legal services, and; Research, analytical,
                      technical, and diagnostic services.
                    </li>
                    <li>
                      Third parties providing Us services related to the
                      offering of the Services, including the Platform (e.g.
                      cloud service providers, support providers, integrated
                      third-party platforms providers);
                    </li>
                    <li>
                      If we will be subject to an audit or a Due diligence
                      process.
                    </li>
                    <li>
                      If We sell, assign, or transfer some or all of Our
                      business or assets to a successor or acquirer, if We are
                      acquired by or merge with a third party, or if We file for
                      bankruptcy or become insolvent, or any other situation
                      where Personal Data may be sold, assigned or transferred
                      to a successor or acquirer;
                    </li>
                    <li>
                      To protect Our rights, property, and interest or those of
                      third parties;
                    </li>
                    <li>
                      To fulfill Our legal or regulatory requirements or to
                      comply with a court order.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">Transfer of Personal Data abroad</h4>
              <ol>
                <li>
                  Personal Data about you may be transferred to a third country
                  (i.e. jurisdictions other than the one you reside in) or to
                  international organizations. In such circumstances, the
                  Company shall take appropriate safeguards to ensure the
                  protection of Personal Data about you and to provide that
                  enforceable data subject rights and effective legal remedies
                  for data subjects are available.
                </li>
                <li>
                  If you are an EEA resident, please note that these safeguards
                  and protection will be available if any of the following are
                  met:
                  <ol>
                    <li>
                      The transfer is to a third country or an international
                      organization that the EU Commission has decided provides
                      an adequate level of protection to the Personal Data that
                      is transferred to it pursuant to Article 45(3) of
                      Regulation (EU) 2016/679 of the European Parliament and of
                      the Council of 27 April 2016 ("GDPR");
                    </li>
                    <li>
                      The transfer is according to a legally binding and
                      enforceable instrument between public authorities or
                      bodies pursuant to Article 46(2)(a) of the GDPR; or
                    </li>
                    <li>
                      The transfer is in accordance with standard data
                      protection clauses adopted by the EU Commission pursuant
                      to Article 46(2)(c) of the GDPR. The clauses adopted by
                      the EU Commission can be viewed at{" "}
                      <a
                        className="text-link"
                        href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"
                      >
                        https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en
                      </a>
                      .
                    </li>
                  </ol>
                </li>
                <li>
                  You may request that the Company provide you with details
                  concerning the safeguards employed by it to protect the
                  Personal Data about you that are transferred to a third
                  country or an international organization, by sending an email
                  to the following address: Office@cisotronix.com.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">Retention of Personal Data</h4>
              <p>
                We will retain Personal Data about You only for as long as
                necessary for the fulfillment of the purposes for which such
                Personal Data is collected. We may retain Personal Data about
                You for longer periods if We find it necessary to comply with
                legal requirements applicable to Us.
              </p>
            </li>
            <li>
              <h4 className="underline">Marketing communications</h4>
              <ol>
                <li>
                  You may allow Us to provide You with communications from Us,
                  as well as from third parties, either in connection with the
                  Services or in connection with other products and services.
                </li>
                <li>
                  You may opt-out from receiving communications from Us by
                  sending a blank e-mail to Us to{" "}
                  <a href="mailto:Office@cisotronix.com">
                    Office@cisotronix.com
                  </a>{" "}
                  and/or by clicking on the unsubscribe link available in Our
                  e-mails sent to You.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">Cookies</h4>
              <ol>
                <li>
                  We may use certain third-party services, such as analytics
                  companies or companies delivering advertisements, which may
                  use cookies or other technologies, and those practices and
                  providers are subject to their own policies.
                </li>
                <li>
                  The Cookie (which is a small text file) is installed on the
                  device via which You visit or access the Platform. The cookies
                  allow Us to collect information about You and Your behavior,
                  to improve Your user experience, to remember Your preferences
                  and settings, and to customize and offer You products and
                  services that may interest You. Cookies are also used to
                  collect statistics and perform analytics.
                </li>
                <li>
                  Some of the cookies We use are session cookies, which are
                  downloaded temporarily to Your device and last until You close
                  Your web browser, while others are persistent cookies, which
                  last on Your device after You cease browsing the Service and
                  are used to help the Service remember You as a returning
                  visitor when You return to the Service.
                </li>
                <li>
                  The cookies We may use have been classified according to their
                  functionality, as follows:
                </li>
                <h4 className="underline text-center">Types of Cookie</h4>
                <p className="underline">
                  <b>Strictly Necessary Cookies</b>
                </p>
                <p>
                  <b>Purpose</b>
                </p>
                <p>
                  These cookies are strictly necessary to enable you to navigate
                  the Platform and use features you have requested. They are
                  used to provide you with Our content, products, and services
                  that you have requested.
                </p>
                <p>
                  These cookies are strictly necessary to enable you to navigate
                  the Platform and use features you have requested. They are
                  used to provide you with Our content, products, and services
                  that you have requested.
                </p>
                <p>
                  <b>Additional Information</b>
                </p>
                <p>
                  These cookies collect Personal Data about you, such as user
                  name and last login date, and identify you as being logged in
                  to the Platform.
                </p>
                <p>
                  These cookies are deleted when you close your web browser
                  (session cookies).
                </p>
                <p className="underline">
                  <b>Functionality Cookies</b>
                </p>
                <p>
                  <b>Purpose</b>
                </p>
                <p>
                  These cookies are used to recognize you when you return to the
                  Platform and allow Us to remember your choices and
                  preferences.
                </p>

                <p>
                  <b>Additional Information</b>
                </p>
                <p>
                  These cookies survive the closing of your web browser, and
                  last until their applicable expiry time.
                </p>
                <p className="underline">
                  <b>Performance Cookies</b>
                </p>
                <p>
                  <b>Purpose</b>
                </p>
                <p>
                  These cookies are used to provide aggregated statistics in
                  respect of the performance of the Platform and to test and
                  improve such performance, in order to provide better user
                  experience. In addition, they allow Us to carry out analytical
                  functions on the Platform.
                </p>

                <p>
                  <b>Additional Information</b>
                </p>
                <p>
                  These cookies collect anonymized data that is not related to
                  an identified or identifiable natural person.
                </p>
                <p>
                  These cookies collect anonymized data that is not related to
                  an identified or identifiable natural person.
                </p>
                <p className="underline">
                  <b>Third Party Marketing/Targeting Cookies </b>
                </p>
                <p>
                  <b>Purpose</b>
                </p>
                <p>
                  These cookies are used to deliver ads and marketing
                  communications, and to display the Platform in a manner that
                  is more relevant to you. They are also used to measure the
                  effectiveness of an advertising campaign. These cookies record
                  your visit to the Platform and the pages you have visited.
                </p>

                <p>
                  <b>Additional Information</b>
                </p>
                <p>
                  Some of these cookies are provided and used by third parties
                </p>
                <p>
                  These cookies are valid for varying periods; some are deleted
                  once you close your browser, while others have an indefinite
                  validity period.
                </p>
                <li>
                  You can change the privacy settings in the browser that You
                  use, delete the existing cookies, prevent the creation of
                  cookies, or click the setting that enables You to accept or
                  deny the creation of a cookie every time that a cookie is
                  detected. Please see below links to instructions on how to do
                  this in respect of some of the most popular web browsers:
                  <ul>
                    <li className="underline">
                      <b>Firefox</b>
                    </li>
                    <li className="underline">
                      <b>Internet Explorer</b>
                    </li>
                    <li className="underline">
                      <b>Google Chrome</b>
                    </li>
                    <li className="underline">
                      <b>Safari</b>
                    </li>
                  </ul>
                  If you do so, some or all of the Service’s features and
                  functionalities might not perform as intended.
                </li>
              </ol>
              <p>
                <b>ONLINE TRACKING NOTICE</b>
              </p>
              <p>
                <b>
                  AT THIS TIME, THIS SERVICE DOES NOT SUPPORT DO-NOT-TRACK
                  SIGNALS.
                </b>
              </p>
            </li>
            <li>
              <h4 className="underline">Security</h4>
              <ol>
                <li>
                  We have implemented suitable security policies, rules, and
                  technical measures to protect and safeguard the Personal Data
                  under Our control from unauthorized access, improper use or
                  disclosure, unauthorized modification, or unlawful
                  destruction.
                </li>
                <li>
                  Any transfer of Personal Data via the internet cannot be fully
                  secured. Therefore, the Company cannot ensure the protection
                  of Personal Data about you when transferred via the internet
                  to Us.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">Links to Other Websites</h4>
              <ol>
                <li>
                  The Platform may contain links to websites and/or applications
                  of third parties. Other websites and applications may also
                  reference or link to Our Platform. We do not control such
                  websites and applications, nor the collection and/or
                  processing of Personal Data about You by such websites and
                  applications, and thus We are not responsible for their
                  privacy practices. This Policy does not apply to any actions
                  taken via such websites and/or applications.
                </li>
                <li>
                  Whenever You access such third parties’ websites and/or
                  applications, We recommend that You carefully review their
                  privacy policies before using such websites and/or
                  applications and before disclosing any Personal Data about
                  You.
                </li>
              </ol>
            </li>
            <li>
              <h4 className="underline">
                Your rights in respect of the Personal Data about You
              </h4>
              <ol>
                <li>
                  Generally, You have the right to request that We provide You
                  with confirmation as to whether Personal Data about You is
                  being collected by Us, to ask to review such data, to rectify
                  the Personal Data if applicable, and to erase the Personal
                  Data no longer required by Us.
                </li>
                <li>
                  Please note that We may need to receive Personal Data from You
                  to verify Your identity before allowing You to exercise Your
                  rights.
                </li>
                <p>
                  <i>
                    If you are an EEA resident please read this section below:
                  </i>
                </p>
                <li>
                  You are entitled to the following rights in respect of the
                  Personal Data about you. To exercise such rights, you may send
                  a request to exercise your rights to the following email
                  address: Office@cisotronix.com
                  <ol>
                    <li>
                      <p>
                        <b>Right of access</b>
                      </p>
                      <p>
                        You have the right to receive from the Company
                        confirmation as to whether or not Personal Data about
                        you is being processed, and, if that is the case, access
                        to the Personal Data and the following information: (1)
                        the purposes of the processing; (2) the categories of
                        Personal Data concerned; (3) the recipients or
                        categories of recipients to whom the Personal Data have
                        been or will be disclosed, in particular recipients in
                        third countries outside the European Economic Area (EEA)
                        or international organizations; (4) if possible, the
                        envisaged period for which the Personal Data will be
                        stored, or, if not possible, the criteria used to
                        determine that period; (5) the existence of the right to
                        request from the Company rectification or erasure of
                        Personal Data or restriction of processing of Personal
                        Data about you or to object to such processing; (6) the
                        right to lodge a complaint with a supervisory authority;
                        (7) if the Personal Data is not collected from you, any
                        available information as to its source; (8) the
                        existence of profiling; and (9) if Personal Data is
                        transferred to a third country outside the EEA or to an
                        international organization, the appropriate safeguards
                        relating to the transfer.
                      </p>
                      <p>
                        The Company shall provide a copy of the Personal Data
                        undergoing processing and may charge a reasonable fee
                        for any further copies requested by you. If you make the
                        request by electronic means, and unless otherwise
                        requested by you, the information shall be provided in a
                        commonly used electronic form.
                      </p>
                      <p>
                        The right to obtain a copy of the Personal Data shall
                        not adversely affect the rights and freedoms of others.
                        Therefore, if the request harms the rights and freedoms
                        of others, the Company may not fulfill your request or
                        do so in a limited manner.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Right of rectification</b>
                      </p>
                      <p>
                        You have the right to obtain from the Company the
                        rectification of inaccurate Personal Data about you.
                        Taking into account the purposes of the processing, you
                        have the right for incomplete Personal Data about you to
                        be completed, including by means of providing a
                        supplementary statement.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Right of erasure</b>
                      </p>
                      <p>
                        You have the right to obtain from the Company the
                        erasure of Personal Data about you if one of the
                        following grounds applies: (a) the Personal Data is no
                        longer necessary in relation to the purpose for which it
                        was collected or otherwise processed; (b) you withdraw
                        your consent on which the processing is based and there
                        is no other legal ground for the processing; (c) you
                        object at any time, on grounds relating to your
                        particular situation, to the processing of Personal Data
                        about you that is based on the legitimate interests
                        pursued by Us or by a third party, and there are no
                        overriding legitimate grounds for the processing; (d)
                        you object to the processing of Personal Data about you
                        for direct marketing purposes; (e) the Personal Data has
                        been unlawfully processed; or (f) the Personal Data
                        about you has to be erased for compliance with a legal
                        obligation in European Union or Member State law to
                        which the Company is subject.
                      </p>
                      <p>
                        This right is not applicable to the extent that the
                        processing is necessary (a) for compliance with a legal
                        obligation that requires processing by European Union or
                        Member State law to which the Company is subject; or (b)
                        for the establishment, exercise, or defense of legal
                        claims.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Right of restriction of processing</b>
                      </p>
                      <p>
                        You have the right to obtain from the Company the
                        restriction of processing of Personal Data about you if
                        one of the following applies: (a) the accuracy of the
                        Personal Data is contested by you, for a period enabling
                        the Company to verify the accuracy of the Personal Data
                        about you; (b) the processing is unlawful and you oppose
                        the erasure of the Personal Data about you and request
                        the restriction of its use instead; (c) the Company no
                        longer needs the Personal Data about you for the
                        purposes of processing, but the Personal Data is
                        required by you for the establishment, exercise, or
                        defense of legal claims; (d) if the processing of
                        Personal Data about you is necessary for the purpose of
                        the legitimate interests pursued by the Company or by a
                        third party, unless We demonstrate compelling legitimate
                        grounds for the processing that override your interests,
                        rights, and freedoms or for the establishment, exercise,
                        or defense of legal claims; or (e) if the Personal Data
                        about you is processed for direct marketing purposes,
                        including profiling, to the extent it is related to such
                        direct marketing.
                      </p>
                      <p>
                        If the processing of Personal Data about you has been
                        restricted following your request, such Personal Data
                        shall, with the exception of storage, only be processed
                        with your consent or for the establishment, exercise, or
                        defense of legal claims or for the protection of the
                        rights of another natural or legal person or for reasons
                        of important public interest of the European Union or of
                        a Member State.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Right to data portability</b>
                      </p>
                      <p>
                        You have the right to receive the Personal Data about
                        you, which you have provided to the Company, in a
                        structured, commonly used, and machine-readable format,
                        as well as the right to transmit such Personal Data to
                        another controller, if (a) the processing is based on
                        your consent or on a contract to which you are a party,
                        and (b) the processing is carried out by automated means
                      </p>
                      <p>
                        In exercising your right to data portability, you have
                        the right for the Personal Data about you to be
                        transmitted directly from the Company to another
                        controller, where technically feasible. The exercise of
                        your right to data portability is without prejudice to
                        your and the Company’s rights under your right to
                        erasure. In addition, the right to data portability
                        shall not adversely affect the rights and freedoms of
                        others.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Right to object</b>
                      </p>
                      <p>
                        You have the right to object at any time, on grounds
                        relating to your particular situation, to the processing
                        of Personal Data about you that is based on the
                        legitimate interests pursued by the Company or by a
                        third party, including profiling based on such
                        legitimate interests. In such case, We shall no longer
                        process the Personal Data about you, unless We
                        demonstrate compelling legitimate grounds for the
                        processing that override your interests, rights, and
                        freedoms or for the establishment, exercise, or defense
                        of legal claims.
                      </p>
                      <p>
                        You have the right to object at any time to the
                        processing of Personal Data about you for direct
                        marketing purposes, including profiling, to the extent
                        it is related to such direct marketing.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Right to withdraw consent</b>
                      </p>
                      <p>
                        You may withdraw your consent provided to Us for the
                        purpose of processing Personal Data about you at any
                        time, without affecting the lawfulness of processing
                        based on your consent before its withdrawal.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          Right to lodge a complaint with a supervisory
                          authority
                        </b>
                      </p>
                      <p>
                        You have the right to lodge a complaint with a
                        supervisory authority established by a Member State to
                        protect the fundamental rights and freedoms of natural
                        persons in relation to the processing of Personal Data
                        within the European Union.
                      </p>
                      <p>
                        Your rights in respect of Personal Data about you as
                        outlined in this section 13 may be restricted by
                        European Union or Member State law to which the Company
                        is subject.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  We shall provide you with the information requested according
                  to your rights outlined in this section 13 without undue delay
                  and in any event within one month of receipt of the request.
                  That period may be extended by two further months where
                  necessary, taking into account the complexity and number of
                  the requests. We shall inform you of any such extension within
                  one month of receipt of your request, together with the
                  reasons for the delay.
                </li>
                <li>
                  The information requested according to your rights outlined in
                  this section 13 shall be provided free of charge, unless
                  stated otherwise in this section 13. Where requests are
                  manifestly unfounded or excessive, in particular because of
                  their repetitive character, We may either (a) charge a
                  reasonable fee, taking into account the administrative costs
                  of providing the information or communication or taking the
                  action requested; or (b) refuse to act on the request.
                </li>
                <li>
                  The Company may require you to provide additional information
                  necessary to confirm your identity in order to fulfill your
                  request according to your rights outlined in this section 13,
                  if We have reasonable doubts concerning the identity of the
                  natural person making the request.
                </li>
              </ol>
            </li>
            <br />
            <p>
              <i>
                If you reside in the United States, please read below with
                respect to the California Consumer Privacy Act (CCPA),
                California Privacy Rights Act (CPRA), the Virginia Consumer Data
                Protection Act (VCDPA), and the Colorado Privacy Act (CPA))
                rights you might have:
              </i>
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>Right</b>
                  </td>
                  <td>
                    <b>Scope</b>
                  </td>
                </tr>
                <br />
                <tr>
                  <td className="pr-2 w-40 content-start">
                    <p>1. Right to know</p>
                  </td>
                  <td className="content-start w-60">
                    You have the right to receive the following information:
                    <ul>
                      <li>What types of Personal Data collected;</li>
                      <li>
                        What are the types of sources of the Personal Data
                        collected;
                      </li>
                      <li>
                        To what end We collect sell or share the Personal Data;
                      </li>
                      <li>
                        Types of third parties to whom We disclose Personal
                        Data, if any; and
                      </li>
                      <li>
                        The specific pieces of Personal Data We have collected
                        about you.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className="pr-2 w-40 content-start">
                    <p>2. Right to Correct </p>
                  </td>
                  <td className="content-start w-60">
                    Taking into account the nature of the Personal Data and the
                    purposes of the processing thereof, you have the right to
                    request that We correct inaccurate Personal Data about you.
                  </td>
                </tr>
                <tr>
                  <td className="pr-2 w-40 content-start">
                    <p>3. Right to Erasure </p>
                  </td>
                  <td className="content-start w-60">
                    <p>
                      You make ask Us to delete your Personal Data and direct
                      Our service providers to do so
                    </p>
                    <p>
                      Please note that We may not delete your Personal Data if
                      it is necessary to complete Our legal obligation to you to
                      provide the Services or otherwise protect Our legal
                      rights, comply with an existing legal obligation; or use
                      your Personal Data, internally, in a lawful manner that is
                      compatible with the context in which you provided the
                      information.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="pr-2 w-40 content-start">
                    <p>
                      4. Right to Limit Use and Disclosure of Sensitive Personal
                      Data
                    </p>
                  </td>
                  <td className="content-start w-60">
                    <p>
                      You have the right to request that We limit Our use or
                      disclosure of your “sensitive personal information” that
                      We collected from you. We may deny your limitation request
                      if the use and/or disclosure of the information is
                      necessary for Us to perform Our Services or provide the
                      goods or Services as reasonably expected by an average
                      consumer.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="pr-2 w-40 content-start">
                    <p>5. Right to Non-selling or Sharing</p>
                  </td>
                  <td className="content-start w-60">
                    <p>
                      You may ask Us to not sell your Personal Data, or share it
                      with a third party for cross-context behavioral
                      advertising, whether or not for monetary or other valuable
                      consideration.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="pr-2 w-40 content-start">
                    <p>
                      6. Right to Non-Discrimination for the exercise of your
                      privacy rights
                    </p>
                  </td>
                  <td className="content-start w-60">
                    <p>
                      You have the right to not be discriminated against by Us
                      because you exercised any of your rights under the CCPA.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="pr-2 w-40 content-start">
                    <p>
                      7. Right to designate an authorized agent to submit CCPA
                      requests on your behalf
                    </p>
                  </td>
                  <td className="content-start w-60">
                    <p>
                      You may designate an authorized agent to make a request
                      under the CCPA on your behalf. To do so, you need to
                      provide the authorized agent written permission to do so
                      and the agent will need to submit to Us proof that such
                      agent has been authorized by you. We will also require
                      that you verify your own identity, as explained below.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              In order to exercise your abovementioned rights, please contact us
              using the following details: email: Office@cisotronix.com.
            </p>
            <p>
              Please note that We may need to receive Personal Data from you in
              order to verify your identity prior to allowing you to exercise
              your rights.
            </p>
            <li>
              <h4 className="underline">Changes to the Policy </h4>
              <p>
                We may change this Policy in Our sole discretion at any time.
                Any revisions to this Policy will be posted as an updated
                version accessible via the Platform home page. We recommend that
                You revisit this Policy regularly to be kept apprised of any
                such changes.
              </p>
            </li>
            <li>
              <h4 className="underline">Contact Us</h4>
              <p>
                For any queries relating to this Policy and data protection,
                please contact Us at Office@cisotronix.com.
              </p>
            </li>
          </ol>

          {/* <table>
            <tbody>
              <tr>
                <td>
                  <b>Purpose</b>
                </td>
                <td>
                  <b>Legal Basis</b>
                </td>
              </tr>
              <br />
              <tr>
                <td className="pr-2 ">
                  <p>
                    <b>1. In order to register you with Our Services </b>
                  </p>
                  <p>
                    Upon your request to open an account and register to use the
                    Services, We will process Personal Data about you in order
                    to allow Us to perform such request.{" "}
                  </p>
                </td>
                <td className="content-start">
                  Processing is necessary for the performance of a contract to
                  which you are party or in order to take steps at your request
                  prior to entering into a contract.
                </td>
              </tr>
            </tbody>
          </table> */}

          {/* <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Privacy Policy
            Generator.
          </p>
          <h3>Interpretation and Definitions</h3>
          <h5>Interpretation</h5>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h5>Definitions</h5>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <p>
                <strong className="text-white">Account</strong> means a unique
                account created for You to access our Service or parts of our
                Service.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Affiliate</strong> means an
                entity that controls, is controlled by or is under common
                control with a party, where "control" means ownership of 50% or
                more of the shares, equity interest or other securities entitled
                to vote for election of directors or other managing authority.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Company</strong> (referred to as
                either "the Company", "We", "Us" or "Our" in this Agreement)
                refers to Armory.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Cookies</strong> are small files
                that are placed on Your computer, mobile device or any other
                device by a website, containing the details of Your browsing
                history on that website among its many uses.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Country</strong> refers to: New
                York, United States
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Device</strong> means any device
                that can access the Service such as a computer, a cellphone or a
                digital tablet.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Personal Data</strong> is any
                information that relates to an identified or identifiable
                individual.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Service</strong> refers to the
                Website.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Service Provider</strong> means
                any natural or legal person who processes the data on behalf of
                the Company. It refers to third-party companies or individuals
                employed by the Company to facilitate the Service, to provide
                the Service on behalf of the Company, to perform services
                related to the Service or to assist the Company in analyzing how
                the Service is used.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Usage Data</strong> refers to
                data collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example,
                the duration of a page visit).
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Website</strong> refers to
                Armory, accessible from{" "}
                <a
                  href="https://dev.cisotronix.com"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://dev.cisotronix.com
                </a>
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">You</strong> means the individual
                accessing or using the Service, or the company, or other legal
                entity on behalf of which such individual is accessing or using
                the Service, as applicable.
              </p>
            </li>
          </ul>
          <h2>Collecting and Using Your Personal Data</h2>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>
              <p>Email address</p>
            </li>
            <li>
              <p>First name and last name</p>
            </li>
            <li>
              <p>Phone number</p>
            </li>
            <li>
              <p>Address, State, Province, ZIP/Postal code, City</p>
            </li>
            <li>
              <p>Usage Data</p>
            </li>
          </ul>
          <h4>Usage Data</h4>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h4>Tracking Technologies and Cookies</h4>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <ul>
            <li>
              <strong className="text-white">
                Cookies or Browser Cookies.
              </strong>{" "}
              A cookie is a small file placed on Your Device. You can instruct
              Your browser to refuse all Cookies or to indicate when a Cookie is
              being sent. However, if You do not accept Cookies, You may not be
              able to use some parts of our Service. Unless you have adjusted
              Your browser setting so that it will refuse Cookies, our Service
              may use Cookies.
            </li>
            <li>
              <strong className="text-white">Web Beacons.</strong> Certain
              sections of our Service and our emails may contain small
              electronic files known as web beacons (also referred to as clear
              gifs, pixel tags, and single-pixel gifs) that permit the Company,
              for example, to count users who have visited those pages or opened
              an email and for other related website statistics (for example,
              recording the popularity of a certain section and verifying system
              and server integrity).
            </li>
          </ul>
          <p>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. You can learn more about cookies on{" "}
            <a
              href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
              target="_blank"
            >
              TermsFeed website
            </a>{" "}
            article.
          </p>
          <p>
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <ul>
            <li>
              <p>
                <strong className="text-white">
                  Necessary / Essential Cookies
                </strong>
              </p>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">
                  Cookies Policy / Notice Acceptance Cookies
                </strong>
              </p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies identify if users have accepted the use
                of cookies on the Website.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">Functionality Cookies</strong>
              </p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </p>
            </li>
          </ul>
          <p>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>
          <h3>Use of Your Personal Data</h3>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              <p>
                <strong className="text-white">
                  To provide and maintain our Service
                </strong>
                , including to monitor the usage of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">To manage Your Account:</strong>{" "}
                to manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">
                  For the performance of a contract:
                </strong>{" "}
                the development, compliance and undertaking of the purchase
                contract for the products, items or services You have purchased
                or of any other contract with Us through the Service.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">To contact You:</strong> To
                contact You by email, telephone calls, SMS, or other equivalent
                forms of electronic communication, such as a mobile
                application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">To provide You</strong> with
                news, special offers and general information about other goods,
                services and events which we offer that are similar to those
                that you have already purchased or enquired about unless You
                have opted not to receive such information.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">To manage Your requests:</strong>{" "}
                To attend and manage Your requests to Us.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">For business transfers:</strong>{" "}
                We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of Our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Us about our
                Service users is among the assets transferred.
              </p>
            </li>
            <li>
              <p>
                <strong className="text-white">For other purposes</strong>: We
                may use Your information for other purposes, such as data
                analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and
                improve our Service, products, services, marketing and your
                experience.
              </p>
            </li>
          </ul>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <ul>
            <li>
              <strong className="text-white">With Service Providers:</strong> We
              may share Your personal information with Service Providers to
              monitor and analyze the use of our Service, to contact You.
            </li>
            <li>
              <strong className="text-white">For business transfers:</strong> We
              may share or transfer Your personal information in connection
              with, or during negotiations of, any merger, sale of Company
              assets, financing, or acquisition of all or a portion of Our
              business to another company.
            </li>
            <li>
              <strong className="text-white">With Affiliates:</strong> We may
              share Your information with Our affiliates, in which case we will
              require those affiliates to honor this Privacy Policy. Affiliates
              include Our parent company and any other subsidiaries, joint
              venture partners or other companies that We control or that are
              under common control with Us.
            </li>
            <li>
              <strong className="text-white">With business partners:</strong> We
              may share Your information with Our business partners to offer You
              certain products, services or promotions.
            </li>
            <li>
              <strong className="text-white">With other users:</strong> when You
              share personal information or otherwise interact in the public
              areas with other users, such information may be viewed by all
              users and may be publicly distributed outside.
            </li>
            <li>
              <strong className="text-white">With Your consent</strong>: We may
              disclose Your personal information for any other purpose with Your
              consent.
            </li>
          </ul>
          <h3>Retention of Your Personal Data</h3>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h3>Transfer of Your Personal Data</h3>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h3>Delete Your Personal Data</h3>
          <p>
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <p>
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>
          <p>
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <p>
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
          <h3>Disclosure of Your Personal Data</h3>
          <h4>Business Transactions</h4>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h4>Law enforcement</h4>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h4>Other legal requirements</h4>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <h3>Security of Your Personal Data</h3>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h2>Children's Privacy</h2>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul>
            <li>By email: support@armory.com</li>
          </ul> */}
        </div>
      </Modal.Body>
      <hr />
      <Modal.Footer>
        <div className="w-100 text-center">
          <Button variant="primary" onClick={onDecline}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyPolicyModal;
