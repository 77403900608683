import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { detectLocation } from "../../util/country";
import { decodeHTML } from "entities";

function Linky() {
  const [location, setLocation] = useState(null);
  const routeParams = useParams();

  const getPosition = function () {
    // this will return a new Promise which will then give us Position when it's fulfilled
    return new Promise(function (resolve, reject) {
      // solution-1: more simplified version

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        console.log("Geolocation not supported");
      }

      // solution-2:
      // navigator.geolocation.getCurrentPosition(
      //   position => resolve(position),
      //   err => reject(err)
      // );
    });
  };

  // Simple Timeout function
  const timeout = function (sec) {
    // Here we just want to reject our promise so we can neglect resolve by replacing it with '_'
    return new Promise(function (_, reject) {
      setTimeout(function () {
        reject(new Error("Request took too long!"));
      }, sec * 1000);
    });
  };

  // async function handleLocationClick() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(success, error);
  //   } else {
  //     console.log("Geolocation not supported");
  //   }
  // }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    // setLocation({ latitude, longitude });
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`, position);
    let data = {
      location: {
        lat: latitude,
        lng: longitude,
      },
      code: routeParams.code,
      status: 1,
    };
    console.log("my data", routeParams, data);
    axios
      .post("url/", data)
      .then((response) => {
        let url = decodeHTML(response.data.url);
        console.log("urlaaaaaa", url);
        window.location = url;
      })
      .catch((e) => {
        axios.post("url/", data).then((response) => {
          let url = decodeHTML(response.data.url);
          console.log("url", url);
          window.location = url;
        });
      });
  }

  async function error() {
    let approxLoc = await detectLocation();
    console.log("Unable to retrieve your location");
    let data = {
      location: approxLoc,
      code: routeParams.code,
      status: 2,
    };

    axios.post("url/", data).then((response) => {
      let url = decodeHTML(response.data.url);
      console.log("url", url);
      window.location = url;
      // setTimeout(() => {
      //   navigate("/auth/otp/" + data.code);
      // }, 500);
    });
  }

  const init = async () => {
    // Promise.race accepts array of Promise and return the first fulfilled promise
    Promise.race([getPosition(), timeout(5)])
      .then((pos) => {
        success(pos);
        console.log("User granted permission");
      })
      .catch(async (err) => {
        console.error(err);
        console.error("User didn't grant permission");
        await error();
      });
  };
  useEffect(() => {
    init();
  }, []);

  return <></>;
}

export default Linky;
