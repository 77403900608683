import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  Nav,
  Row,
  Table,
} from "react-bootstrap";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { Triangle } from "react-loader-spinner";
import GApp from "../../components/GMap";
import core from "../../util/core";
import ShareForm from "./ShareForm";
// Svgs as component
import { ReactComponent as IconWarning } from "../../assets/images/icon-warning.svg";
import { ReactComponent as IconCheckCircle } from "../../assets/images/icon-check-circle.svg";
import { ReactComponent as IconLoader } from "../../assets/images/icon-loader.svg";
import ShareLocation from "../../components/ShareLocation";

const UrlView = (props) => {
  const routeParams = useParams();
  const [item, setItem] = useState("");

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  // const updateLocation = async (location) => {
  //   console.log("location", location);
  //   const params = {
  //     id: routeParams.id,
  //     location,
  //   };

  //   const { data } = await axios.post("/urls/update-location", params);
  // };
  const fetchURLView = async () => {
    setIsLoading(true);
    console.log("my data", routeParams);
    try {
      const params = {
        id: routeParams.id,
      };

      const { data } = await axios.get("/urls/view", { params });
      let tmpItem = data.urlInfo;

      tmpItem.location = core.parseJSON(tmpItem.location);

      setItem(tmpItem);

      setItem(data.urlInfo);
      console.log("data", item);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      toast.error("Unable to fetch details", {
        toastId: "error1",
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const renderItem = (item) => {
    let temp = core.parseJSON(item.device_info);

    return (
      <React.Fragment>
        {/* <div className="rounded overlap-info-box   position-relative text-center">
          {0 == item.status ? (
            <IconLoader className="align-middle  me-2" width="48" />
          ) : 1 === item.status ? (
            <IconCheckCircle className="align-middle me-2" width="48" />
          ) : (
            <IconWarning className="align-middle me-2" width="48" />
          )}
          <h2 className="my-2">
            {0 == item.status
              ? "Sent"
              : 1 === item.status
              ? "Confirmed"
              : "Rejected"}
          </h2>
          <p>This Information will be updated soon!</p>
        </div> */}
        <div className="bg-secondary bg-gradient position-relative rounded border p-md-2 p-2 mx-auto fs-md-3 overlap-box">
          <Table variant="custom" className=" position-relative" borderless>
            <tbody>
              <tr>
                <td colSpan={2}>
                  {0 == item.status ? (
                    <IconLoader className="align-middle  me-2" width="24" />
                  ) : 1 === item.status ? (
                    <IconCheckCircle className="align-middle me-2" width="24" />
                  ) : (
                    <IconWarning className="align-middle me-2" width="24" />
                  )}
                  {0 == item.status
                    ? "Sent"
                    : 1 === item.status
                    ? "Confirmed"
                    : "Rejected"}
                </td>
                <td className="text-end text-md-start"></td>
              </tr>
              <tr>
                <th>Full Name</th>
                <td className="text-end text-md-start">{item.fullname}</td>
              </tr>
              <tr>
                <th nowrap="true" width="100px">
                  Phone Number
                </th>
                <td className="text-end text-md-start">
                  {item.phone || "N/A"}
                </td>
              </tr>
              <tr>
                <th>Location</th>
                <td className="text-end text-md-start">
                  {item.address || "N/A"}
                </td>
              </tr>
              <tr>
                <th>Date & Time</th>
                <td className="text-end text-md-start">
                  <Moment format="DD.MM.YYYY h:mmA" className="pe-2">
                    {item.viewed_at ? item.viewed_at : item.created_at}
                  </Moment>
                  <Moment fromNow ago>
                    {item.viewed_at ? item.viewed_at : item.created_at}
                  </Moment>{" "}
                  ago
                </td>
              </tr>
            </tbody>
          </Table>
          {item.location ? (
            <ShareLocation
              location={item.location}
              title={item.fullname + "'s Location"}
            />
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  };
  const init = async () => {
    await fetchURLView();
  };
  useEffect(() => {
    //passing getData method to the lifecycle method
    init();
  }, []);
  return (
    <React.Fragment>
      {isLoading ? <Triangle
          height="80"
          width="80"
          color="#145f95"
          ariaLabel="triangle-loading"
          wrapperStyle={{ justifyContent: "center" }}
          wrapperClassName=""
          visible={isLoading}
        /> : 
      <Row>
        <Col>
          <div className="justify-content-start  rounded">
            <h1 className="fw-normal position-relative mx-auto overlap-h">
              <Button
                variant="secondary"
                onClick={() => navigate("/urls")}
                className="px-2 rounded-1 me-3"
              >
                <i className="bi bi-chevron-left text-white fs-4"></i>
              </Button>
            </h1>

            <GApp item={item}>{item ? renderItem(item) : ""}</GApp>
          </div>
        </Col>
      </Row>}
      {/* {item ? (
        <Row className="my-4">
          <Col>
            <ShareForm item={item} />
          </Col>
        </Row>
      ) : (
        ""
      )} */}
    </React.Fragment>
  );
};

export default UrlView;
