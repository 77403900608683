import React, { useCallback, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
} from "react-bootstrap";

import axios from "../../util/axios";
import EasmSingle from "./EasmSingle";
import InfiniteScroll from "react-infinite-scroller";
import { Triangle } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationModalContextProvider from "../../components/ModalConfirmationContext";
import { ReactComponent as Globe } from "../../assets/images/globe.svg";
import { ReactComponent as PlusBlue } from "../../assets/images/plus-blue.svg";



async function loadItems(page, pageSize) {
  try {
    let res = await axios.get("scans", {
      params: { page: page, pageSize: pageSize },
    });
    console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const Easms = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState("");
  const pageSize = 10;
  const [fetching, setFetching] = useState(false);
  const [dataLoad, setDataLoad] = useState(false);

  const navigate = useNavigate();

  const fetchItems = useCallback(async () => {
    if (fetching) {
      return;
    }

    setFetching(true);

    try {
      const data = await loadItems(page, pageSize);

      setItems([...items, ...data.items]);
      setPage(data.nextPage);
      setTotal(data.total);
      setDataLoad(true)
    } catch (error) {
      setPage(false);
      console.log("my error");
      
    } finally {
      setFetching(false);
    }
  }, [items, fetching, page, pageSize]);
  const onRemoveItem = (id) => {
    setItems(
      items.filter(function (item) {
        return item.id !== id;
      })
    );
  };

  const hasMoreItems = !!page;

  const loader = (
    <div key="loader" className="loader">
      <Triangle
        height="80"
        width="80"
        color="#fff"
        ariaLabel="triangle-loading"
        wrapperStyle={{ justifyContent: "center" }}
        wrapperClassName=""
      />
    </div>
  );

  return (
    <React.Fragment>
    {dataLoad && (
      <>
       {items.length <= 0 ? (
    <div>
      <div className="d-flex justify-content-center">
        <div className="position-relative globe-easm d-flex align-items-center justify-content-center">
          <PlusBlue className="globe-plus-position" />
          <Globe />
        </div>
      </div>
      <div className="text-center">
        <h1 className="fw-normal m-2">No Domain Scanned Yet</h1>
        <p className="fw-normal">Add your first domain by...</p>
        <Link to="/easm/new" className="btn btn-primary align-self-start">
          Add Domain
        </Link>
      </div>
    </div>
  ) : (
    <div className="text-uppercase d-flex justify-content-between text-white p-1 pb-3">
      <h1 className="fw-normal mb-5">Easm</h1>
      <Link to="/easm/new" className="btn btn-primary d-flex align-self-start">
        <i className="bi bi-plus-lg me-2"></i> Add Domain
      </Link>
    </div>
  )}
  </>
 
)}

      <Stack gap={2}>
        <ConfirmationModalContextProvider>
          <InfiniteScroll
            loadMore={fetchItems}
            hasMore={hasMoreItems}
            loader={loader}
            className="container-fluids "
          >
            {items.map((item) => (
              <EasmSingle
                key={item.id}
                item={item}
                onRemoveItem={onRemoveItem}
              />
            ))}
          
          </InfiniteScroll>
        </ConfirmationModalContextProvider>
      </Stack>
    </React.Fragment>
  );
};

export default Easms;
