import { Button } from "react-bootstrap";
import {
  FacebookShareCount,
  PinterestShareCount,
  VKShareCount,
  OKShareCount,
  RedditShareCount,
  TumblrShareCount,
  HatenaShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from "react-share";

function SocialShare(props) {
  const { url, title } = props;
  const iconSize = 55;

  return (
    <>
      <FacebookShareButton url={url} quote={title} className="share-btn">
        <FacebookIcon size={iconSize} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} className="share-btn">
        <TwitterIcon size={iconSize} round />
      </TwitterShareButton>
      <TelegramShareButton url={url} title={title} className="share-btn">
        <TelegramIcon size={iconSize} round />
      </TelegramShareButton>
      <WhatsappShareButton
        url={url}
        title={title}
        separator=":: "
        className="share-btn"
      >
        <WhatsappIcon size={iconSize} round />
      </WhatsappShareButton>
      <LinkedinShareButton url={url} className="share-btn">
        <LinkedinIcon size={iconSize} round />
      </LinkedinShareButton>

      <RedditShareButton
        url={url}
        title={title}
        windowWidth={660}
        windowHeight={460}
        className="share-btn"
      >
        <RedditIcon size={iconSize} round />
      </RedditShareButton>
      <ViberShareButton url={url} title={title} className="share-btn">
        <ViberIcon size={iconSize} round />
      </ViberShareButton>
      <LineShareButton url={url} title={title} className="share-btn">
        <LineIcon size={iconSize} round />
      </LineShareButton>
      <InstapaperShareButton url={url} title={title} className="share-btn">
        <InstapaperIcon size={iconSize} round />
      </InstapaperShareButton>
      <EmailShareButton
        url={url}
        subject={title}
        body="body"
        className="share-btn"
      >
        <EmailIcon size={iconSize} round />
      </EmailShareButton>
    </>
  );
}

export default SocialShare;
