import React, { useState } from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ReactComponent as BlueCross } from "../../assets/images/Blue-Cross.svg";
import { ReactComponent as InfoCircle } from "../../assets/images/Tested-Info-circle.svg";
import {useParams, useNavigate } from "react-router-dom";

const PopoverHeader = ({ title, popoverContent }) => {
  const [showPopover, setShowPopover] = useState(false);
  const navigate = useNavigate();
  const routeParams = useParams();
  const handleMouseEnter = () => {
    document.body.style.overflowY = "hidden";
    setShowPopover(true);
  };

  const handleMouseLeave = () => {
    setShowPopover(false);
  };

  const popover = (
    <Popover id="popover-basic" className="custom-popover-arrow">
      <Popover.Body className="comming-tool-body">
        <span className="text-black">Validated Armory Attack </span>
        <div
          className="text-center cursor-pointer"
          onClick={() => {
            navigate(`/offense/${routeParams?.target_id}`);
          }}
        >
          <span className="text-link">Click here to view more</span>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div
      className="flex items-center gap-2"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span>{title}</span>
      <OverlayTrigger
        show={showPopover}
        trigger={['hover', 'focus']}
        placement="top"
        overlay={popoverContent || popover}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
      >
        <div className="tested-tag d-flex align-items-center">
          <BlueCross />
          <label className="label-text mx-1">Tested</label>
          <InfoCircle />
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default PopoverHeader;
