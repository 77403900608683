
import axios from "../util/axios";
import base64url from "base64url";
import * as fido2 from '@simplewebauthn/browser';

export const _fetch = async (path, payload = '', method='POST') => {
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
  };
  if (payload && !(payload instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
    payload = JSON.stringify(payload);
  }

  // const res = await fetch(path, {
  //   method: 'POST',
  //   credentials: 'same-origin',
  //   headers: headers,
  //   body: payload,
  // });
  const res = await axios({
    method: method,
    url: path,
    // withCredentials: true,
    headers: headers,
    data: payload
  });
  let data = res.data;
  console.log('res', res)
  if (res.status === 200) {
    // Server authentication succeeded
    return data;
  } else {
    // Server authentication failed
    throw data.error;
  }
};

export const registerCredential = async () => {
  const opts = {
    attestation: 'none',
    authenticatorSelection: {
      authenticatorAttachment: 'platform',
      userVerification: 'required',
      requireResidentKey: false
    }
  };
  const registerRes = await _fetch('/webauthn/registerRequest', opts);
  let credential;
  try {
    // Pass the options to the authenticator and wait for a response
    console.log('my data', registerRes)
    let attResp = await fido2.startRegistration(registerRes);
    console.log('att', attResp)
    credential = attResp;
  } catch (error) {
    console.log(error)
    // Some basic error handling
    // if (error.name === 'InvalidStateError') {
    //   elemError.innerText = 'Error: Authenticator was probably already registered by user';
    // } else {
    //   elemError.innerText = error;
    // }

    throw error;
  }

  return await _fetch('/webauthn/registerResponse', credential);
};


export const verifyAuth = async (opts) => {
  const verificationResp = await _fetch('/webauthn/verify-authentication', opts);
  console.log('resp', verificationResp);
  return verificationResp;
}

export const authenticate = async () => {
  const opts = {};

  let url = '/webauthn/signinRequest';
  const credId = localStorage.getItem(`credId`);
  if (credId) {
    url += `?credId=${encodeURIComponent(credId)}`;
  }

  const options = await _fetch(url, opts);

  if (options.allowCredentials.length === 0) {
    console.info('No registered credentials found.');
    return Promise.resolve(null);
  }

  options.challenge = base64url.decode(options.challenge);

  for (let cred of options.allowCredentials) {
    cred.id = base64url.decode(cred.id);
  }

  const cred = await navigator.credentials.get({
    publicKey: options,
  });

  const credential = {};
  credential.id = cred.id;
  credential.type = cred.type;
  credential.rawId = base64url.encode(cred.rawId);

  if (cred.response) {
    const clientDataJSON =
      base64url.encode(cred.response.clientDataJSON);
    const authenticatorData =
      base64url.encode(cred.response.authenticatorData);
    const signature =
      base64url.encode(cred.response.signature);
    const userHandle =
      base64url.encode(cred.response.userHandle);
    credential.response = {
      clientDataJSON,
      authenticatorData,
      signature,
      userHandle,
    };
  }

  return await _fetch(`/webauthn/signinResponse`, credential);
};

export const unregisterCredential = async (id) => {
  // localStorage.removeItem('credId');
  return _fetch(`/credentials?id=${encodeURIComponent(id)}`,null,'DELETE');
};
