import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';

class Display extends Component {
  constructor() {
    super();
  }

  render() {
    const { value, updateValue } = this.props;

    return (
      <div className="display">
        <PhoneInput
					placeholder={"XX-XXX-XXXX"}
					name="cid"
					type="text"
          readonly
          alwaysDefaultMask={true}
          defaultMask=".. ........."
					defaultCountry="NO"
          value={value}
					onChange={(value) => {
						console.log('change...', value)
            updateValue(value, false)

					}}
				/>
        {/* <input type="text" value={value} disabled /> */}
      </div>
    );
  }
}

export default Display;
