import React, { useEffect, useState } from "react";
import { Button, FormGroup, FloatingLabel, FormControl } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Linker from "../../components/Linker";
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../util/axios";
import { Stack } from "react-bootstrap/esm";

const schema = Yup.object().shape({
    email: Yup.string()
    .email("Invalid email format")
    .required("Email is required")
    .test("no-leading-dot", "Invalid email format", value => value && !/^\./.test(value))
    .test("no-consecutive-dots", "Invalid email format", value => value && !/\.{2,}/.test(value))
    .test("no-trailing-dot", "Invalid email format", value => value && !/\.$/.test(value)),
});

const initialValues = {
    email: "",
};

const Forgot = () => {
    const [submitBtn, setSubmitBtn] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    let API_ENDPOINT_FORGOT_PASSWORD_NODE = "/forgot-password";

    // Handle Form Submit
    const handleSubmit = async (values, helpers) => {
        setIsLoading(true);
        try {
            const response = await axios.post(API_ENDPOINT_FORGOT_PASSWORD_NODE, values);
            const data = response.data;
            setIsLoading(false);
            helpers.resetForm({
                values: {
                    email: "",
                },
            });
            toast.success(data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                theme: "dark",
            });
        } catch (e) {
            setIsLoading(false);
            if (e.response.status === 422) {
                let errorField = e.response.data
                helpers.setFieldError(errorField?.path, errorField?.msg);
            }
        }
    };

    useEffect(() => {
        if (isLoading) {
            setSubmitBtn({ disabled: true, label: "Please wait.." });
        } else {
            setSubmitBtn({
                disabled: false,
                label: "Proceed",
            });
        }
    }, [isLoading]);

    return (
        <React.Fragment>
            {/* new */}
            <div className="forgot-fragment">
            <div className="forgot-main">
                <Logo className="mt-3 d-block mx-auto img-fluid logo-img" />
                <h2 className="mt-4 text-center ">Forgot Password </h2>
                <div className="forgot-form">
                    <div className="forgot-input">
                        <Formik
                            validationSchema={schema}
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            render={({
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                values,
                                errors,
                                validateForm,
                            }) => (
                                <Form>
                                    <Field
                                        name="email"
                                        render={({ field, form: { touched, errors }, meta }) => (
                                            <FormGroup className="mt-3 " controlId="email">
                                                <label controlId="floatingPassword">Email</label>
                                                <FormControl
                                                    type={"text"}
                                                    size="lg"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    placeholder="Enter your email "
                                                    className={
                                                        meta.touched && meta.error ? "is-invalid" : ""
                                                    }
                                                    {...field}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <div className="invalid-feedback">{meta.error}</div>
                                                ) : null}
                                            </FormGroup>
                                        )}
                                    />
                                    <Button
                                        type="submit"
                                        className="btn bg-primary btn-full mx-auto my-3 mt-4"
                                        id="singup-btn"
                                        disabled={submitBtn.disabled}
                                    >
                                        {submitBtn.label}
                                    </Button>
                                    <div className="d-flex justify-content-center align-items-center  ">
                                        <Linker
                                            to={"/auth/login"}
                                            title="Back to Login"
                                            className="m-1 cancel "
                                        >
                                            {"Cancel"}
                                        </Linker>
                                    </div>

                                </Form>
                            )}
                        />
                    </div>
                </div>
            </div>
            </div>

            {/* old */}

            {/* <div>
                <h2 className="my-5 text-center">Forgot Password</h2>
                <Formik
                    validationSchema={schema}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    render={({
                    }) => (
                        <Form>
                            <Stack gap={4}>
                                <Field
                                    name="email"
                                    render={({ field, form: { touched, errors }, meta }) => (
                                        <FormGroup controlId="email">
                                            <FloatingLabel
                                                controlId="floatingPassword"
                                                label="Enter your email"
                                            >
                                                <FormControl
                                                    type={"text"}
                                                    size="xxlg"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    className={
                                                        meta.touched && meta.error ? "is-invalid" : ""
                                                    }
                                                    {...field}
                                                />
                                                {meta.touched && meta.error ? (
                                                    <div className="invalid-feedback">{meta.error}</div>
                                                ) : null}
                                            </FloatingLabel>
                                        </FormGroup>
                                    )}
                                />
                                <Button
                                    type="submit"
                                    className="btn bg-primary btn-full mx-auto my-3"
                                    id="singup-btn"
                                    disabled={submitBtn.disabled}
                                >
                                    {submitBtn.label}
                                </Button>

                            </Stack>
                        </Form>
                    )}
                />
            </div> */}
            <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} theme="dark" />
        </React.Fragment>
    );
};

export default Forgot;





{/* <span onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
    {showConfirmPassword ? <EyeSlashFill /> : <EyeFill />}
</span>  */}