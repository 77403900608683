import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const HorizontalBarChart = ({ HorizontalBarChartdata, HorizontalBarChartoptions, isChartLabelsOnTop }) => {
  const plugins = isChartLabelsOnTop ? [ChartDataLabels] : [];

  return (
    <div className='bar-chart-container d-flex align-items-center justify-content-center w-100 h-100'>
      <div className='col-12 h-100'>
        <Bar data={HorizontalBarChartdata} options={HorizontalBarChartoptions} plugins={plugins} />
      </div>
    </div>
  );
};

export default HorizontalBarChart;
