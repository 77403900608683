import React, { useState } from "react";
import { Badge, Button, Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import core from "../../util/core";

import Moment from "react-moment";
import { ReactComponent as IconBellMinus } from "../../assets/images/icon-bell-minus.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../util/axios";
import { useConfirmationModalContext } from "../../components/ModalConfirmationContext";

const AlertSingle = ({ item, onRemoveItem }) => {
  const [loading, setLoading] = useState(false);
  let modalContext = useConfirmationModalContext();


  const removeThisAlert = async (alert) => {
    let title = 'Are you sure you want to delete this alert? ';
    let msg = 'This action cannot be undone. Confirm to proceed or Cancel to keep the alert.';
    let icon = 'alert';

    const result = await modalContext.showConfirmation(
      title,
      (<div className='p-3'>
        <p>
          {msg}

        </p>
      </div>),
      icon,
      'Delete alert'
    );
    if (result) {
      removeAlert(alert);
    }
  };
  const removeAlert = (alert) => {
    const id = alert.id;
    setLoading(true);
    axios
      .delete("/alerts", { params: { id } })
      .then((response) => {

        const data = response.data;
        setLoading(false);
        if (data.msg) {
          onRemoveItem(id);
          toast.success(data.msg, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

        }

      })
      .catch((e) => {
        setLoading(false);
        if (e.response?.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];
            toast.error(field.msg, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            // helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };

  return (

    <Row className=" rounded border m-0 my-2  shadow-sm">

      <Col className=" p-3">
        <Link
          to={{
            pathname: "/leaks",
            search: `?keyword=${item.keyword}`,
            // replace: true,
          }}
          className="nav-link"
        >
          <div className="d-flex m-2">

            <span className="text-muted"><img alt="" src={`/images/icon-mini-alerts.png`} height={18} className="me-2" /> {" "}{item.keyword}</span>
          </div>

          <div>
            <div className="m-2"><Badge bg="secondary">No new information</Badge></div>
            <div className="m-2">

              <i className="bi bi-clock me-2" title="Date & Time"></i>Date &
              Time {": "}
              <small className="ms-1 text-muted">
                <Moment format="MMM DD, YYYY hh:mm">{item.created_at}</Moment>
              </small>
            </div>
          </div>
        </Link>
      </Col>
      <Col xs="auto" className="p-3 px-1">

        <OverlayTrigger
          overlay={<Tooltip id={"alert-" + item.id}>{loading ? "Removing the alert" : "Remove this alert"}</Tooltip>}
          placement="left"
          delayShow={300}
          delayHide={150}
        >
          <Button className={loading ? 'removing-state' : ''} variant="link" onClick={(e) => {
            removeThisAlert(item)
          }}><IconBellMinus width="25" className="mx-1" /></Button>
        </OverlayTrigger>

      </Col>
    </Row>

  );
};

export default AlertSingle;
