import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Background,
  Panel,
  useReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
} from "reactflow";
import "reactflow/dist/style.css";
import MapFilter from "./TreeMapFilter";
import mapBg from "../../assets/images/RiskCard_bg.png";


const ZoomTransitionTree = ({ title, targets,loading }) => {
  const { setViewport, zoomIn, zoomOut } = useReactFlow();

  const handleTransform = useCallback(() => {
    setViewport({ x: 0, y: 0, zoom: 1 }, { duration: 800 });
  }, [setViewport]);

  useEffect(() => {
    // Call zoomOut function on page load
    zoomOut({ duration: 800 });
  }, [zoomOut]);
  useEffect(() => {

  }, [targets]);

  // Function to generate nodes and edges dynamically based on input data
  const generateNodesAndEdges = () => {
    const nodes = [];
    const edges = [];

    // Add title node
    nodes.push({
      id: title,
      type: "input",
      data: { label: title },
      position: { x: 0, y: 80 },
      style: {
        backgroundColor: "#FFFFFF1C",
        color: "#FFFFFF",
        fontWeight: "500",
        fontSize: "16px",
      },
      draggable: true,
      sourcePosition: "right",
    });

    // Add IP nodes and edges connecting to title node
    targets.forEach((treeItem, index) => {
      const ipNodeId = `ip-${index}`;
      nodes.push({
        id: ipNodeId,
        data: { label: treeItem.title },
        position: { x: 350 * (index + 1), y: 80 },
        style: {
          backgroundColor: "#636363",
          color: "#FFFFFF",
          fontWeight: "400",
          fontSize: "12px",
          minWidth: "200px",
        },
        draggable: true,
        sourcePosition: "right",
        targetPosition: "left",
      });
      edges.push({
        id: `edge-${index}`,
        source: title,
        target: ipNodeId,
        animated: true,
        type: "smoothstep",
        sourcePosition: "right",
        targetPosition: "left",
      });

      // Add hostname nodes and edges connecting to IP nodes
      treeItem.hosts.forEach((hostData, hostIndex) => {
        const hostNodeId = `host-${index}-${hostIndex}`;
        nodes.push({
          id: hostNodeId,
          data: { label: hostData.ipAddress },
          position: { x: 900 * (index + 1), y: 90 * (hostIndex + 1) },
          style: {
            backgroundColor: "#636363",
            color: "#FFFFFF",
            fontWeight: "400",
            fontSize: "12px",
            minWidth: "200px",
          },
          draggable: true,
          sourcePosition: "right",
          targetPosition: "left",
        });
        edges.push({
          id: `edge-${index}-${hostIndex}`,
          source: ipNodeId,
          target: hostNodeId,
          animated: true,
          type: "smoothstep",
          sourcePosition: "right",
          targetPosition: "left",
        });
        hostData.scanHosts.forEach((host, indexing) => {
          const additionalNodeId = `additional-${index}-${hostIndex}-${indexing}`;
          nodes.push({
            id: additionalNodeId,
            data: { label: host.hostName },
            position: {
              x: 1300 * (index + 1),
              y: 120 * (hostIndex + indexing),
            },
            style: {
              backgroundColor: "#636363",
              color: "#FFFFFF",
              fontWeight: "400",
              fontSize: "12px",
              minWidth: "200px",
            },
            draggable: true,
            sourcePosition: "right",
            targetPosition: "left",
          });
          edges.push({
            id: `additional-edge-${index}-${hostIndex}-${indexing}`,
            source: hostNodeId,
            target: additionalNodeId,
            animated: true,
            type: "",
            sourcePosition: "right",
            targetPosition: "left",
          });
        });
      });
    });

    return { nodes, edges };
  };

  const { nodes, edges } = generateNodesAndEdges();
  const [nodes1, setNodes, onNodesChange] = useNodesState(nodes);
  const [edges1, setEdges, onEdgesChange] = useEdgesState(edges);
  if (loading) {
    return <div className="position-relative d-flex align-items-center justify-content-center">
          
          <img src={mapBg} alt="map-load"/>
      <div className="greentext position-absolute">
          Initializing first-time scanning...
        </div>
    </div>;
  }

  return (
    <div style={{ height: "500px" }}>
      <ReactFlow
        nodes={nodes1}
        edges={edges1}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        className="map-tree-flow"
      >
        <Panel position="top-right">
          <MapFilter
            handleTransform={handleTransform}
            zoomIn={() => zoomIn({ duration: 800 })}
            zoomOut={() => zoomOut({ duration: 800 })}
          />
       
        </Panel>
        {loading}
      
        <Background />
      </ReactFlow>
    </div>
  );
};

// HorizontalFlow component
// HorizontalFlow component
const HorizontalFlow = ({ title, targets, filteredData, loading }) => {
  const [mapDomains, setMapDomains] = useState({});

  useEffect(() => {
    const tree = [];
    const scanInfoHostsMap = new Map(
      targets?.scanInfo?.hosts.map((host) => [host.key, host.host])
    );
    // Check if targets and filteredData are defined and contain the expected data
    filteredData.forEach((ipItem) => {
      const domainId = ipItem.ip.domain_id;
      const ipAddress = ipItem.ip.ip;
      const scanHosts = ipItem.scan_hosts.map((scanHost) => {
        const host_id = scanHost.host_id;
        const hostName = scanHost.host.host;
        return { host_id, hostName };
      });
      const domain = scanInfoHostsMap.get(domainId) || "";
      const existingDomainIndex = tree.findIndex(
        (item) => item.title === domain
      );
      if (existingDomainIndex !== -1) {
        tree[existingDomainIndex].hosts.push({ ipAddress, scanHosts });
      } else {
        tree.push({
          title: domain,
          hosts: [{ ipAddress, scanHosts }],
        });
      }
    });

    setMapDomains({
      tree,
    });
  }, [targets, filteredData]);

  return (
    <ReactFlowProvider>
      {mapDomains?.tree && (
        <ZoomTransitionTree title={title} targets={mapDomains?.tree} loading={loading}/>
      )}
    </ReactFlowProvider>
  );
};



export default HorizontalFlow;
