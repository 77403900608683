import axios from "axios";
// define baseUrl
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
// axios.defaults.withXSRFToken = true

// axios.interceptors.request.use(
//     async (config) => {
//         // setup interceptor to be used all over the application
//         let userToken = localStorage.getItem('user-token');

//         if (userToken) {
//             // console.log('my token', userToken)
//             config.headers["access-token"] = userToken;
//         }
//         return config;
//     },
//     (err) => {
//         return Promise.reject(err);
//     }
// )

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      originalRequest.url != "auth/login"
    ) {
      localStorage.clear();
      console.log("clear");
      window.location = "/auth/login";
      // return Promise.reject(error)
    }
    if (error.response && error.response.status === 405) {
      // window.location = '/';
    }

    return Promise.reject(error);
  }
);
export default axios;
