import React, { useContext, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const useModalShow = () => {
  const [show, setShow] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  };
};

const ConfirmationModalContext = React.createContext({});

const ConfirmationModalContextProvider = (props) => {
  const { setShow, show, onHide } = useModalShow();
  const [content, setContent] = useState(null);
  const resolver = useRef();

  const handleShow = (title, message, icon = "", btnOkText="OK",btnCancelText="Cancel") => {
    setContent({
      title,
      message,
      icon,
      btnOkText,
      btnCancelText
    });
    setShow(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const modalContext = {
    showConfirmation: handleShow,
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}
      {content && (
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="lg"
          contentClassName={`admin-modal `}
        >
          <Modal.Body>
            <h2 className="text-center mt-4">
              {content.icon && (
                <img
                  alt=""
                  src={`/images/icon-mini-${content.icon}.png`}
                  width={20}
                />
              )}{" "}
              {content.title}
            </h2>
            <p className="text-center">{content.message}</p>
            <div>
              <Button className="btn-full mt-4 mb-2" variant="primary" onClick={handleOk}>{content.btnOkText}</Button>
              <Button className="btn-full text-white" variant="outline-primary"  outlined onClick={handleCancel}>{content.btnCancelText}</Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModalContext = () => useContext(ConfirmationModalContext);

export { useModalShow, useConfirmationModalContext };

export default ConfirmationModalContextProvider;
