import React from "react";
import { Card,  Image } from "react-bootstrap";


const StatusCard = (props) => {
  return (
    <Card bg={props.bg} className="p-0">
      <Card.Body className="d-flex flex-row">
        {props.type === "img" ? (
          <Image
            height={100}
            alt="171x180"
            src={"/images/" + props.icon}
          />
        ) : (
          <i className={props.icon} style={{fontSize: '70px'}}></i>
        )}
        <div className="d-flex flex-column w-100 p-1">
          <h3 className="text-end" style={{fontSize: '40px'}}>{props.count}</h3>
          <Card.Text className="text-end fs-3">{props.title}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

export default StatusCard;
