import React from 'react'
import Breadcrumb from "../../components/breadcrumbs/BreadCrumb"
import AssetDiscoveryCard from './AssetDiscoveryCard';
import OffensiveTestingCard from './OffensiveTestingCard';
import TopIssuesCard from './TopIssuesCard';
import RiskScoreCard from './RiskScoreCard';
import RiskCard from './RiskCard';
import AiButton from './AiButton';


const Dashboard = () => {
    const breadcrumbItems = ["Dashboard", "Offensive Recon"];
    
  return (
    <React.Fragment>
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
        <div className='px-4 py-5'>
        <AssetDiscoveryCard/>
        <div className='d-flex justify-content-between align-items-center my-4 '>
        <RiskScoreCard/>
        <OffensiveTestingCard/>
        <TopIssuesCard/>
        </div>
        <RiskCard/>
        <AiButton num="3" message="Release Kubernetes Critical Security Issue in Bamboo"/>
        </div>

        
    </React.Fragment>
  )
}

export default Dashboard
