import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Button } from "react-bootstrap";
import ShareLocation from "./ShareLocation";
import { GMAP_KEY } from "../util/constants";

const DEFAULT_CENTER = { lat: 48.8566, lng: 2.3522 };
const DEFAULT_ZOOM = 18;
export const GoogleMapsWrapper = (props) => {
  if (!GMAP_KEY) {
    return <div>Cannot display the map: google maps api key missing</div>;
  }

  return <Wrapper apiKey={GMAP_KEY}>{props.children}</Wrapper>;
};

const geocodeLatLng = (map, geocoder, location, updateLocation) => {
  geocoder
    .geocode({ location: location })
    .then((response) => {
      if (response.results[0]) {
        map.setZoom(11);
        let myAddress = response.results[0];
        updateLocation(myAddress);
        console.log("my address", response.results[0]);
      } else {
        console.log("No results found");
      }
    })
    .catch((e) => console.log("Geocoder failed due to: ", e));
};

const addSingleMarkers = (locations, map, scale = 4) => {
  const svgMarker = {
    path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "#185f95",
    fillOpacity: 0.6,
    strokeColor: "#000",
    strokeWeight: 0.8,
    rotation: 0,
    scale: scale,
    anchor: new window.google.maps.Point(0, 20),
  };

  const markers = locations.map((position) => {
    // console.log("position", position);
    if ((position?.lat, position?.lng)) {
      return new window.google.maps.Marker({
        position,
        icon: svgMarker,
        map,
      });
    }
  });
  return new MarkerClusterer({ markers, map });
};
export const GoogleMaps = ({ item, locations }) => {
  const ref = useRef(null);
  const [location, setLocation] = useState("");
  const [pins, setPins] = useState([]);

  useEffect(() => {
    let loc = item.location ? item.location : DEFAULT_CENTER;
    if (item.location) setLocation(item.location);
  }, [ref]);
  useEffect(() => {
    let loc = item.location ? item.location : DEFAULT_CENTER;
    let scale = item.scale || 4;
    if (item.location) setLocation(item.location);
    // Display the map
    if (ref.current) {
      const map = new window.google.maps.Map(ref.current, {
        center: loc,
        zoom: item.zoom || DEFAULT_ZOOM,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#242f3e" }],
          },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
          },
        ],
      });
      if (item.location) {
        // Displays single markers on map when called
        addSingleMarkers([item.location], map, scale);
      }

      if (locations?.length > 0) {
        // Displays single markers on map when called
        addSingleMarkers(locations, map, scale);
      }
    }
  }, [ref, item.location, locations]);

  return (
    <>
      <div
        ref={ref}
        className="rounded"
        style={{ width: "100%", height: "50vh" }}
      />
    </>
  );
};

const GMap = ({ item, locations, children }) => {
  return (
    <GoogleMapsWrapper>
      <GoogleMaps item={item} locations={locations} />

      {children}
    </GoogleMapsWrapper>
  );
};
export default GMap;
