import React, { useState, useEffect } from "react";
import {
    Button,
    Col,
    Container,
    FloatingLabel,
    FormControl,
    FormGroup,
    Row,
} from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../util/axios";

const validate = (values) => {
    const errors = {};
    if (!values.title) {
        errors.title = "Please enter title.";
    }
    if (!values.objective) {
        errors.objective = "Please enter objective.";
    }
    if (!values.organization_id) {
        errors.organization_id = "Please Select An Organization.";
    }
    if (!values.date) {
        errors.date = "Please select a date.";
    }
    if (values.number < 0 || values.number > 100) {
        errors.number = "Please enter a number between 0 and 100.";
    }
    return errors;
};

const CyberServices = ({ editData, fetchServices, handleEditClose }) => {
    const [submitBtn, setSubmitBtn] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [allOrganizations, setAllOrganizations] = useState([]);
    const [file, setFile] = useState(null);
    let API_ENDPOINT_NODE = editData ? `admin/cyber-services/${editData?.id}` : "admin/cyber-services" ;


    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const initialValues = editData ? {
        title: editData.title || "",
        objective: editData.objective || "",
        organization_id: editData.organization_id || "",
        date: editData.submit_date ? formatDate(editData.submit_date) : "",
        number: editData.risk_score || "",
    } : {
        title: "",
        objective: "",
        organization_id: "",
        date: "",
        number: "",
        file: null,
    };
    const getAllOrganizations = () => {
        axios
            .get("organizations", {
                params: { page: 1, pageSize: 1000 },
            })
            .then((res) => {
                setAllOrganizations(res.data?.items);
            })
            .catch(err => {
                toast.error("Failed to load organizations");
            });
    };


    const handleSubmit = (values, { resetForm }) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('objective', values.objective);
        formData.append('organization_id', values.organization_id);
        formData.append('submit_date', values.date);
        formData.append('risk_score', values.number);
        if (file && !editData) {
            formData.append('doc', file);
        }

        axios.post(API_ENDPOINT_NODE, formData)
            .then(response => {
                if (editData) {
                    toast.success('Security Issue Updated successfully!', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        theme: "dark",
                    });
                    fetchServices()
                    handleEditClose()
                } else {
                    toast.success('Security Issue added successfully!', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        theme: "dark",
                    });
                }
                resetForm();
                setFile(null);
            })
            .catch(error => {
                toast.error("Failed to add Security Issues");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    useEffect(() => {
        getAllOrganizations();
    }, [])


    useEffect(() => {
        if (isLoading) {
            setSubmitBtn({ disabled: true, label: editData ? "Updating Security Issue.." : "Adding Security Issue.." });
        } else {
            setSubmitBtn({ disabled: false, label: editData ? "Update Security Issue" : "Add Security Issue" });
        }
    }, [isLoading]);


    return (
        <React.Fragment>
            <Container fluid className={editData ? "" : "main_container_style"}>
                <Row className="justify-content-start m-0" style={{ display: editData ? "none" : "block" }}>
                    <Col>
                        <h1 className="fw-normal mb-3">Security Issues</h1>
                    </Col>
                </Row>
                <div className={editData ? "w-100 m-auto p-2" : "w-50 m-auto"}>
                    <Row>
                        <Col>
                            <h2 className={editData ? "my-3 text-center" : "my-5 text-center"}>{editData ? "Edit" : "Add"} Security Issue</h2>
                            <Formik
                                validate={validate}
                                initialValues={initialValues}
                                enableReinitialize={true}
                                onSubmit={handleSubmit}
                                render={({ isValid, isSubmitting }) => (
                                    <Form className="form-height-set">

                                        {/* Organization Name Field */}
                                        <Field name="organization_id">
                                            {({ field, form: { touched, errors }, meta }) => (
                                                <FormGroup controlId="organization_id" className="my-3">
                                                    <FloatingLabel label="Select organization name">
                                                        <FormControl
                                                            as="select"
                                                            size="lg"
                                                            {...field}
                                                            value={field.value}
                                                            className={`form-control-height ${meta.touched && meta.error ? "is-invalid" : ""}`}
                                                        >
                                                            <option value="">Select an organization</option>
                                                            {allOrganizations?.map((org) => (
                                                                <option key={org.id} value={org.id}>
                                                                    {org.org_name}
                                                                </option>
                                                            ))}
                                                        </FormControl>
                                                        {meta.touched && meta.error ? (
                                                            <div className="invalid-feedback">{meta.error}</div>
                                                        ) : null}
                                                    </FloatingLabel>
                                                </FormGroup>
                                            )}
                                        </Field>

                                        {/* Title Field */}
                                        <Field
                                            name="title"
                                            render={({ field, form: { touched, errors }, meta }) => (
                                                <FormGroup controlId="title" className="my-3">
                                                    <FloatingLabel
                                                        controlId="floatingPassword"
                                                        label="Enter Title"
                                                    >
                                                        <FormControl
                                                            type={"text"}
                                                            size="lg"
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            placeholder=""
                                                            className={
                                                                meta.touched && meta.error ? "is-invalid" : ""
                                                            }
                                                            {...field}
                                                        />
                                                        {meta.touched && meta.error ? (
                                                            <div className="invalid-feedback">{meta.error}</div>
                                                        ) : null}
                                                    </FloatingLabel>
                                                </FormGroup>
                                            )}
                                        />

                                        {/* Objective Field */}
                                        <Field
                                            name="objective"
                                            render={({ field, form: { touched, errors }, meta }) => (
                                                <FormGroup controlId="objective" className="my-3">
                                                    <FloatingLabel
                                                        controlId="floatingPassword"
                                                        label="Enter Objective"
                                                    >
                                                        <FormControl
                                                            type={"text"}
                                                            size="lg"
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            placeholder=""
                                                            className={
                                                                meta.touched && meta.error ? "is-invalid" : ""
                                                            }
                                                            {...field}
                                                        />
                                                        {meta.touched && meta.error ? (
                                                            <div className="invalid-feedback">{meta.error}</div>
                                                        ) : null}
                                                    </FloatingLabel>
                                                </FormGroup>
                                            )}
                                        />


                                        {/* Date Picker Field */}
                                        <Field name="date">
                                            {({ field, form: { touched, errors }, meta }) => (
                                                <FormGroup controlId="date" className="my-3">
                                                    <FloatingLabel label="Select Date">
                                                        <FormControl
                                                            type="date"
                                                            size="lg"
                                                            className={meta.touched && meta.error ? "is-invalid" : ""}
                                                            {...field}
                                                        />
                                                        {meta.touched && meta.error && (
                                                            <div className="invalid-feedback">{meta.error}</div>
                                                        )}
                                                    </FloatingLabel>
                                                </FormGroup>
                                            )}
                                        </Field>

                                        {/* Number Input Field */}
                                        <Field name="number">
                                            {({ field, form: { touched, errors }, meta }) => (
                                                <FormGroup controlId="number" className="my-3">
                                                    <FloatingLabel label="Enter Risk Score">
                                                        <FormControl
                                                            type="number"
                                                            size="lg"
                                                            min="0"
                                                            max="100"
                                                            className={meta.touched && meta.error ? "is-invalid" : ""}
                                                            {...field}
                                                        />
                                                        {meta.touched && meta.error && (
                                                            <div className="invalid-feedback">{meta.error}</div>
                                                        )}
                                                    </FloatingLabel>
                                                </FormGroup>
                                            )}
                                        </Field>

                                        {/* File Input Field */}
                                        {
                                            !editData &&
                                            <FormGroup controlId="file" className="my-3">
                                                <FormControl
                                                    type="file"
                                                    size="lg"
                                                    onChange={handleFileChange}
                                                    value={file ? undefined : ''}
                                                    className={file ? "is-valid" : ""}
                                                />
                                            </FormGroup>
                                        }

                                        <Button
                                            type="submit"
                                            disabled={!isValid || isSubmitting || isLoading}
                                        >
                                            {submitBtn?.label}
                                        </Button>
                                    </Form>
                                )}
                            />
                        </Col>
                    </Row>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default CyberServices;
