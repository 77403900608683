import React, { useCallback, useState } from "react";
import { Button, Card, Col, Container, Form, Row, Stack } from "react-bootstrap";

import axios from "../../util/axios";
import InfiniteScroll from "react-infinite-scroller";
import HistorySingle from "./HistorySingle";
import { Triangle } from "react-loader-spinner";

async function loadItems(page, pageSize) {
	try {
		let res = await axios.get('leaks/history', { params: { page: page, pageSize: pageSize } });
		console.log('data', res.data);
		return res.data;

	} catch (error) {
		console.log(error);
		throw error;
	}

}

const History = () => {
	const [items, setItems] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState("");
	const pageSize = 10;
	const [fetching, setFetching] = useState(false);

	const fetchItems = useCallback(
		async () => {
			if (fetching) {
				return;
			}

			setFetching(true);

			try {
				const data = await loadItems(page, pageSize);

				setItems([...items, ...data.items]);
				setPage(data.nextPage)
				setTotal(data.total)


			} finally {
				setFetching(false);
			}
		},
		[items, fetching, page, pageSize]
	);

	const hasMoreItems = !!page;

	const loader = (
		<div key="loader" className="loader">
			<Triangle
				height="80"
				width="80"
				color="#fff"
				ariaLabel="triangle-loading"
				wrapperStyle={{ justifyContent: 'center' }}
				wrapperClassName=""
			/>
		</div>
	);

	return (
		<React.Fragment>


			<Stack gap={2}>
				<InfiniteScroll
					loadMore={fetchItems}
					hasMore={hasMoreItems}
					loader={loader}
					className="container-fluids "
				>
					<h3 className="m-0 p-0">Search History ({total.toLocaleString('en')})</h3>
					{items.map(item => (
						<HistorySingle key={item.id} item={item}  />
					))}
					{total <= 0 ? <div className="pt-3">No record found!</div> : ""}

				</InfiniteScroll>
			</Stack>


		</React.Fragment >
	);
};

export default History;
