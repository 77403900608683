import React, { useState, useEffect, useRef, useContext } from "react";
import {
  FormGroup,
  FormControl,
  Button,
  Container,
  Badge,
  FormCheck,
  Modal,
} from "react-bootstrap";
import axios from "../../util/axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import core from "../../util/core";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as Xmark } from "../../assets/images/xmark.svg";
import { ReactComponent as Delete } from "../../assets/images/delete.svg";
import { ScannerContext } from "../../components/ScannerContext";
import TermsConditionsModal from "../../auth/register/TermsConditionsModal";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { locationToPageName } from "../../helpers";
import { sendEvent } from "../../util/analytics";
const services = {
  0: "full",
  1: "demo",
  4: "selected",
};

function DomainScan({ scan, loadMore }) {
  const navigate = useNavigate();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const { fetchTargets ,dropdownItems} = useContext(ScannerContext);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [domains, setDomains] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [hostValue, sethostValue] = useState("");
  const [showInput, setShowInput] = useState(domains.length < 2);
  const [orgDetails, setOrgDetails] = useState(null);
  const [selectedService, setSelectedService] = useState(0);
  const [selectedScanServices, setSelectedScanServices] = useState([1]);
  const [servicesResponse, setServicesResponse] = useState([]);
  const [editButton, setEditButton] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const formikRef = useRef();
  const targetNameInputRef = useRef(null);
  const hostInputRef = useRef(null);
  const ipsLimitRef = useRef(0);
  const [defaultSelected, setDefaultSelected] = useState(["harvester"]);
  const [allTargets, setAllTargets] = useState([]);
  const location = useLocation();
  let API_ENDPOINT_NODE = id ? `/targets/${id}` : "targets";

  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.member_level == 9;
  const isNormalUser = user?.member_level == 0;
  const isDistributor = user?.member_level == 1;
  const schema = Yup.object().shape({
    title: Yup.string()
      .required("Target Name is required")
      .test("is-valid-title", "Title cannot contain only symbols", (value) =>
        /^[\p{L}\p{N}]+[\p{L}\p{N}\s@#$%&*!,.-]*$/u.test(value)
      )
      .test("is-unique-title", "Target Name already exists", function (value) {
        if (scan?.scanInfo?.id) return true;
        return !allTargets.some((item) => item.title === value);
      }),
    scan_type: Yup.string().required("Scan type is required"),
    host: Yup.string()
      .test(
        "is-host-required",
        "Host is required",
        (value) => hosts.length > 0 || value !== ""
      )
      .test(
        "isValidHost",
        "Invalid host",
        (value) => !value || core.isValidHost(value)
      ),
  });

  const initialValues = {
    title: scan?.scanInfo?.scan_name
      ? scan?.scanInfo?.scan_name
      : isDefault
      ? orgDetails?.org_name
      : "",
    description: scan?.scanInfo?.description || "",
    host: "",
    hosts: scan?.scanInfo?.scan_target_hosts?.map((host) => host.host) || [],
    scan_type: 0,
  };
  useEffect(() => {
    if (scan && scan.scanInfo && scan.scanInfo.scan_target_hosts) {
      initialValues["title"] = scan?.scanInfo?.scan_name;
      const initialHosts = scan.scanInfo.scan_target_hosts.map(
        (host) => host.host
      );
      setHosts(initialHosts);
    } else {
      setHosts([]);
    }
  }, [scan]);

  const fetchAllTargets = async () => {
    try {
      const { data } = await axios.get(`targets?page=1&pageSize=1000`);
      //Check if user is normal user and has one target. If yes, navigate to default target.
      if(isNormalUser && data?.items.length > 0){
        let defaultTargetId = data?.items?.filter(
          (el) => el?.default_scan == 1
        )?.[0];
        navigate(`/assets-inventory/${defaultTargetId?.target_uuid}`);
      }
      setAllTargets(data?.items);
    } catch (err) {}
  };

  useEffect(() => {
  
    
    fetchAllTargets();
    axios.get(`/scanServices`).then((res) => {
      console.log(res.data.data);
      setServicesResponse(res.data.data);
    });
  }, []);

  const getUser = () => {
    const userData = localStorage.getItem("user");
    return userData ? JSON.parse(userData) : {};
  };

  const handleDeclineTerms = () => {
    setShowTermsModal(false);
    // setIsTermsAccepted(false);
  };

  const handleAcceptTerms = () => {
    setShowTermsModal(false);
    setIsTermsAccepted(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/targets/${scan?.scanInfo?.id}`);
      fetchTargets();
      let defaultTargetId = allTargets?.filter(
        (el) => el?.default_scan == 1
      )?.[0];
      navigate(`/assets-inventory/${defaultTargetId?.target_uuid}`);
      toast.success("Target deleted successfully!");
      setShowModal(false);
    } catch (error) {
      toast.error("Failed to delete the target!");
      setShowModal(false);
    }
  };

  const getAllOrganizations = () => {
    axios
      .get("organizations", {
        params: { page: 1, pageSize: 1000 },
      })
      .then((res) => {
        setOrgDetails(
          res.data?.items?.filter(
            (el) => el.id === getUser().organization_id
          )?.[0]
        );
      });
  };

  const removeHost = (hostToRemove) => {
    setHosts((prevHosts) => prevHosts.filter((host) => host !== hostToRemove));
  };

  const handleAddDomainClick = () => {
    setShowInput(true);
  };

  function cleanDomain(input) {
    return input.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").toLowerCase();
  }

  const addHost = (e, setFieldValue) => {
    const newHostDomain = e.target.value.trim();
    const newHost = cleanDomainNew(newHostDomain);

    if (!newHost) {
      toast.error("Invalid host", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      return;
    }

    if (hosts.includes(newHost)) {
      toast.error("Host already added", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
      return;
    }

    // Validate host
    schema
      .validateAt("host", { host: newHost })
      .then(() => {
        // Apply regex transformation
        let extractr = newHost.match(
          /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im
        );
        let transformedHost = newHost;
        if (extractr && extractr.length > 0) {
          transformedHost = extractr[1]?.toLowerCase();
        }

        // Check again if the transformed host is already in the list
        if (hosts.includes(transformedHost)) {
          toast.error("Host already added", {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: "colored",
          });
          return;
        }

        setHosts((prevHosts) => [...prevHosts, transformedHost]);
        setFieldValue("host", ""); // Clear input field
      })
      .catch((error) => {
        toast.error("Invalid host: " + error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    if (!scan?.scanInfo?.id) {
      targetNameInputRef.current?.focus();
    } else {
      hostInputRef.current?.focus();
    }
  }, [scan]);
  const cleanDomainNew = (url) => {
    // Add protocol if missing
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }
    try {
      const newUrl = new URL(url);
      let hostname = newUrl.hostname;

      // Remove "www." prefix
      if (hostname.startsWith("www.")) {
        hostname = hostname.slice(4);
      }

      // Check if the hostname is valid and not just a protocol
      if (!hostname || hostname === "http" || hostname === "https") {
        return null; // Invalid hostname
      }

      return hostname;
    } catch (e) {
      // If URL constructor fails, return null to indicate invalid URL
      return null;
    }
  };

  const handleSubmit = (values, helpers) => {
    if (hosts.length === 0 && !values.host) {
      helpers.setFieldError("host", "At least one host is required");
      return; // Return early to prevent further submission
    }
    let updatedHosts = hosts?.map((el) => el);
    let hostName = cleanDomainNew(values.host);
    values.host = cleanDomainNew(values.host);

    if (hostName && !hosts.includes(hostName)) {
      updatedHosts.push(hostName);
    }
    setIsScanning(true);
    values.hosts = updatedHosts;
    values.selected_services = selectedScanServices.join(",");

    // if(selectedScanServices.length>1){
    //   values.scan_type = 4 // for selected services scan
    // }

    if (isDefault) {
      values["default_scan"] = 1;
    }
    axios
      .post(API_ENDPOINT_NODE, values)
      .then((response) => {
        const data = response.data;
        setIsScanning(false);
        fetchTargets();
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
        });
        const pageName = locationToPageName(location);
        const obj = {
          pageName,
          result: "target created",
          scanType: services[values.scan_type],
        };
        if (obj.scanType === services[4]) obj.selected = selectedScanServices;
        sendEvent("buttonClick", obj);
        navigate(`/assets-inventory/${data?.data?.target_uuid}`);
      })
      .catch((e) => {
        setIsScanning(false);
      });
  };
  // we check the https and www on target input change now we check when host add not onChange
  // const handleInputChange = (field) => {
  //   console.log("field", field);
  //   let extractr = field.value.match(
  //     /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im
  //   );
  //   if (extractr && extractr.length > 0)
  //     field.value = extractr[1]?.toLowerCase();
  //   field.onChange(field.value);
  // };

  useEffect(() => {
    if (allTargets?.length === 0 || scan?.scanInfo?.default_scan == 1) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
  }, [allTargets, scan?.scanInfo]);

  useEffect(() => {
    getAllOrganizations();
  }, []);

  const handleOnScansDropdownChange = (selected) => {
    // Find all dependencies recursively
    const findDependencies = (serviceIds) => {
      let allDependencies = new Set(serviceIds);

      servicesResponse.forEach((service) => {
        if (serviceIds.includes(service.id)) {
          // Add dependency of this service
          if (service.dependency !== 0) {
            allDependencies.add(service.dependency);
            allDependencies = new Set([
              ...allDependencies,
              ...findDependencies([service.dependency]),
            ]);
          }
        }
      });

      return [...allDependencies];
    };

    // Get the IDs of the selected services
    const selectedServiceIds = servicesResponse
      .filter((service) => selected.includes(service.service_name))
      .map((service) => service.id);

    // Find all dependencies for the selected services
    const allSelectedServiceIds = findDependencies(selectedServiceIds);
    setSelectedScanServices(allSelectedServiceIds);
  };
  useEffect(() => {
    if (dropdownItems.length > 0 && isNormalUser) {
      navigate("/"); 
    }
  }, [dropdownItems, isNormalUser, navigate]);

  return (
    <>
      <div className="add-target-main">
        <div className="add-target-container">
          <div className="add-target-form">
            <Formik
              enableReinitialize={true}
              validationSchema={schema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnMount={false}
              context={{ allTargets }}
              render={({
                handleChange,
                handleSubmit,
                handleBlur,
                values,
                errors,
                validateForm,
                setFieldValue,
              }) => {
                return (
                  <Form>
                    {!isScanning && (
                      <div>
                        <div className="row">
                          {isDefault && !scan?.scanInfo?.id && (
                            <div>
                              <h1>Create your first target</h1>
                              <div className="col-md-10 text-white sub-heading">
                                This is your primary target, and unlike other
                                targets, scans for the Default target will run
                                continuously to ensure ongoing monitoring and
                                protecttion
                              </div>
                            </div>
                          )}
                          <div className="col-md-6 hide-invalid-icon-field">
                            <Field
                              name="title"
                              render={({
                                field,
                                form: { touched, errors },
                                meta,
                              }) => (
                                <FormGroup controlId="title">
                                  <label>
                                    Target Name
                                    {isDefault && (
                                      <tag className="tag-default">Default</tag>
                                    )}
                                  </label>

                                  <FormControl
                                    type={"text"}
                                    size="lg"
                                    value={field.value}
                                    onChange={field.onChange}
                                    placeholder="Enter the target name"
                                    className={
                                      meta.touched && meta.error
                                        ? "is-invalid text-white fw-lighter"
                                        : "text-white fw-lighter"
                                    }
                                    ref={targetNameInputRef}
                                    autoFocus
                                    {...field}
                                  />
                                  {meta.touched && meta.error ? (
                                    <div className="invalid-feedback">
                                      {meta.error}
                                    </div>
                                  ) : null}
                                </FormGroup>
                              )}
                            />
                          </div>
                        </div>

                        <Field
                          name="description"
                          render={({
                            field,
                            form: { touched, errors },
                            meta,
                          }) => (
                            <FormGroup controlId="description">
                              <label>Description</label>
                              <FormControl
                                type={"text"}
                                as="textarea"
                                size="lg"
                                value={field.value}
                                onChange={field.onChange}
                                placeholder="Provide a brief description for the new seed environment."
                                className={
                                  meta.touched && meta.error
                                    ? "is-invalid text-white fw-lighter"
                                    : "text-white fw-lighter"
                                }
                                rows={5}
                                {...field}
                              />
                              {meta.touched && meta.error ? (
                                <div className="invalid-feedback">
                                  {meta.error}
                                </div>
                              ) : null}
                            </FormGroup>
                          )}
                        />
                        <Field
                          name="host"
                          render={({
                            field,
                            form: { touched, errors },
                            meta,
                          }) => (
                            <FormGroup controlId="host">
                              <label>Domain/IP Addresses</label>
                              <FormControl
                                type={"text"}
                                size="lg"
                                value={field.value}
                                onChange={field.value}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === "Enter" ||
                                    e.key === " " ||
                                    e.key === ","
                                  ) {
                                    e.preventDefault();
                                    addHost(e, setFieldValue);
                                  }
                                }}
                                placeholder="Enter domain name(s) or IP address(es) for this target, separated by commas."
                                className={
                                  meta.touched && meta.error
                                    ? "is-invalid text-white fw-lighter"
                                    : "text-white fw-lighter"
                                }
                                ref={hostInputRef}
                                {...field}
                              />
                              {meta.touched && meta.error ? (
                                <div className="invalid-feedback">
                                  {meta.error}
                                </div>
                              ) : null}
                            </FormGroup>
                          )}
                        />
                        <div className="domain-chips mt-3">
                          {hosts.map((host, index) => (
                            <Badge
                              key={index}
                              className="badge-border my-1  px-2 me-2 "
                            >
                              <label className="p-2 label-for-chip">
                                {host}
                              </label>
                              {!id ? (
                                <span
                                  className="px-0 pb-0 pt-0 ms-2 cursor-pointer"
                                  onClick={() => removeHost(host)}
                                >
                                  <Xmark />
                                </span>
                              ) : null}
                            </Badge>
                          ))}
                        </div>
                        {!scan?.scanInfo?.id && (
                          <div className="col-md-12 pb-2">
                           
                              <FormGroup className="mt-2" controlId="scan_type">
                                <div className="row">
                                  <div className="col-12 checkbox-scan">
                                    {(isAdmin || isNormalUser || isDistributor) ? <FormCheck
                                      inline
                                      label="Full Scan"
                                      className="me-3"
                                      type="checkbox"
                                      name="scan_type"
                                      id="fullScan"
                                      checked={selectedService === 0}
                                      onChange={() => {
                                        setSelectedService(0);
                                        // Update the 'scan_type' field value in the formik form
                                        setFieldValue("scan_type", 0);
                                      }}
                                    />: ''}
                                    {!isNormalUser && <FormCheck
                                      inline
                                      label="Demo Scan"
                                      type="checkbox"
                                      name="scan_type"
                                      id="demoScan"
                                      checked={selectedService === 1}
                                      onChange={() => {
                                        setSelectedService(1);
                                        setFieldValue("scan_type", 1);
                                      }}
                                    />}
                                   {isAdmin && <FormCheck
                                      inline
                                      label="Selected Scan"
                                      type="checkbox"
                                      name="service"
                                      id="demoScan"
                                      checked={selectedService === 4}
                                      onChange={() => {
                                        setSelectedService(4);
                                        setFieldValue("scan_type", 4);
                                      }}
                                    />}
                                  </div>
                                </div>
                              </FormGroup>
                            

                            {isAdmin &&
                              servicesResponse.length > 0 &&
                              selectedService == 4 && (
                                <div className="row">
                                  {" "}
                                  <div className="col-md-6 custom_dropdown">
                                    <DropdownMultiselect
                                      options={servicesResponse.map(
                                        (service) => service.service_name
                                      )}
                                      name="selectedServices"
                                      placeholder="Select Scans"
                                      handleOnChange={
                                        handleOnScansDropdownChange
                                      }
                                      buttonClass="fw-light"
                                      selected={defaultSelected}
                                    />
                                  </div>{" "}
                                </div>
                              )}
                          </div>
                        )}

                        {isAdmin && (
                          <div className="row">
                            {" "}
                            <div className="col-md-12">
                              {" "}
                              <Field
                                name="ip_limit"
                                render={({
                                  field,
                                  form: { touched, errors },
                                  meta,
                                }) => (
                                  <FormGroup controlId="title">
                                    <label>IPs Limitation</label>

                                    <FormControl
                                      type={"number"}
                                      size="lg"
                                      value={field.value}
                                      onChange={field.onChange}
                                      placeholder="Select the IPs amount you want to scan"
                                      className={
                                        meta.touched && meta.error
                                          ? "is-invalid text-white fw-lighter"
                                          : "text-white fw-lighter"
                                      }
                                      ref={ipsLimitRef}
                                      autoFocus
                                      {...field}
                                    />
                                    {meta.touched && meta.error ? (
                                      <div className="invalid-feedback">
                                        {meta.error}
                                      </div>
                                    ) : null}
                                  </FormGroup>
                                )}
                              />
                            </div>
                          </div>
                        )}
                        <hr className="mt-3" />
                        <div className="mt-5 box p-1">
                          <div
                            className="box-text"
                            style={{
                              marginLeft: scan?.scanInfo?.id ? "10px" : "0px",
                            }}
                          >
                            {scan?.scanInfo?.id ? (
                              <Field name="agreeTerms">
                                {({ field, form }) => (
                                  <FormGroup
                                    controlId="agreeTerms"
                                    className="my-1  w-100 ms-2 terms-cond"
                                  >
                                    <input
                                      type="checkbox"
                                      id="flexCheckDefault"
                                      checked={isTermsAccepted}
                                      onChange={() =>
                                        setIsTermsAccepted(!isTermsAccepted)
                                      }
                                      className={`base-checkbox ${
                                        form.touched.agreeTerms &&
                                        form.errors.agreeTerms
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      readOnly
                                    />
                                    <span className="scan-content-text ms-2">
                                      I have the rights and authorizations to
                                      scan this target and I agree to the
                                      <u style={{ color: "#398FFE" }}>
                                        <a
                                          style={{
                                            color: "#398FFE",
                                            cursor: "pointer",
                                          }}
                                          className="ms-1"
                                          onClick={() =>
                                            setShowTermsModal(true)
                                          }
                                        >
                                          Terms of Services
                                        </a>
                                      </u>
                                    </span>
                                    {form.touched.agreeTerms &&
                                      form.errors.agreeTerms && (
                                        <div className="invalid-feedback d-block">
                                          {form.errors.agreeTerms}
                                        </div>
                                      )}
                                  </FormGroup>
                                )}
                              </Field>
                            ) : (
                              <>
                                <Field name="agreeTerms">
                                  {({ field, form }) => (
                                    <FormGroup
                                      controlId="agreeTerms"
                                      className="my-1 w-100 ms-1 terms-cond"
                                    >
                                      <input
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        checked={isTermsAccepted}
                                        onChange={() =>
                                          setIsTermsAccepted(!isTermsAccepted)
                                        }
                                        className={`base-checkbox ${
                                          form.touched.agreeTerms &&
                                          form.errors.agreeTerms
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        readOnly
                                      />
                                      <span className="scan-content-text ms-2">
                                        I have the rights and authorizations to
                                        scan this target and I agree to the
                                        <u style={{ color: "#398FFE" }}>
                                          <a
                                            style={{
                                              color: "#398FFE",
                                              cursor: "pointer",
                                            }}
                                            className="ms-1"
                                            onClick={() =>
                                              setShowTermsModal(true)
                                            }
                                          >
                                            Terms of Services
                                          </a>
                                        </u>
                                      </span>
                                      {form.touched.agreeTerms &&
                                        form.errors.agreeTerms && (
                                          <div className="invalid-feedback d-block">
                                            {form.errors.agreeTerms}
                                          </div>
                                        )}
                                    </FormGroup>
                                  )}
                                </Field>
                              </>
                            )}
                          </div>

                          <TermsConditionsModal
                            show={showTermsModal}
                            onHide={handleDeclineTerms}
                            onContinue={handleAcceptTerms}
                            onDecline={handleDeclineTerms}
                            isAfterRegister={true}
                          />
                        </div>

                        <div className="d-flex justify-content-center mt-5">
                          {scan?.scanInfo?.id ? (
                            <Button
                              type="submit"
                              variant="secondary"
                              className="btn btn-primary w-50 scan-button"
                              style={{ background: "#767272" }}
                              id="singup-btn"
                              disabled={
                                Object.entries(errors)?.length > 0 ||
                                (hosts.length <= 0 &&
                                  !core.isValidHost(values?.host)) ||
                                !isTermsAccepted
                              }
                            >
                              Save Changes
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              className="btn btn-primary w-50 scan-button"
                              id="singup-btn"
                              disabled={
                                Object.entries(errors)?.length > 0 ||
                                (hosts.length <= 0 &&
                                  !core.isValidHost(values?.host)) ||
                                !isTermsAccepted
                              }
                            >
                              Create Target
                            </Button>
                          )}
                        </div>
                        {!isDefault && scan?.scanInfo?.id && (
                          <div
                            className="delete-btn mt-3"
                            onClick={() => handleDeleteClick()}
                          >
                            <span>Delete Target</span>
                            <Delete />
                          </div>
                        )}
                      </div>
                    )}
                  </Form>
                );
              }}
            />
          </div>
          {isScanning && (
            <Container className="dos-container p-0 bg-black mt-3  mb-3">
              <Container className="px-3 pt-3 pb-5">
                <div className="scanning-content">
                  <p className="active-text">Scanning for domains...</p>
                  <p className="active-text">
                    We'll let you know when scanning is complete
                  </p>
                  <p className="header-title">Host Names:</p>
                  {hosts.map((host, index) => (
                    <p key={index} className="active-text">
                      {host}
                    </p>
                  ))}
                </div>
              </Container>
            </Container>
          )}
        </div>
      </div>
      <Modal show={showModal} centered onHide={handleClose} className="w-70">
        <Modal.Header className="target-modal">
          <div className="d-flex flex-column mt-4 align-items-center justify-content-center w-100">
            <div>
              <Modal.Title>
                <h3>Are you sure you want to delete this target?</h3>
              </Modal.Title>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-3 mt-5 mb-5 w-100">
              <Button className=" target-button fs-12 " onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className=" target-button fs-12"
                onClick={handleConfirmDelete}
              >
                Yes Delete
              </Button>
            </div>
          </div>
        </Modal.Header>
      </Modal>
    </>
  );
}

export default DomainScan;
